import { useTranslation } from "react-i18next";
import { downloadFile } from "../../apis/downloadApi";
import { SummaryTableLi } from "../../components/study/studyContent/StudyViewSummary";
import * as S from "../../styles/Common";
import { TitleV3 } from "../Title/TitleText";
import * as S2 from "./style/Drop.style";

export const DropImageBox = ({ text, imagesArr, file, isRadiation, handleImageViewer }) => {
  const { t } = useTranslation(["translation"]);
  /**이미지 다운로드 */
  const handleDownload = (id, name) => {
    downloadFile(id, name, t);
  };

  return (
    <S.ContentBox $printhide>
      {text && <TitleV3 title={text} />}
      <S2.DropListWrap $radiation={isRadiation}>
        {imagesArr?.map((img) => (
          <S2.DropList className={img} key={img}>
            <S2.DropItem $radiation={isRadiation}>
              {file?.[img] && (
                <S2.DropItemImg>
                  <img src={file?.[img]?.distFileName} alt="preview" />
                  <S2.DropItemControlBtn type="button" onClick={() => handleImageViewer(file?.[img]?.distFileName)} $enlargebtn>
                    <i className="ri-fullscreen-line"></i>
                  </S2.DropItemControlBtn>
                  <S2.DropItemControlBtn type="button" onClick={() => handleDownload(file?.[img]?.fileId, file?.[img]?.orgFileName)} $downloadbtn>
                    <i className="ri-download-line"></i>
                  </S2.DropItemControlBtn>
                </S2.DropItemImg>
              )}
            </S2.DropItem>
          </S2.DropList>
        ))}
      </S2.DropListWrap>
    </S.ContentBox>
  );
};

export const DropImageBoxPrint = ({ text, imagesArr, file, isRadiation }) => {
  return (
    <SummaryTableLi title={text} printshow>
      <S2.DropListWrap $radiation={isRadiation}>
        {imagesArr?.map((img) => (
          <S2.DropList className={img} key={img}>
            <S2.DropItem $radiation={isRadiation}>
              {file?.[img] && (
                <S2.DropItemImg>
                  <img src={file?.[img]?.distFileName} alt="preview" />
                </S2.DropItemImg>
              )}
            </S2.DropItem>
          </S2.DropList>
        ))}
      </S2.DropListWrap>
    </SummaryTableLi>
  );
};
