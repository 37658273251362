import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { MonthPicker } from "../../elements/DatePicker";
import * as S2 from "../../elements/Form/style/Form.style";
import * as S from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";

const BillFilter = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const searchs = watch();

  /**검색 시작날짜 설정 */
  const handleStartMonth = (date) => {
    if (date) {
      setValue("startMonth", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("startMonth", "");
    }
  };

  /**검색 마감날짜 설정 */
  const handleEndMonth = (date) => {
    if (date) {
      setValue("endMonth", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("endMonth", "");
    }
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    // data.start와 data.end 중 하나는 값이 있고 다른 하나는 없는 경우
    if ((data.startMonth && !data.endMonth) || (!data.startMonth && data.endMonth)) {
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }

    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      startMonth: "",
      endMonth: "",
      doctorName: "",
      clinicName: "",
      doctorType: "",
    });
    setSearchCondition({ page: 1 });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">{t(IK.board_date)}</th>
            <td>
              <S2.FormControlsFlex>
                <MonthPicker
                  placeholderText={t(IK.search_year_month)}
                  handleDataChange={handleStartMonth}
                  selected={(searchs.startMonth && new Date(searchs.startMonth)) || (searchCondition.startMonth && new Date(searchCondition.startMonth))}
                  formControls
                />
                <MonthPicker
                  placeholderText={t(IK.search_year_month)}
                  handleDataChange={handleEndMonth}
                  selected={(searchs.endMonth && new Date(searchs.endMonth)) || (searchCondition.endMonth && new Date(searchCondition.endMonth))}
                  formControls
                />
              </S2.FormControlsFlex>
            </td>
            <th scope="row">{t(IK.search_doctor)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_doctor_input)} defaultValue={searchCondition?.doctorName} {...register("doctorName")} />
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.search_clinic)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.clinicName} {...register("clinicName")} />
            </td>
            <th scope="row">{t(IK.doctor_type)}</th>
            <td>
              <S2.FormControls {...register("doctorType")} defaultValue={searchCondition?.doctorType} as="select" $select>
                <option value="">{t(IK.select_doctor_type)}</option>
                <option value="TNS">{t(IK.doctor_tns)}</option>
                <option value="DENTIS">{t(IK.doctor_dentis)}</option>
              </S2.FormControls>
            </td>
          </tr>
        </tbody>
      </S.TableType2>
      <S3.ButtonCtBox>
        <S3.StyledButton type="button" onClick={() => onReset()} as="button">
          <i className="ri-refresh-line" /> {t(IK.reset)}
        </S3.StyledButton>
        <S3.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line" /> {t(IK.search)}
        </S3.StyledButton>
      </S3.ButtonCtBox>
    </form>
  );
};

export default BillFilter;
