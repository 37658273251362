import { useTranslation } from "react-i18next";
import { useGetPaymentDetail } from "../../apis/billApi";
import * as S5 from "../../elements/Table/style/Table.style";
import { TitleV3 } from "../../elements/Title/TitleText";
import * as S4 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { formatCurrency, payMethodStatus, paymentStatus } from "../../utils/paymentUtils";
import { seraphinOption } from "../../utils/studyStatus";
import ModalLayout from "../Modal/ModalLayout";
import { CardTitle } from "../layout/CardLayout/CardLayout";
import BillDoneSkeleton from "./BillDoneSkeleton";

const PaymentDetailModal = ({ paymentModal, closePaymentModal }) => {
  const { t } = useTranslation(["translation"]);
  const { data, isLoading } = useGetPaymentDetail(paymentModal?.paymentId);

  return (
    <ModalLayout open={paymentModal.isOpen} header={t(IK.coupon_title)} handleClose={closePaymentModal}>
      <CardTitle title={`${t(IK.detail_billing)}(#${paymentModal?.paymentId})`} />
      {isLoading ? (
        <BillDoneSkeleton />
      ) : (
        <div>
          {data?.map((bill) => (
            <S4.ContentLine key={bill.paymentId}>
              <TitleV3 title={t(IK.history_billing)} />
              <S5.TableType1>
                <colgroup>
                  <col width="120px"></col>
                  <col width="*"></col>
                  <col width="130px"></col>
                  <col width="130px"></col>
                  <col width="130px"></col>
                  <col width="130px"></col>
                  <col width="130px"></col>
                  <col width="*"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">{t(IK.pay_products)}</th>
                    <th scope="col">{t(IK.patient_name)}</th>
                    <th scope="col">{`${t(IK.pay_products)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{`${t(IK.discount)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{`${t(IK.discount)} ${t(IK.coupon)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{t(IK.pay_charged)}</th>
                    <th scope="col">
                      {t(IK.create_date)} ({t(IK.delivery_date)})
                    </th>
                    <th scope="col">{t(IK.pay_notes)}</th>
                  </tr>
                </thead>
                <tbody>
                  {bill.paymentDetailDTOList.map((item) => (
                    <tr key={item.paymentDetailId}>
                      <td>{seraphinOption[item.packages]}</td>
                      <td>
                        {`${item.patientFirstName} ${item.patientLastName}`} {item?.patientKoreaName && `(${item.patientKoreaName})`}
                      </td>
                      <td>
                        {formatCurrency(item.salePrice)}
                        {t(IK.krw)}
                      </td>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(item.discountPrice)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(item.couponAmount)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                      <td>
                        {formatCurrency(item.salePrice - (item.discountPrice + item.couponAmount))}
                        {t(IK.krw)}
                      </td>
                      <td>{item.startDate || "-"}</td>
                      <td>
                        <S4.TextDiv $textpreline>{item.memo}</S4.TextDiv>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </S5.TableType1>
              <S4.ContentBox>
                <TitleV3 title={t(IK.info_billing)} />
                <S5.TableType2>
                  <colgroup>
                    <col width="200px"></col>
                    <col width="*"></col>
                    <col width="200px"></col>
                    <col width="*"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">{t(IK.pay_status)}</th>
                      <td>{t(paymentStatus[bill?.paymentStatus])}</td>
                      <th scope="row">{t(IK.date_billing)}</th>
                      <td>{bill?.paidAt}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.pay_methods)}</th>
                      <td colSpan={bill.payMethod === "vbank" ? 1 : 3}>
                        {t(payMethodStatus[bill?.payMethod || "payment_status_prepaid"])} {bill?.escrow && `(${t(IK.escrow)})`}
                      </td>
                      {bill.payMethod === "vbank" && (
                        <>
                          <th scope="row">{t(IK.deposit_account)}</th>
                          <td>
                            {bill?.vbankName} {bill?.vbankNum}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      {bill.payMethod === "vbank" && (
                        <>
                          <th scope="row">{t(IK.deposit_deadline)}</th>
                          <td colSpan={3}>{bill?.vBankDate}</td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </S5.TableType2>
              </S4.ContentBox>
              <S4.ContentBox>
                <TitleV3 title={t(IK.price_billing)} />
                <S5.TableType2>
                  <colgroup>
                    <col width="200px"></col>
                    <col width="*"></col>
                    <col width="200px"></col>
                    <col width="*"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">{t(IK.total_price)}</th>
                      <td>
                        {formatCurrency(bill?.paymentTotalAmount)}
                        {t(IK.krw)}
                      </td>
                      <th scope="row">{t(IK.total_discount)}</th>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(bill?.discountAmount)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.total_coupon)}</th>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(bill?.couponAmount)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                      <th scope="row">{t(IK.total_prepayment)}</th>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(bill?.prepaidAmount)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.final_price)}</th>
                      <td colSpan={3}>
                        <S4.TextDiv $textColorBlue400 $textSize24 $textBold800>
                          {formatCurrency(bill?.payAmount)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                    </tr>
                  </tbody>
                </S5.TableType2>
              </S4.ContentBox>
            </S4.ContentLine>
          ))}
        </div>
      )}
    </ModalLayout>
  );
};

export default PaymentDetailModal;
