import Notiflix from "notiflix";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetDoctorPatientList } from "../apis/patientApi";
import BoardTotal from "../components/board/BoardTotal";
import CouponCheck from "../components/coupon/CouponCheck";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import PatientTransferFilter from "../components/transfer/PatientTransferFilter";
import PatientTransferTr from "../components/transfer/PatientTransferTr";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import * as S2 from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import { changeParam } from "../utils/searchParams";

const PatientTransfer = () => {
  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState({ page: 1 });

  /**체크박스 선택 */
  const [transfers, setTransfers] = useState({
    patientIds: [],
    fromDoctorId: "",
    toDoctorId: "",
  });

  const { register, handleSubmit, reset } = useForm();

  const { data, isLoading } = useGetDoctorPatientList(transfers.fromDoctorId, changeParam(searchCondition));
  const patients = data?.content;

  /**전체 체크 */
  const handleAllCheck = useCallback(() => {
    setTransfers((prev) => ({
      ...prev,
      patientIds: prev.patientIds.length === patients?.length ? [] : patients?.map((patient) => patient.patientId) || [],
    }));
  }, [patients]);

  /**단일 체크 */
  const handleCheck = useCallback((patientId) => {
    setTransfers((prev) => ({
      ...prev,
      patientIds: prev.patientIds.includes(patientId) ? prev.patientIds.filter((id) => id !== patientId) : [...prev.patientIds, patientId],
    }));
  }, []);

  /**페이지 설정 */
  const handleChangePage = useCallback((e) => {
    setTransfers((prev) => ({ ...prev, patientIds: [] }));
    setSearchCondition((prev) => ({ ...prev, page: e }));
  }, []);

  /**환자명 검색 */
  const handleSearch = ({ patientName }) => {
    if (transfers.fromDoctorId === "") {
      return Notiflix.Report.warning("환자 이동을 위한 의사를 선택해주세요.", "from 의사를 선택한 후에 검색할 수 있습니다.", "확인");
    }
    setTransfers((prev) => ({ ...prev, patientIds: [] }));
    setSearchCondition({ ...searchCondition, page: 1, patientName });
  };

  /**초기화 */
  const handleResetClick = useCallback(() => {
    reset({
      patientName: "",
    });
    setSearchCondition({ page: 1 });
    setTransfers((prev) => ({ ...prev, patientIds: [] }));
  }, [reset]);

  const tableCol = useMemo(() => ["70px", "90px", "90px", "220px", "220px", "220px", "220px"], []);
  const tableHead = useMemo(
    () => [
      <CouponCheck handleCheck={handleAllCheck} checked={transfers.patientIds.length === patients?.length && patients?.length > 0} />,
      "No.",
      "환자번호",
      "환자명",
      "생년월일",
      "성별",
      "환자 등록일",
    ],
    [handleAllCheck, transfers.patientIds.length, patients]
  );

  return (
    <S.CardRow>
      <PatientTransferFilter setTransfers={setTransfers} transfers={transfers} />
      {isLoading ? (
        <DataTableSkeleton tableHead={tableHead} tableCol={tableCol} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={data?.totalElements} />
              <form onSubmit={handleSubmit(handleSearch)}>
                <S4.ButtonCtBox>
                  <S2.FormControls placeholder="환자명을 입력해주세요." {...register("patientName")} />
                  <S4.StyledButton $primary type="submit" as="button">
                    <i className="ri-search-2-line" /> 검색
                  </S4.StyledButton>
                  <S4.StyledButton type="button" as="button" onClick={handleResetClick}>
                    <i className="ri-refresh-line" /> 초기화
                  </S4.StyledButton>
                </S4.ButtonCtBox>
              </form>
            </CardHead>
            <DataTable
              dataLength={data?.totalElements}
              tableCol={tableCol}
              tableHead={tableHead}
              tableTr={<PatientTransferTr patients={patients} total={data?.totalElements} page={searchCondition.page} handleCheck={handleCheck} transfers={transfers} />}
            />
            <CardTail>
              <Paging totalCount={data?.totalElements || 0} currentPage={searchCondition.page} size={data?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default PatientTransfer;
