import { useTranslation } from "react-i18next";
import * as S from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { formatCurrency, payStatus } from "../../utils/paymentUtils";
import { doctorTypeLabel } from "../../utils/searchLabel";

const BillTableTr = ({ bills }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      {bills.map((bill) => (
        <tr key={bill.billingId}>
          <td>{bill.billingId}</td>
          <td>
            {bill.billingMonth}
            {t(IK.month)}
          </td>
          <td>
            {bill.doctorName} {bill?.doctorKoreaName && `(${bill.doctorKoreaName})`}
          </td>
          <td>{!!bill?.doctorType ? t(doctorTypeLabel[bill.doctorType]) : ""}</td>
          <td>{bill.addressName}</td>
          <td>
            {formatCurrency(bill.saleTotalPrice)}
            {t(IK.krw)}
          </td>
          <td>
            <S2.TextDiv $textColorRed600>
              -{formatCurrency(bill.discountTotalPrice)}
              {t(IK.krw)}
            </S2.TextDiv>
          </td>
          <td>
            {formatCurrency(bill.paymentTotalPrice)}
            {t(IK.krw)}
          </td>
          <td>
            {formatCurrency(bill.paymentTotalPrice - bill.priceLeft)}
            {t(IK.krw)}
          </td>
          <td>
            <S.StyledSmallButton to={`/billList/${bill.billingId}`} state={{ herf: window.location.search }}>
              {t(payStatus[bill.billingStatus])}
            </S.StyledSmallButton>
          </td>
        </tr>
      ))}
    </>
  );
};

export default BillTableTr;
