import { useTranslation } from "react-i18next";
import * as S2 from "../../elements/Form/style/Form.style";
import { TitleV3, TitleV4 } from "../../elements/Title/TitleText";
import * as S from "../../styles/Common";
import { getPrecutTeeth } from "../../utils/getPrecutTeeth";
import { IK } from "../../utils/i18n/keyStore";
import { preferenceLabel } from "../../utils/preferenceStatus";
import { CardTitle } from "../layout/CardLayout/CardLayout";

const ClinicalPreference = ({ preferenceData, preCutInfo }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <div>
      <S.ContentBox>
        <S2.FormCardList>
          <li>
            <S2.FormControlsColumn $large>
              {preferenceData?.countryCode === "01" && (
                <div>
                  {/**장치 배송의 기본 단위*/}
                  <TitleV3 title={t(IK.clinical_configurations_item1_title)} blink={preferenceData.CI19 !== "1"} />
                  <p>{preferenceLabel(preferenceData, "CI19", t)}</p>
                </div>
              )}
              <div>
                <TitleV3 title={t(IK.clinical_configurations_item25_title)} blink={preferenceData.CI25 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI25", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            {/**치아 크기 부조화*/}
            <TitleV3 title={t(IK.clinical_configurations_item9_title)} blink={preferenceData.CI10 !== "1"} />
            <p>{preferenceLabel(preferenceData, "CI10", t)}</p>
          </li>
          <li>
            {/**시행 단계의 지연여부*/}
            <TitleV3 title={t(IK.clinical_configurations_item2_title)} titleSub={t(IK.clinical_configurations_item2_title_ex)} />
            <S2.FormControlsColumn $large>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item2_1)} blink={preferenceData.CI05 !== "1"} />
                <p>
                  {preferenceData?.CI05_2} {preferenceLabel(preferenceData, "CI05", t)}
                </p>
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item2_2)} blink={preferenceData.CI04 !== "1"} />
                <p>
                  {preferenceData?.CI04_2} {preferenceLabel(preferenceData, "CI04", t)}
                </p>
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item2_3)} blink={preferenceData.CI06 !== "1"} />
                <p>
                  {preferenceData?.CI06_2} {preferenceLabel(preferenceData, "CI06", t)}
                </p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            {/**Spacing*/}
            <TitleV3 title={t(IK.clinical_configurations_item3_title)} />
            <S2.FormControlsColumn $large>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item3_1)} blink={preferenceData.CI07 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI07", t)}</p>
                {preferenceData?.CI07 === "1" && (
                  <ul>
                    <li>{t(IK.clinical_configurations_item3_1_1_1)}</li>
                  </ul>
                )}
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item3_2)} blink={preferenceData.CI13 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI13", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            {/**악궁 확장*/}
            <TitleV3 title={t(IK.dental_arch_extension)} />
            <S2.FormControlsColumn $large>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item4_1)} blink={preferenceData.CI09 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI09", t)}</p>
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item4_2)} blink={preferenceData.CI08 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI08", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            {/**상악 전치부 배열*/}
            <TitleV3 title={t(IK.clinical_configurations_item5_title)} blink={preferenceData.CI11 !== "1" || preferenceData.CI11_1 !== "2"} />
            <p>
              {preferenceLabel(preferenceData, "CI11", t)}
              {preferenceData?.CI11 === "1" && <> : {preferenceLabel(preferenceData, "CI11_1", t)}</>}
            </p>
          </li>
          <li>
            {/**AP 교정*/}
            <TitleV3 title={t(IK.clinical_configurations_item7_title)} blink={preferenceData.CI16 !== "1"} />
            <p>{preferenceLabel(preferenceData, "CI16", t)}</p>
            {preferenceData?.CI16 === "1" && (
              <ul>
                <li>{t(IK.clinical_configurations_item7_1_1)}</li>
              </ul>
            )}
            <br />
            {/**Elastic cut interface */}
            <TitleV4 title={t(IK.precut_text_1)} blink={!preCutInfo?.defaultValue} />
            <ul>
              <p>{t(IK.precut_class2_1)}</p>
              <ul>
                <li>
                  {t(IK.button_hole)}: {getPrecutTeeth(preCutInfo?.elastic, "class_2", "elastic_bs", "BUTTON_HOLE")}
                </li>
                <li>
                  {t(IK.elastic_cut)}: {getPrecutTeeth(preCutInfo?.elastic, "class_2", "elastic_bs", "ELASTIC_HOOKS")}
                </li>
              </ul>
              <p>{t(IK.precut_class2_2)}</p>
              <ul>
                <li>
                  {t(IK.button_hole)}: {getPrecutTeeth(preCutInfo?.elastic, "class_2", "elastic_ts", "BUTTON_HOLE")}
                </li>
                <li>
                  {t(IK.elastic_cut)}: {getPrecutTeeth(preCutInfo?.elastic, "class_2", "elastic_ts", "ELASTIC_HOOKS")}
                </li>
              </ul>
              <p>{t(IK.precut_class3_1)}</p>
              <ul>
                <li>
                  {t(IK.button_hole)}: {getPrecutTeeth(preCutInfo?.elastic, "class_3", "elastic_bs", "BUTTON_HOLE")}
                </li>
                <li>
                  {t(IK.elastic_cut)}: {getPrecutTeeth(preCutInfo?.elastic, "class_3", "elastic_bs", "ELASTIC_HOOKS")}
                </li>
              </ul>
              <p>{t(IK.precut_class3_2)}</p>
              <ul>
                <li>
                  {t(IK.button_hole)}: {getPrecutTeeth(preCutInfo?.elastic, "class_3", "elastic_ts", "BUTTON_HOLE")}
                </li>
                <li>
                  {t(IK.elastic_cut)}: {getPrecutTeeth(preCutInfo?.elastic, "class_3", "elastic_ts", "ELASTIC_HOOKS")}
                </li>
              </ul>
            </ul>
          </li>
          <li>
            {/**최후방 대구치의 왜곡*/}
            <TitleV3 title={t(IK.clinical_configurations_item12_title)} titleSub={t(IK.clinical_configurations_item12_title_ex)} blink={preferenceData.CI14 !== "1"} />
            <p>{preferenceLabel(preferenceData, "CI14", t)}</p>
            {preferenceData?.CI14 === "1" && (
              <ul>
                <li>{t(IK.clinical_configurations_item12_1_1)}</li>
                <li>{t(IK.clinical_configurations_item12_1_2)}</li>
                <li>{t(IK.clinical_configurations_item12_1_3)}</li>
                <li>{t(IK.clinical_configurations_item12_1_4)}</li>
              </ul>
            )}
            {preferenceData?.CI14 === "2" && (
              <ul>
                <li>{t(IK.clinical_configurations_item12_2_1)}</li>
              </ul>
            )}
            {preferenceData?.CI14 === "3" && (
              <ul>
                <li>{t(IK.clinical_configurations_item12_3_1)}</li>
              </ul>
            )}
          </li>
        </S2.FormCardList>
      </S.ContentBox>
      {/*--------------어린이 임상환경 ----------------- */}
      <S.ContentBox>
        <CardTitle title={t(IK.clinical_configurations_child)} />
        <S2.FormCardList>
          <li>
            <S2.FormControlsColumn $large>
              {preferenceData?.countryCode === "01" && (
                <div>
                  {/**장치 배송의 기본 단위*/}
                  <TitleV3 title={t(IK.clinical_configurations_item1_title)} blink={preferenceData.CI27 !== "1"} />
                  <p>{preferenceLabel(preferenceData, "CI27", t)}</p>
                </div>
              )}
              <div>
                <TitleV3 title={t(IK.clinical_configurations_item25_title)} blink={preferenceData.CI26 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI26", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            <TitleV3 title="IPR" />
            <S2.FormControlsColumn $large>
              <div>
                <TitleV4 title={t(IK.permanent_teeth)} blink={preferenceData.CI20 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI20", t)}</p>
              </div>
              <div>
                <TitleV4 title={t(IK.deciduous_teeth)} blink={preferenceData.CI21 !== "2"} />
                <p>{preferenceLabel(preferenceData, "CI21", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
          <li>
            <TitleV3 title={t(IK.dental_arch_extension)} />
            <S2.FormControlsColumn $large>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item15_title)} blink={preferenceData.CI24 !== "1"} />
                <p>{preferenceLabel(preferenceData, "CI24", t)}</p>
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item14_1_title)} blink={preferenceData.CI22 !== "2"} />
                <p>{preferenceLabel(preferenceData, "CI22", t)}</p>
              </div>
              <div>
                <TitleV4 title={t(IK.clinical_configurations_item14_2_title)} blink={preferenceData.CI23 !== "3"} />
                <p>{preferenceLabel(preferenceData, "CI23", t)}</p>
              </div>
            </S2.FormControlsColumn>
          </li>
        </S2.FormCardList>
      </S.ContentBox>
    </div>
  );
};

export default ClinicalPreference;
