import * as S from "./style/SideBar.style";
import { LogoType2 } from "../../../elements/Logo/Logo";

const SideHead = () => {
  return (
    <S.SideBarHead>
      <LogoType2/>
    </S.SideBarHead>
  );
};

export default SideHead;
