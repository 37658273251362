import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 결제내역 리스트 조회*/
export const useGetBillList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BillList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/billing/list", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**빌링 리스트 잔여 결제 금액 총합 */
export const useGetBillTotal = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BillTotal", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/billing/list-price-left", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 결제 수정 상세 내역 조회*/
export const useGetBillDetail = (billingId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BillDetail", billingId],
    async () => {
      const { data } = await axios.get(`/api/admin/billing/detail?billingId=${billingId}`);
      return data;
    },
    {
      enabled: !!billingId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 결제완료 영수증 상세 조회*/
export const useGetPaymentDetail = (paymentId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PaymentDetail"],
    async () => {
      const { data } = await axios.get(`/api/admin/payment/list/${paymentId}`);
      return data;
    },
    {
      cacheTime: 0,
      enabled: !!paymentId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**결제 요청 상태 저장 */
export const useSaveBilling = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      const response = await axios.put(`/api/admin/billing/save`, data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BillDetail");
        Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.fail_save), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**결제 요청 상태 변경 */
export const useRequestPayment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (billingId) => {
      const response = await axios.put(`/api/admin/billing/request-payment/${billingId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BillDetail");
        Notiflix.Report.success(t(IK.request_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.request_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**결제요청 => 결제예정으로 되돌리기 */
export const useReRequestPayment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (billingId) => {
      const response = await axios.put(`/api/admin/billing/cancel-request/${billingId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BillDetail");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**결제 단건 상태 변경. 이월 ,제외, 직접 결제 */
export const useChangeStatus = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      const response = await axios.put(`/api/admin/billing/change-status`, data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BillDetail");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**결제 리스트 엑셀 다운로드 */
export const getDownloadBillExcel = async (billingId, t, langInfo) => {
  Notiflix.Loading.standard("");
  try {
    const response = await axios.get(`/api/admin/billing/detail/excel/${billingId}`, {
      responseType: "arraybuffer",
      params: {
        language: langInfo,
      },
    });

    const fileBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = `billingList_${billingId}_serafin.xlsx`;
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove(); // 성공 또는 실패 시 로딩 제거
  }
};
