import { useTranslation } from "react-i18next";
import { useGetEventList } from "../apis/eventApi";
import BoardTotal from "../components/board/BoardTotal";
import EventCard from "../components/event/EventCard";
import EventFilter from "../components/event/EventFilter";
import Paging from "../elements/Paging";
import { useSearchParam } from "../hooks/useSearchParam";
import { IK } from "../utils/i18n/keyStore";
import { changeEventParam } from "../utils/searchParams";

//스타일 관련
import { useRecoilValue } from "recoil";
import { userAuth } from "../atom/userAtom";
import BoardSkeleton from "../components/board/BoardSkeleton";
import * as S2 from "../components/board/style/BoardList.style";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import { EmptyCard } from "../elements/EmptyComponent/EmptyCard";
const EventBoard = () => {
  const { t } = useTranslation(["translation"]);

  /**유저정보 조회 */
  const { countryCode } = useRecoilValue(userAuth);

  /**검색 조건 설정*/
  const { searchCondition, setSearchCondition } = useSearchParam({ page: 1 });
  /**페이지 사이즈(고정) */
  const searchSize = 10;

  /**이벤트 리스트 조회 */
  const { data, isLoading } = useGetEventList(changeEventParam(searchCondition));

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  return (
    <S.CardRow>
      {/**검색 필터 */}
      <S.CardCol>
        <S.CardInner>
          <EventFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} isCountryManager={countryCode === "00"} />
        </S.CardInner>
      </S.CardCol>

      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              {/**헤더 */}
              <CardHead line>
                <BoardTotal total={data?.totalElements} />
                {countryCode === "00" && (
                  <S4.StyledButton $primary to="/support/event/post">
                    {t(IK.registration)}
                  </S4.StyledButton>
                )}
              </CardHead>
              {/**이벤트 카드 */}
              {data?.content?.length > 0 ? (
                <S2.BoardCardList>
                  {data?.content?.map((content) => (
                    <EventCard key={content.eventBoardId} content={content} t={t} isCountryManager={countryCode === "00"} />
                  ))}
                </S2.BoardCardList>
              ) : (
                <EmptyCard />
              )}
              {/**페이징 */}
              <CardTail line>
                <Paging totalCount={data?.totalElements} currentPage={searchCondition.page} size={searchSize} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default EventBoard;
