import React from "react";
import { pageListNumber } from "../../utils/pageListNumber";
import CouponCheck from "../coupon/CouponCheck";

const PatientTransferTr = ({ patients, total, page, handleCheck, transfers }) => {
  return (
    <>
      {patients?.map((patient, index) => (
        <tr key={patient?.patientId}>
          <td>
            <CouponCheck handleCheck={() => handleCheck(patient?.patientId)} checked={transfers.patientIds?.includes(patient.patientId)} />
          </td>
          <td>{pageListNumber(total, page, index)}</td>
          <td>{patient?.patientNumber}</td>
          <td>{`${patient?.firstName} ${patient?.lastName}${patient?.korName ? ` (${patient?.korName})` : ""}`}</td>
          <td>{patient?.birthday}</td>
          <td>{patient?.gender === "male" ? "남자" : "여자"}</td>
          <td>{patient?.registedDate}</td>
        </tr>
      ))}
    </>
  );
};

export default PatientTransferTr;
