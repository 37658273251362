import { Link } from "react-router-dom";
import * as S from "./style/Logo.style";
import LogoType1Image from "../../assets/images/common/logo.png";
import LogoType2Image from "../../assets/images/common/logo2.png";

export const LogoType1 = () => {
  return (
    <S.Logo>
      <Link to="/">
        <img src={LogoType1Image} alt="SERAFIN ADMINISTRATOR" />
      </Link>
    </S.Logo>
  );
};

export const LogoType2 = () => {
  return (
    <S.Logo>
      <Link to="/">
        <img src={LogoType2Image} alt="SERAFIN ADMINISTRATOR" />
      </Link>
    </S.Logo>
  );
};