import { css, styled } from "styled-components";

export const SelectionItemList = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 13px 20px;
  ${(props) =>
    props.$gridColumns3fr &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    `}
`;

export const SelectionItem = styled.div`
  ${(props) =>
    props.$Small &&
    css`
      ${SelectionItemInput} {
        top: 2px;
        width: 18px;
        height: 18px;
        &:before {
          top: 1px;
        }
      }
      ${SelectionItemSpan} {
        padding-left: 27px;
        font-size: 14px;
      }
    `}
  ${(props) =>
    props.$labelhidden &&
    css`
      display: flex;
      justify-content: center;
      ${SelectionItemLabel} {
        display: block;
        width: 19px;
        height: 19px;
      }
      ${SelectionItemInput} {
        top: 0;
      }
    `}
`;

export const SelectionItemLabel = styled.label`
  position: relative;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
`;

export const SelectionItemInput = styled.input`
  position: absolute;
  top: 2px;
  width: 19px;
  height: 19px;
  appearance: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #ddd;
  cursor: pointer;
  ${(props) =>
    props.$checkbox &&
    css`
      border-radius: 3px;
      &:checked {
        background-color: ${(props) => props.theme.colors.blue400};
        border-color: ${(props) => props.theme.colors.blue400};
        &:before {
          color: ${(props) => props.theme.colors.white};
        }
      }
      &:before {
        content: "\\f00c";
        font-family: FontAwesome;
        font-size: 10px;
        color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        top: 1px;
        text-align: center;
      }
    `}

  ${(props) =>
    props.$radio &&
    css`
      border-radius: 50%;
      &:checked {
        border: 4px solid ${(props) => props.theme.colors.blue400};
      }
    `}
    &:checked + span {
    font-weight: 500;
    color: ${(props) => props.theme.colors.blue400};
  }
  &:disabled {
    background-color: #ddd;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;

export const SelectionItemSpan = styled.span`
  display: inline-block;
  padding-left: 29px;
  text-align: left;
  font-size: 15px;
`;
