import styled from "styled-components";

export const SideBarContainer = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.gray300};
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const SideBarHead = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 25px;
`;

export const SideBarNav = styled.nav`
  flex: 1 0;
  overflow: auto;
  padding: 15px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: #1a202e;
  }
  &::-webkit-scrollbar-corner {
    background-color: #1a202e;
  }
`;

export const SideBarMenuIco = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  font-size: 17px;
`;

export const SideBarMenuTxt = styled.span`
  margin-right: 10px;
  font-size: 15px;
`;

export const SideBarMenuArrow = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 22px;
  margin-left: auto;
  font-size: 24px;
  transition: all 0.3s;
`;

export const SideBarNavDepth2 = styled.ul`
  display: none;
  margin-top: 8px;
  li a {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    ${SideBarMenuIco} {
      opacity: 0;
      font-size: 24px;
    }
    &.active ${SideBarMenuIco} {
      opacity: 1;
    }
  }
`;

export const SideBarNavDepth1 = styled.ul`
  > li {
    &:not(:first-child) {
      margin-top: 7px;
    }
    > a {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 10px;
      border-radius: 6px;
      &.active,
      &:hover {
        background: ${(props) => props.theme.colors.blue400};
        span {
          color: ${(props) => props.theme.colors.white};
        }
      }
      &.active ${SideBarMenuArrow} {
        transform: rotate(90deg);
      }
      &.active + ${SideBarNavDepth2} {
        display: block;
      }
    }
  }
`;

export const SideBarTail = styled.div`
  padding: 20px 25px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const SideBarMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  li a,
  li button {
    display: flex;
    align-items: center;
  }
`;
