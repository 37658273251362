import Skeleton from "react-loading-skeleton";
import * as S from "./../../../elements/Form/style/Form.style";

const PreferenceSkeleton = () => {
  return (
    <S.FormCardList>
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
    </S.FormCardList>
  );
};

export default PreferenceSkeleton;

export const SkeletonBox = () => {
  return (
    <li>
        <Skeleton width={80} height={30} />
        <Skeleton width={560} height={30} />
    </li>
  );
};
