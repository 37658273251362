import * as S from "./style/Modal.style";

const ModalLayout = ({ open, header, headerSub, handleClose, children, headernone }) => {
  return (
    <S.ModalBox $openModal={open}>
      {open && (
        <section>
          <S.ModalBoxHeader $headernone={headernone}>
            <h3>
              {header} {headerSub && <span>{headerSub}</span>}
            </h3>
            <button className="close" onClick={handleClose}>
              <i className="ri-close-line"></i>
            </button>
          </S.ModalBoxHeader>

          <S.ModalBoxCont $headernone={headernone}>{children}</S.ModalBoxCont>
        </section>
      )}
    </S.ModalBox>
  );
};

export default ModalLayout;
