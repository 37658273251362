import styled from "styled-components";

export const ErrorContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
  height:100vh;
  &:before{
    content:'';
    position:absolute;
    bottom:0;
    width:100%;
    height:50vh;
    background-color:${(props) => props.theme.colors.blue400}
  }
`;

export const ErrorBox = styled.div`
  position:relative;
  min-width:800px;
  padding:60px 100px;
  text-align:center;
  background-color:${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0 40px rgba(46, 55, 164, 0.1);
  h1 {
    line-height:1;
    margin-top:23px;
    font-size:60px;
    font-weight:700
  }
  h2 {
    margin:15px 0;
    font-size:28px;
  }
  p {
    font-size:17px
  }
`;

export const ErrorBtn = styled.div`
  display:inline-flex;
  gap:15px;
  margin-top:30px;
`;