import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

const PopUpFilter = ({ searchCondition, setSearchCondition, isCountryManager }) => {
  const { t } = useTranslation(["translation"]);

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      type: searchCondition?.type || "",
      display: searchCondition?.display || "",
      country_list: searchCondition?.country_list?.split(";") || [],
    },
  });

  const searchs = watch();

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition((prev) => ({ ...prev, ...data, page: 1, country_list: data?.country_list?.join(";") || "" }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      type: "",
      display: "",
      country_list: [],
    });
    setSearchCondition({ page: 1 });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S2.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          {isCountryManager && (
            <tr>
              <th scope="row">{t(IK.select_country)}</th>
              <td>
                <CountryBtn setValue={setValue} fieldName="country_list" watch={searchs?.country_list} />
              </td>
              <th scope="row">{`${t(IK.country)} ${t(IK.list)}`}</th>
              <td>{searchs?.country_list?.length > 0 && <SelectedCountryList watch={searchs.country_list} fieldName="country_list" setValue={setValue} t={t} />}</td>
            </tr>
          )}
          <tr>
            <th>{t(IK.pop_type)}</th>
            <td>
              <S3.FormControls $select as="select" {...register("type")}>
                <option value="">{t(IK.select)}</option>
                <option value="IMAGE">{t(IK.pop_type_image)}</option>
                <option value="YOUTUBE">{t(IK.pop_type_video)}</option>
              </S3.FormControls>
            </td>
            <th>{t(IK.pop_used)}</th>
            <td>
              <S3.FormControls $select as="select" {...register("display")}>
                <option value="">{t(IK.select)}</option>
                <option value={true}>{t(IK.pop_used_use)}</option>
                <option value={false}>{t(IK.pop_used_not_use)}</option>
              </S3.FormControls>
            </td>
          </tr>
        </tbody>
      </S2.TableType2>
      <S4.ButtonCtBox>
        <S4.StyledButton type="button" onClick={() => onReset()} as="button">
          <i className="ri-refresh-line"></i> {t(IK.reset)}
        </S4.StyledButton>
        <S4.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line"></i> {t(IK.search)}
        </S4.StyledButton>
      </S4.ButtonCtBox>
    </form>
  );
};

export default PopUpFilter;
