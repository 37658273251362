import * as S from "./style/Title.style";
import * as S2 from "../../styles/Common";

/**에러 메세지 */
export const TitleV3 = ({ title, titleSub, blink }) => {
  return (
    <S.TitleV3>
      <S2.TextDiv as="h2" $blink={blink}>
        {title}
      </S2.TextDiv>
      {titleSub && <span>{titleSub}</span>}
    </S.TitleV3>
  );
};

export const TitleV4 = ({ title, blink }) => {
  return (
    <S.TitleV4>
      <S2.TextDiv as="h2" $blink={blink}>
        {title}
      </S2.TextDiv>
    </S.TitleV4>
  );
};
