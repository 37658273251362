import { useTranslation } from "react-i18next";
import { useGetPreCut, useGetPreference } from "../../apis/studyApi";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";
import ClinicalPreference from "../preference/ClinicalPreference";
import PreferenceSkeleton from "./studySkeleton/PreferenceSkeleton";

const PreferenceModal = ({ openPreference, setOpenPreference, patientId }) => {
  const { t } = useTranslation(["translation"]);

  /**임상환경 정보 조회 */
  const { data, isLoading } = useGetPreference(patientId);
  const preferenceData = data?.data;

  /**프리시젼 컷 정보 조회 */
  const { data: preCutData, isLoading: isPrecutLoading } = useGetPreCut(patientId);
  const preCutInfo = preCutData?.data;

  /**모달 닫기 */
  const handleClose = () => {
    setOpenPreference(false);
  };

  return (
    <ModalLayout open={openPreference} handleClose={handleClose} header={t(IK.clinical_configurations)}>
      {isLoading || isPrecutLoading ? <PreferenceSkeleton /> : <ClinicalPreference preferenceData={preferenceData} preCutInfo={preCutInfo} />}
    </ModalLayout>
  );
};

export default PreferenceModal;
