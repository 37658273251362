import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 알람 카운트 30분 간격으로 조회 */
export const useGetAlarmCount = () => {
  return useQuery(
    ["AlarmCount"],
    async () => {
      const { data } = await axios.get(`/api/alarm/count`, {
        params: {
          alarmCode: "ADMIN",
        },
      });
      return data;
    },
    {
      refetchInterval: 30 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );
};

/**알림 검색 카운트 */
export const useGetAlarmSearchCount = (alarmParams) => {
  const { alarmFilterCode, ...rest } = alarmParams;
  return useQuery(
    ["AlarmSearchCount", rest],
    async () => {
      const { data } = await axios.get(`/api/alarm/admin/filter-count`, {
        params: {
          ...rest,
        },
      });
      return data;
    },
    {
      refetchInterval: 30 * 60 * 1000,
      refetchIntervalInBackground: true, //
    }
  );
};

/**알림 내역 무한스크롤 */
export const useGetInfinityAlarmList = (payload, setAlarmList, setAlarmLoading) => {
  const getAlarmScroll = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`/api/alarm/list`, {
      params: {
        ...payload,
        page: pageParam,
      },
    });
    return data;
  };
  const { fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } = useInfiniteQuery(["AlarmList", payload], getAlarmScroll, {
    cacheTime: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.data.last ? undefined : lastPage.data.number + 1;
    },
    onSuccess: (res) => {
      const alarmList = res.pages.map((page) => page.data.content).flat();
      setAlarmList(alarmList);
      setAlarmLoading(false);
    },
  });

  return { fetchNextPage, isLoading, hasNextPage, isFetchingNextPage };
};

/** 알람 읽음 처리 */
export const useReadAlarm = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (alarmId) => {
      await axios.put(`/api/alarm/${alarmId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AlarmList");
      },
      onError: () => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/** 알람 삭제 {list: 전체, id: 알람 아이디} */
export const useDeleteAlarm = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (alarmId) => {
      await axios.delete(`/api/alarm/${alarmId}`, {
        params: {
          alarmCode: "ADMIN",
        },
      });
    },
    {
      onSuccess: () => {
        /**알람리스트 삭제후 리패칭 안함, 화면에서만 제거 */
        queryClient.invalidateQueries("AlarmCount");
        queryClient.invalidateQueries("AlarmSearchCount");
        Notiflix.Notify.success(t(IK.delete_notifications_message1));
      },
      onError: () => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/**알림 선택 삭제 */
export const useDeleteSelectedAlarm = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (alarmIds) => {
      await axios.delete(`/api/alarm/check`, {
        data: {
          ids: alarmIds,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AlarmCount");
        queryClient.invalidateQueries("AlarmSearchCount");
        queryClient.invalidateQueries("AlarmList");
        Notiflix.Notify.success(t(IK.delete_notifications_message1));
      },
      onError: () => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};
