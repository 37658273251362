import React from "react";
import { useTranslation } from "react-i18next";
import { codeToCountryArray, countryArrayToString, reorderCountryCodes } from "../../utils/countryUtils";
import * as S from "../board/style/BoardList.style";

const CountryBadge = ({ countryCodes }) => {
  const { t } = useTranslation(["translation"]);

  //검색한 뱃지로 보일 수 있게 국가코드(string)을 배열로 변환
  const searchCountry = new URL(window.location.href).searchParams.get("country_list")?.split(";");

  //해당 검색어가 countryCodes에 포함되어 있으면 순서를 앞으로 땡김
  //배열이면 그대로, 아닌 경우 변환 처리
  const reorderedCountryCodes = Array.isArray(countryCodes) ? countryCodes : reorderCountryCodes(countryCodes?.split(","), searchCountry);

  /**국가코드 국가명 배열로 변환 */
  const countries = codeToCountryArray(reorderedCountryCodes);

  return (
    <S.BoardBadge>
      {/**국가별 뱃지(3개 넘을 경우 3개까지만 표출) */}
      {countries.length > 3 ? (
        <>
          <span>
            {`${countryArrayToString(countries.slice(0, 3), t)}`}
            <i className="ri-add-line" />
          </span>
          <S.BoardBadgeToolTipWrap>
            <S.BoardBadgeToolTipCont>
              <div>{countryArrayToString(countries, t)}</div>
            </S.BoardBadgeToolTipCont>
          </S.BoardBadgeToolTipWrap>
        </>
      ) : (
        <span>{countryArrayToString(countries, t)}</span>
      )}
    </S.BoardBadge>
  );
};

export default CountryBadge;
