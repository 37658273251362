import { IK } from "./i18n/keyStore";

/**임상환경 라벨링 */
export const preferenceLabel = (studyInfo, key, t) => {
  const valueLabel = {
    CI19: {
      1: t(IK.clinical_configurations_item1_1),
      2: t(IK.clinical_configurations_item1_2),
    },
    CI05: {
      1: t(IK.not_delayed),
      2: t(IK.delays_step),
    },
    CI04: {
      1: t(IK.not_delayed),
      2: t(IK.delays_step),
    },
    CI06: {
      1: t(IK.not_delayed),
      2: t(IK.delays_step),
    },
    CI08: {
      1: t(IK.clinical_configurations_item4_2_1),
      2: t(IK.clinical_configurations_item4_2_2),
      3: t(IK.clinical_configurations_item4_2_3),
      4: t(IK.clinical_configurations_item4_2_4),
    },
    CI09: {
      1: t(IK.clinical_configurations_item4_1_1),
      2: t(IK.clinical_configurations_item4_1_2),
    },
    CI16: {
      1: t(IK.clinical_configurations_item7_1),
      2: t(IK.clinical_configurations_item7_2),
    },
    CI11: {
      1: t(IK.clinical_configurations_item5_1),
      2: t(IK.clinical_configurations_item5_2),
    },
    CI11_1: {
      1: t(IK.clinical_configurations_item5_1_1),
      2: t(IK.clinical_configurations_item5_1_2),
    },
    CI10: {
      1: t(IK.clinical_configurations_item9_1),
      2: t(IK.clinical_configurations_item9_2),
      3: t(IK.clinical_configurations_item9_3),
    },
    CI13: {
      1: t(IK.not_applied),
      2: t(IK.applied),
    },
    CI07: {
      1: t(IK.clinical_configurations_item3_1_1),
    },
    CI14: {
      1: t(IK.clinical_configurations_item12_1),
      2: t(IK.clinical_configurations_item12_2),
      3: t(IK.clinical_configurations_item12_3),
    },
    CI20: {
      1: t(IK.implemented),
      2: t(IK.not_implemented),
    },
    CI21: {
      1: t(IK.implemented),
      2: t(IK.not_implemented),
    },
    CI22: {
      1: t(IK.clinical_configurations_item14_1_1),
      2: t(IK.clinical_configurations_item14_1_2),
    },
    CI23: {
      1: "< 2 mm",
      2: "2-4 mm",
      3: "4-6 mm",
      4: "6-8 mm",
      5: "8 < mm",
    },
    CI24: {
      1: t(IK.clinical_configurations_item15_1),
      2: t(IK.clinical_configurations_item15_2),
    },
    CI25: {
      1: t(IK.clinical_configurations_item25_1),
      2: t(IK.clinical_configurations_item25_2),
    },
    CI26: {
      1: t(IK.clinical_configurations_item25_1),
      2: t(IK.clinical_configurations_item25_2),
    },
    CI27: {
      1: t(IK.clinical_configurations_item1_1),
      2: t(IK.clinical_configurations_item1_2),
    },
  };
  const value = studyInfo[key] || [];
  if (value.includes(",")) {
    // 쉼표가 포함된 경우 여러 개의 레이블을 가져와 합쳐서 반환
    const splitArr = value.split(",");
    return splitArr.map((item) => valueLabel[key][item]).join(", ");
  }
  return valueLabel[key][value];
};
