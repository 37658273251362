import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDoctorLoadExcel, useGetDoctorList } from "../apis/doctorApi";
import BoardTotal from "../components/board/BoardTotal";
import DoctorFilter from "../components/doctor/DoctorFilter";
import { DoctorTableTr } from "../components/doctor/DoctorTableTr";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { createdChangeParam, urlParamToObject } from "../utils/searchParams";

const DoctorList = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  const { data: doctorData, isLoading } = useGetDoctorList(createdChangeParam(searchCondition));
  const doctors = doctorData?.data;

  /**엑셀 다운로드 */
  const handleExcel = () => {
    const langInfo = localStorage.getItem("langInfo");
    getDoctorLoadExcel(createdChangeParam({ ...searchCondition, page: 1 }), t, langInfo);
  };

  const tableCol = ["7%", "*", "8%", "8%", "7%", "7%", "7%", "14%", "7%", "7%", "7%", "7%", "6%"];
  const tableHaed = [
    t(IK.doctor_number),
    t(IK.name),
    t(IK.id),
    t(IK.contact),
    t(IK.doctor_license),
    t(IK.doctor_type),
    t(IK.doctor_type_classification),
    t(IK.doctor_address),
    t(IK.doctor_application),
    t(IK.doctor_studying),
    t(IK.doctor_discount_grade),
    t(IK.video_call),
    t(IK.doctor_marketing),
  ];

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <DoctorFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} />
        </S.CardInner>
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHaed} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={doctors?.totalElements} />
              <S2.StyledButton $excel type="submit" onClick={handleExcel} as="button">
                <i className="ri-file-excel-2-line"></i> {t(IK.download_doctor_list)}
              </S2.StyledButton>
            </CardHead>
            <DataTable tableCol={tableCol} tableHead={tableHaed} dataLength={doctors.content?.length} tableTr={<DoctorTableTr doctors={doctors?.content} />} />
            <CardTail>
              <Paging totalCount={doctors?.totalElements} currentPage={searchCondition.page} size={doctors?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default DoctorList;
