import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditAdditionalEndDate, useEditEndDate, useEditExpectedDate, useEditStartDate } from "../../../apis/studyApi";
import * as S2 from "../../../elements/Button/style/ButtonLayout.style";
import { TimePicker } from "../../../elements/DatePicker";
import * as S from "../../../elements/Form/style/Form.style";
import { IK } from "../../../utils/i18n/keyStore";

function StudyViewEditDate({ studyInfo, studyId, studyAuth }) {
  const { t } = useTranslation(["translation"]);

  /**처방전 시작일 날짜 변경 */
  const [isEditStartDate, setIsEditStartDate] = useState(false);
  const [editStartDate, setEditStartDate] = useState(studyInfo?.startDate);
  /**처방전 만료일 날짜 변경 */
  const [isEditEndDate, setIsEditEndDate] = useState(false);
  const [editEndDate, setEditEndDate] = useState(studyInfo?.endDate);
  /**처방전 세라뷰 예상일 날짜 변경 */
  const [isEditExpectedDate, setIsEditExpectedDate] = useState(false);
  const [editExpectedDate, setEditExpectedDate] = useState(studyInfo?.expectedDate);
  /**처방전 추가교정장치 만료일 날짜 변경 */
  const [isEditAdditionalDate, setIsEditAdditionalDate] = useState(false);
  const [editAdditionalDate, setEditAdditionalDate] = useState(studyInfo?.additionalEndDate);

  useEffect(() => {
    setEditStartDate(studyInfo?.startDate);
    setEditEndDate(studyInfo?.endDate);
    setEditExpectedDate(studyInfo?.expectedDate);
    setEditAdditionalDate(studyInfo?.additionalEndDate);
  }, [studyInfo]);

  /**처방전 시작일 변경 */
  const handleStartDate = (date) => {
    if (date) {
      setEditStartDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setEditStartDate(dayjs(studyInfo?.startDate).format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const startDateMutation = useEditStartDate();
  /**처방전 시작일 등록 */
  const changeStartDate = () => {
    startDateMutation.mutate(
      {
        studyId,
        data: {
          dateTime: editStartDate,
        },
      },
      {
        onSuccess: () => {
          setIsEditStartDate(false);
        },
      }
    );
  };

  /**처방전 만료일 변경 */
  const handleEndDate = (date) => {
    if (date) {
      setEditEndDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setEditEndDate(dayjs(studyInfo?.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const endDateMutation = useEditEndDate();
  /**처방전 만료일 등록 */
  const changeEndDate = () => {
    endDateMutation.mutate(
      {
        studyId,
        data: {
          dateTime: editEndDate,
        },
      },
      {
        onSuccess: () => {
          setIsEditEndDate(false);
        },
      }
    );
  };

  /**처방전 세라뷰 예상일 변경 */
  const handleExpectedDate = (date) => {
    if (date) {
      setEditExpectedDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setEditExpectedDate(dayjs(studyInfo?.expectedDate).format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const expectedDateMutation = useEditExpectedDate();
  /**처방전 세라뷰 예상일 등록 */
  const changeExpectedDate = (status) => {
    //초기화
    if (status === "notShow") setEditExpectedDate(null);

    expectedDateMutation.mutate(
      {
        studyId,
        data: {
          dateTime: status === "notShow" ? null : editExpectedDate,
        },
      },
      {
        onSuccess: () => {
          setIsEditExpectedDate(false);
        },
      }
    );
  };

  const additionalDateMutation = useEditAdditionalEndDate();

  /**처방전 추가교정장치 만료일 변경 */
  const handleAdditionalDate = (date) => {
    if (date) {
      setEditAdditionalDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setEditAdditionalDate(dayjs(studyInfo?.additionalEndDate).format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const changeAdditionalDate = () => {
    additionalDateMutation.mutate(
      {
        studyId,
        data: {
          dateTime: editAdditionalDate,
        },
      },
      {
        onSuccess: () => {
          setIsEditAdditionalDate(false);
        },
      }
    );
  };

  return (
    <>
      {(studyInfo?.detail === "14" || studyInfo?.detail === "18") && (
        <>
          <dl>
            <dt> {studyInfo?.detail === "14" ? t(IK.seraview_expected) : t(IK.expacted_date_device)}</dt>
            <dd>
              <S.FormControlsFlex>
                <TimePicker
                  selected={editExpectedDate && new Date(editExpectedDate)}
                  handleDataChange={handleExpectedDate}
                  disabled={!isEditExpectedDate}
                  formTimeControls
                  placeholderText={t(IK.not_show)}
                />
                {isEditExpectedDate ? (
                  <S2.ButtonFlex $small>
                    <S2.StyledSmallButton as="button" type="button" onClick={() => changeExpectedDate("notShow")}>
                      {t(IK.not_show)}
                    </S2.StyledSmallButton>
                    <S2.StyledSmallButton as="button" type="button" onClick={changeExpectedDate}>
                      {t(IK.registration)}
                    </S2.StyledSmallButton>
                    <S2.StyledSmallButton as="button" type="button" $secondary onClick={() => setIsEditExpectedDate(false)}>
                      {t(IK.cancel)}
                    </S2.StyledSmallButton>
                  </S2.ButtonFlex>
                ) : (
                  <>
                    {studyAuth.studyDateFix && (
                      <S2.StyledSmallButton as="button" type="button" onClick={() => setIsEditExpectedDate(true)}>
                        {t(IK.modify)}
                      </S2.StyledSmallButton>
                    )}
                  </>
                )}
              </S.FormControlsFlex>
            </dd>
          </dl>
        </>
      )}
      {!!studyInfo?.startDate && (
        <dl>
          <dt>{t(IK.treat_create_date)}</dt>
          <dd>
            <S.FormControlsFlex>
              <TimePicker selected={editStartDate && new Date(editStartDate)} handleDataChange={handleStartDate} disabled={!isEditStartDate} formTimeControls />
              {isEditStartDate ? (
                <S2.ButtonFlex $small>
                  <S2.StyledSmallButton as="button" type="button" onClick={changeStartDate}>
                    {t(IK.registration)}
                  </S2.StyledSmallButton>
                  <S2.StyledSmallButton as="button" type="button" $secondary onClick={() => setIsEditStartDate(false)}>
                    {t(IK.cancel)}
                  </S2.StyledSmallButton>
                </S2.ButtonFlex>
              ) : (
                <>
                  {studyAuth.studySuperAuth && (
                    <S2.StyledSmallButton as="button" type="button" onClick={() => setIsEditStartDate(true)}>
                      {t(IK.modify)}
                    </S2.StyledSmallButton>
                  )}
                </>
              )}
            </S.FormControlsFlex>
          </dd>
        </dl>
      )}
      {!!studyInfo?.endDate && (
        <dl>
          <dt> {t(IK.end_date)}</dt>
          <dd>
            <S.FormControlsFlex>
              <TimePicker selected={editEndDate && new Date(editEndDate)} handleDataChange={handleEndDate} disabled={!isEditEndDate} formTimeControls />
              {isEditEndDate ? (
                <S2.ButtonFlex $small>
                  <S2.StyledSmallButton as="button" type="button" onClick={changeEndDate}>
                    {t(IK.registration)}
                  </S2.StyledSmallButton>
                  <S2.StyledSmallButton as="button" type="button" $secondary onClick={() => setIsEditEndDate(false)}>
                    {t(IK.cancel)}
                  </S2.StyledSmallButton>
                </S2.ButtonFlex>
              ) : (
                <>
                  {studyAuth.studyDateFix && (
                    <S2.StyledSmallButton as="button" type="button" onClick={() => setIsEditEndDate(true)}>
                      {t(IK.modify)}
                    </S2.StyledSmallButton>
                  )}
                </>
              )}
            </S.FormControlsFlex>
          </dd>
        </dl>
      )}
      {!!studyInfo?.additionalEndDate && (
        <dl>
          <dt> {t(IK.additional_study_end_date)}</dt>
          <dd>
            <S.FormControlsFlex>
              <TimePicker selected={editAdditionalDate && new Date(editAdditionalDate)} handleDataChange={handleAdditionalDate} disabled={!isEditAdditionalDate} formTimeControls />
              {isEditAdditionalDate ? (
                <S2.ButtonFlex $small>
                  <S2.StyledSmallButton as="button" type="button" onClick={changeAdditionalDate}>
                    {t(IK.registration)}
                  </S2.StyledSmallButton>
                  <S2.StyledSmallButton as="button" type="button" $secondary onClick={() => setIsEditAdditionalDate(false)}>
                    {t(IK.cancel)}
                  </S2.StyledSmallButton>
                </S2.ButtonFlex>
              ) : (
                <>
                  {studyAuth.studyDateFix && (
                    <S2.StyledSmallButton as="button" type="button" onClick={() => setIsEditAdditionalDate(true)}>
                      {t(IK.modify)}
                    </S2.StyledSmallButton>
                  )}
                </>
              )}
            </S.FormControlsFlex>
          </dd>
        </dl>
      )}
    </>
  );
}

export default StudyViewEditDate;
