import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDoctorCoinHistory } from "../../apis/doctorApi";
import { EmptyCard } from "../../elements/EmptyComponent/EmptyCard";
import Paging from "../../elements/Paging";
import * as S from "../../elements/Table/style/Table.style";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { changeParam } from "../../utils/searchParams";
import LoadingSpinner from "../Loading/LoadingSpinner";
import ModalLayout from "../Modal/ModalLayout";
import { BoardBottom } from "../layout/BoardLayout/BoardLayout";

const CoinHistoryModal = ({ coinModal, setCoinModal, doctorId }) => {
  const { t } = useTranslation(["translation"]);

  //검색쿼리
  const [searchQuery, setSearchQuery] = useState({ page: 1 });

  /**코인 리스트 조회 */
  const { data, isLoading } = useGetDoctorCoinHistory(coinModal, doctorId, changeParam(searchQuery));
  const coinList = data?.content;

  /**페이지 설정 */
  const handleChangePage = (page) => {
    setSearchQuery((prev) => ({ ...prev, page }));
  };

  //모달 닫기
  const onClose = () => {
    setCoinModal(false);
  };

  const coinType = {
    CHARGE: t(IK.pay_charging),
    DEDUCTION: t(IK.pay_using),
    SERA_QUICK: t(IK.coin_seraquick),
    SERA_QUICK_FAILED: t(IK.coin_seraquick_failed),
    AUTO_CEPH: t(IK.coin_autoceph),
    STUDY_COMPLETE: t(IK.coin_study_complete),
    STUDY_REJECT: t(IK.reject_study),
  };

  return (
    <ModalLayout open={coinModal} handleClose={onClose} header={t(IK.coin_use_history)}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <S.TableType1>
            <colgroup>
              <col width="130px"></col>
              <col width="100px"></col>
              <col width="200px"></col>
              <col width="130px"></col>
            </colgroup>
            <thead>
              <tr>
                <th>{t(IK.prepaid_category)}</th>
                <th>{t(IK.coin)}</th>
                <th>{t(IK.memo)}</th>
                <th>{t(IK.prepaid_date)}</th>
              </tr>
            </thead>
            <tbody>
              {coinList?.length > 0 ? (
                coinList?.map((coin) => (
                  <tr key={coin.coinHistoryId}>
                    <td>{coinType[coin?.type]}</td>
                    <td>
                      <S2.TextDiv $textColorBlue400 $textColorRed600={coin?.amount < 0}>
                        {coin?.amount}
                      </S2.TextDiv>
                    </td>
                    <td>{coin?.memo}</td>
                    <td>{coin?.createdDate}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <EmptyCard />
                  </td>
                </tr>
              )}
            </tbody>
          </S.TableType1>

          <BoardBottom>
            <Paging totalCount={data?.totalElements} currentPage={searchQuery.page} size={10} handleChangePage={handleChangePage} />
          </BoardBottom>
        </>
      )}
    </ModalLayout>
  );
};

export default CoinHistoryModal;
