import Skeleton from "react-loading-skeleton";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import * as S4 from "../../styles/Common";
import * as S3 from "../board/style/BoardList.style";
import { CardTail } from "../layout/CardLayout/CardLayout";
import * as S from "../layout/CardLayout/style/CardLayout.style";

const BillViewSkeleton = ({ tableCol, tableHead }) => {
  return (
    <S.CardInner>
      <S.CardTitle>
        <h2>
          <Skeleton width={500} height={40} />
        </h2>
      </S.CardTitle>
      <S3.BoardTable>
        <colgroup>
          {tableCol?.map((col, i) => (
            <col key={i} width={col} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {tableHead?.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <tr key={index}>
              {tableHead?.map((head, i) => (
                <td key={i}>
                  <Skeleton height={24} width={head.length * 15} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </S3.BoardTable>
      <S4.ContentBox>
        <Skeleton height={120} />
      </S4.ContentBox>
      <S2.ButtonCtBox>
        <Skeleton height={40} width={100} />
      </S2.ButtonCtBox>
      <CardTail line>
        <S2.ButtonLtBox>
          <Skeleton height={40} width={100} />
        </S2.ButtonLtBox>
      </CardTail>
    </S.CardInner>
  );
};

export default BillViewSkeleton;
