import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S4 from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../elements/Form/style/Form.style";
import * as S from "../../elements/Table/style/Table.style";
import { permissions } from "../../utils/centerStatus";
import { IK } from "../../utils/i18n/keyStore";

const CenterSearchFilter = ({ setSearchCondition, searchCondition }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, reset } = useForm();

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      name: "",
      roleName: "",
    });
    setSearchCondition({ page: 1 });
  };

  return (
    <S4.CardInner>
      <form onSubmit={handleSubmit(onSubmitSearch)}>
        <S.TableType2>
          <colgroup>
            <col width="200px"></col>
            <col width="*"></col>
            <col width="200px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{t(IK.name)}</th>
              <td>
                <S2.FormControls placeholder={t(IK.name)} $regular defaultValue={searchCondition?.name} {...register("name")} />
              </td>
              <th scope="row">{t(IK.center_permissions)}</th>
              <td>
                <S2.FormControls {...register("roleName")} defaultValue={searchCondition?.roleName} as="select" $select>
                  {permissions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {t(option.text)}
                    </option>
                  ))}
                </S2.FormControls>
              </td>
            </tr>
          </tbody>
        </S.TableType2>
        <S3.ButtonCtBox>
          <S3.StyledButton type="button" onClick={() => onReset()} as="button">
            <i className="ri-refresh-line"></i> {t(IK.reset)}
          </S3.StyledButton>
          <S3.StyledButton $primary type="submit" as="button">
            <i className="ri-search-2-line"></i> {t(IK.search)}
          </S3.StyledButton>
        </S3.ButtonCtBox>
      </form>
    </S4.CardInner>
  );
};

export default CenterSearchFilter;
