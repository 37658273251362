import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import * as S from "../../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../elements/Tab/style/Tab.style";
import * as S4 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import StudyViewDelivery from "../studyContent/StudyViewDelivery";
import StudyViewSetup from "../studyContent/StudyViewSetup";
import StudyViewSummary from "../studyContent/StudyViewSummary";
import * as S3 from "../style/Study.style";

const StudyDevice = ({ studyId, detail, studyInfo, isNowStudy, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  const componentRef = useRef();
  /**프린트 실행*/
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**컴포넌트 클릭 상태값 관리 */
  const [btn, setBtn] = useState("summary_setup");

  const components = {
    summary_setup: (
      <>
        {/**현재 진행중인 스터디인 경우 15이상(접수 이후)에 RP가 아닌 경우에만 디자인 탭 보여줌 */}
        {(isNowStudy ? studyInfo?.packages !== "RP" && detail >= "15" : studyInfo?.packages !== "RP") && (
          <S4.ContentBox>
            <StudyViewSetup studyId={studyId} detail={detail} studyInfo={studyInfo} isNowStudy={isNowStudy} studyAuth={studyAuth} />
          </S4.ContentBox>
        )}
        <S4.ContentBox>
          <S3.PrintWrapper ref={componentRef}>
            <StudyViewSummary studyId={studyId} handlePrint={handlePrint} studyAuth={studyAuth} />
          </S3.PrintWrapper>
        </S4.ContentBox>
      </>
    ),
    delivery: <StudyViewDelivery studyId={studyId} studyInfo={studyInfo} studyAuth={studyAuth} />,
  };

  /**RP인 경우 요약으로 이름 처리 */
  const tabName = (btn) => {
    if (btn === "summary_setup" && studyInfo?.packages === "RP") {
      return "prescription_summary";
    }
    return btn;
  };

  //현재 진행중인 스터디인 경우 15이상(접수 이후)에만 탭 보여줌
  return (
    <S.CardInner>
      {(isNowStudy ? detail >= "15" : true) && (
        <S2.TabType2>
          {Object.keys(components).map((key) => (
            <S2.TabType2Item key={key} onClick={() => setBtn(key)} $active={btn === key}>
              {t(IK[tabName(key)])}
            </S2.TabType2Item>
          ))}
        </S2.TabType2>
      )}
      {components[btn]}
    </S.CardInner>
  );
};

export default StudyDevice;
