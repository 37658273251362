import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropImageBox } from "../../elements/Drop/DropImageBox";
import { IK } from "../../utils/i18n/keyStore";
import { studyExtroImages, studyIntroImages, studyRadiographs } from "../../utils/studyStatus";
import ImageViewer from "react-simple-image-viewer";
import * as S from "../../styles/Common";

const PatientFileInfo = ({ file }) => {
  const { t } = useTranslation(["translation"]);

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  return (
    <>
      <DropImageBox text={t(IK.extraoral)} imagesArr={studyExtroImages} file={file} handleImageViewer={handleImageViewer}/>
      <DropImageBox text={t(IK.intraoral)} imagesArr={studyIntroImages} file={file} handleImageViewer={handleImageViewer}/>
      <DropImageBox text={t(IK.radiograph)} imagesArr={studyRadiographs} file={file} isRadiation handleImageViewer={handleImageViewer}/>

      {imageViewer.isView && (
        <S.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S.ImageViewerBox>
      )}
    </>
  );
};

export default PatientFileInfo;
