import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { getAminLink, useGetDeliveryInfo, useSetDeliveryInfo } from "../../../apis/studyApi";
import * as S3 from "../../../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../../../elements/DatePicker";
import * as S2 from "../../../elements/Form/style/Form.style";
import { ErrorMessage } from "../../../elements/Message/MessageText";
import * as S from "../../../elements/Table/style/Table.style";
import { enterKeyDown } from "../../../utils/enterKeydown";
import { IK } from "../../../utils/i18n/keyStore";
import { CardTail } from "../../layout/CardLayout/CardLayout";

const StudyViewDelivery = ({ studyId, additionalId, replacementId, studyInfo }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();
  /**배송정보 조회 */
  const { data, isLoading } = useGetDeliveryInfo(studyId, additionalId, replacementId);
  const deliveryInfo = data?.data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [deliveryDate, setDeliveryDate] = useState();

  useEffect(() => {
    if (!isLoading && deliveryInfo && !!deliveryInfo?.shipDate) {
      setDeliveryDate(deliveryInfo?.shipDate);
    }
  }, [isLoading, deliveryInfo]);

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setDeliveryDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setDeliveryDate(new Date());
    }
  };

  const setDeliveryMutation = useSetDeliveryInfo();
  /**배송정보 전송 */
  const handleDelivery = (data) => {
    const payload = {
      studyId,
      data: {
        shipDate: dayjs(deliveryDate || deliveryInfo?.shipDate).format("YYYY-MM-DD HH:mm:ss"),
        ...data,
      },
    };

    if (additionalId) {
      //추가교정장치일 경우 아이디값 추가
      payload.data.additionalId = additionalId;
    }

    if (replacementId) {
      //교체교정장치일 경우 아이디값 추가
      payload.data.replacementId = replacementId;
    }

    setDeliveryMutation.mutate(payload);
  };

  /**배송 링크 조회 */
  const handledeliveryLink = () => {
    const deliveryPayload = {
      patientId: studyInfo.patientId,
      studyId,
      type: "DELIVERY",
      web: "admin",
    };

    if (additionalId) {
      deliveryPayload.additionalId = additionalId;
    }

    if (replacementId) {
      deliveryPayload.replacementId = replacementId;
    }

    getAminLink(deliveryPayload, t).then((res) => {
      window.open(res.data, "_blank");
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={150} />
      ) : (
        <form onSubmit={handleSubmit(handleDelivery)} onKeyDown={enterKeyDown}>
          <S.TableType2>
            <colgroup>
              <col width="150px"></col>
              <col width="*"></col>
              <col width="150px"></col>
              <col width="*"></col>
              <col width="150px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{t(IK.delivery_start)}</th>
                <td>
                  <DatePicker selected={deliveryDate && new Date(deliveryDate)} handleDataChange={handleStartDate} formControls />
                </td>
                <th scope="row">{t(IK.delivery_company_select)}</th>
                <td>
                  <S2.FormControls
                    as="select"
                    $select
                    {...register("courierCode", {
                      required: t(IK.delivery_company_required),
                    })}
                    defaultValue={deliveryInfo?.courierCode}
                  >
                    <option value="">{t(IK.delivery_company_select)}</option>
                    <option value="04">CJ 대한통운</option>
                  </S2.FormControls>
                  <ErrorMessage errors={errors} name="courierCode" />
                </td>
                <th scope="row">{t(IK.delivery_code)}</th>
                <td>
                  <S2.FormControls
                    $full
                    defaultValue={deliveryInfo?.trackingNumber}
                    type="text"
                    {...register("trackingNumber", {
                      required: t(IK.delivery_code_required),
                    })}
                  />
                  <ErrorMessage errors={errors} name="trackingNumber" />
                </td>
              </tr>
            </tbody>
          </S.TableType2>

          <CardTail line>
            <S3.ButtonLtBox>
              <S3.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
                {t(IK.list)}
              </S3.StyledButton>
              {deliveryInfo?.trackingNumber && (
                <S3.StyledButton as="button" type="button" $secondary onClick={handledeliveryLink}>
                  {t(IK.tracking_delivery)}
                </S3.StyledButton>
              )}
              <S3.StyledButton as="button" type="submit" $primary>
                {t(IK.save)}
              </S3.StyledButton>
            </S3.ButtonLtBox>
          </CardTail>
        </form>
      )}
    </>
  );
};

export default StudyViewDelivery;
