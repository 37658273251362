import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { IK } from "../utils/i18n/keyStore";

/**보드 게시판 리스트 조회
 * @param {Object} searchCondition - 검색조건
 */
export const useGetBoardList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BoardList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/boards", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: () => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**보드 게시판 상세 조회
 * @param {string} boardId - 게시판 아이디
 */
export const useGetBoardInfo = (boardId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BoardInfo", boardId],
    async () => {
      const { data } = await axios.get(`/api/admin/boards/${boardId}`);
      return data;
    },
    {
      enabled: !!boardId,
      onError: () => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 게시판 글 작성*/
export const useAddBoard = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/admin/boards`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BoardList");
        Notiflix.Loading.remove();
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
        Notiflix.Loading.remove();
      },
    }
  );
};

/** 게시판 글 수정*/
export const useEditBoard = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.put(`/api/admin/boards/${data.boardId}`, data.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BoardInfo");
        Notiflix.Loading.remove();
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
        Notiflix.Loading.remove();
      },
    }
  );
};

/** 게시판 글 삭제*/
export const useDeleteBoard = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();

  return useMutation(
    async (boardId) => {
      const response = await axios.delete(`/api/admin/boards/${boardId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BoardList");
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**파일 단건 업로드*/
export const usePostFile = () => {
  return useMutation(async (data) => {
    const response = await axios.post(`/api/file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  });
};

/**임상증례 게시판 리스트 조회 */
export const useGetGalleryList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["GalleryList", searchCondition],
    async () => {
      const { data } = await axios.get(`/api/admin/clinical/list`, {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 게시판 상세 조회 */
export const useGetGalleryinfo = (clinicalId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["Galleryinfo", clinicalId],
    async () => {
      const { data } = await axios.get(`/api/admin/clinical/${clinicalId}`);
      return data;
    },
    {
      enabled: !!clinicalId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 단건 삭제 */
export const useDeleteGallery = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (galleryId) => {
      const response = await axios.delete(`/api/admin/clinical/${galleryId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GalleryList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/education/clinical-gallery"));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 수정*/
export const useEditGallery = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.put(`/api/admin/clinical/${data.galleryId}`, data.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Galleryinfo");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {
          navigate("/education/clinical-gallery");
        });
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 관리자 임상증례 등록*/
export const useAddGallery = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (formData) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/admin/clinical`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Galleryinfo");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.add_success), "", t(IK.confirm), () => {
          navigate("/education/clinical-gallery");
        });
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 등록상태(VIP표출) 수정*/
export const useEditGalleryConfirm = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      const response = await axios.put(`/api/admin/clinical/${data.galleryId}/submission?submissionStatus=${data.submissionStatus}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Galleryinfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm));
      },
      onError: (error) => {
        if (error.response.status === 448) {
          return Notiflix.Report.failure(t(IK.update_fail), t(IK.vip_file_fail), t(IK.confirm), () => {});
        } else {
          Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
        }
      },
    }
  );
};

/**임상증례 게시물 복사 */
export const useCopyGallery = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await axios.post(`/api/admin/clinical/copy`, {
        clinicalIds: data,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GalleryList");
        Notiflix.Report.success(t(IK.board_copy_success_1), t(IK.board_copy_success_2), t(IK.confirm));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.board_copy_fail), t(IK.again_message), t(IK.confirm));
      },
    }
  );
};
