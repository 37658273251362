import { css, styled, keyframes } from "styled-components";

export const ModalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -500px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;

export const ModalBgShow = keyframes`
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
`;

export const ModalBox = styled.div`
  display: ${(props) => (props.$openModal ? "flex" : "none")};
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  background-color: rgba(0, 0, 0, 0.7);
  ${(props) =>
    props.$openModal &&
    css`
      animation: ${ModalBgShow} 0.3s;
    `}
  section {
    position: fixed;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 500px;
    max-width: 1280px;
    animation: ${ModalShow} 0.3s;
  }
`;

export const ModalBoxHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.$headernone ? "5px 0px" : "13px 20px")};
  background-color: ${(props) => (props.$headernone ? "transparent" : props.theme.colors.blue400)};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  h3 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  button {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ModalBoxCont = styled.div`
  max-height: calc(100% - 66px);
  overflow: auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => (props.$headernone ? "10px" : "0 0 10px 10px")};
`;

export const ModalBoxList = styled.ul``;

export const ModalBoxItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const ModalBoxBullet = styled.ul`
  li {
    position: relative;
    padding-left: 10px;
    font-size: 15px;
    &:before {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.blue400};
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const ModalBoxBtn = styled.div`
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;
