import { useEffect } from "react";
import LoginForm from "../components/login/LoginForm";
import useTokenCheck from "../hooks/useToken";
import i18n from "../utils/i18n";

const Login = () => {
  const isToken = useTokenCheck();

  /**언어 설정을 변경한 유저의 값 가져오기 (리코일은 로그아웃시 지워버림)*/
  const commonLang = localStorage.getItem("langInfo") || "ko";

  useEffect(() => {
    i18n.changeLanguage(commonLang);
  }, [commonLang]);

  /**로그인 유저 접근 금지 */
  if (isToken) return null;

  return <LoginForm />;
};

export default Login;
