import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/** 센터 리스트 조회*/
export const useGetCenterList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["CenterList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/center/list", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 센터 리스트 상세 조회*/
export const useGetCenterInfo = (adminId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["CenterInfo", adminId],
    async () => {
      const { data } = await axios.get(`/api/admin/center/${adminId}`);
      return data;
    },
    {
      enabled: !!adminId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 센터 계정 정보 수정*/
export const useEditCenterInfo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (submit) => {
      const response = await axios.put(`/api/admin/center/${submit.adminId}`, submit.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CenterInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        if (response.response.status === 445) return Notiflix.Report.failure(t(IK.update_fail), t(IK.center_error_fix), t(IK.confirm), () => {});
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 센터 계정 추가*/
export const useAddCenterInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await axios.post(`/api/admin/center`, data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CenterList");
      },
    }
  );
};

/** 센터 계정 삭제*/
export const useDeleteCenterInfo = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async (adminId) => {
      const response = await axios.delete(`/api/admin/center?adminId=${adminId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CenterList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/centerList"));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.again_message), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};
