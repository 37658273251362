import { Confirm } from "notiflix";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useDeleteGallery, useEditGalleryConfirm } from "../../apis/boardApi";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { IK } from "../../utils/i18n/keyStore";
import { CardTail } from "../layout/CardLayout/CardLayout";

const ClinicalTail = ({ submissionStatus, isAllCountry }) => {
  const { t } = useTranslation(["translation"]);
  const { state } = useLocation();
  const { galleryId } = useParams();

  const deleteMutation = useDeleteGallery();
  const confirmMutation = useEditGalleryConfirm();

  /**임상증례 삭제 */
  const handleClinicDelete = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        deleteMutation.mutate(galleryId);
      },
      () => {}
    );
  };

  /**임상증례 반영 */
  const handleShowConfirm = () => {
    const submission = submissionStatus === "REGISTERED" ? "NOT_REGISTERED" : "REGISTERED";
    confirmMutation.mutate({ galleryId, submissionStatus: submission });
  };

  return (
    <CardTail line>
      <S3.ButtonLtBox>
        <S3.StyledButton type="button" to={`/education/clinical-gallery${state?.herf || "?page=1"}`}>
          {t(IK.list)}
        </S3.StyledButton>
        {galleryId && isAllCountry ? (
          <>
            <S3.StyledButton as="button" type="button" onClick={handleClinicDelete}>
              {t(IK.delete)}
            </S3.StyledButton>
            <S3.StyledButton as="button" type="button" $secondary onClick={handleShowConfirm}>
              {submissionStatus === "REGISTERED" ? t(IK.vip_file_not_show) : t(IK.vip_file_show)}
            </S3.StyledButton>
            <S3.StyledButton as="button" type="submit" $primary>
              {t(IK.modify)}
            </S3.StyledButton>
          </>
        ) : (
          <S3.StyledButton as="button" type="submit" $primary>
            {t(IK.registration)}
          </S3.StyledButton>
        )}
      </S3.ButtonLtBox>
    </CardTail>
  );
};

export default ClinicalTail;
