import React, { useCallback } from "react";
import * as S2 from "../../elements/Form/style/Form.style";
import { codeToCountry } from "../../utils/countryUtils";
import { IK } from "../../utils/i18n/keyStore";

const SelectedCountryList = ({ watch, setValue, t, fieldName = "countryCodes" }) => {
  /**국가 선택 삭제 */
  const handleFileDelete = useCallback(
    (code) => {
      const newCountries = [...watch].filter((c) => c !== code);
      setValue(fieldName, newCountries);
    },
    [setValue, watch, fieldName]
  );

  return (
    <S2.FormFileList>
      {watch?.map((code) => (
        <li key={code}>
          {t(IK[codeToCountry(code)])}
          <button type="button" onClick={() => handleFileDelete(code)}>
            <i className="ri-close-line" />
          </button>
        </li>
      ))}
    </S2.FormFileList>
  );
};

export default SelectedCountryList;
