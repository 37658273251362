import SelectArrow from "../assets/images/common/select.png";

const { createGlobalStyle } = require("styled-components");

const GlobalStyle = createGlobalStyle`

    /**타임존 셀렉트 박스 */
    .select-time-wrapper {
        width: 300px; 
    }


    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html,
    body {
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
        -webkit-text-size-adjust: 100%;
        background-color: ${(props) => props.theme.colors.white};
    }

    body,
    input,
    select,
    textarea,
    button {
        line-height: 1.5;
        letter-spacing: -0.5px;
        font-size: 14px;
        font-family: 'Montserrat', 'Pretendard';
    }

    img {
        border-style: none;
        vertical-align: middle;
    }

    a {
        color: ${(props) => props.theme.colors.black};
        text-decoration: none;
        transition: all 0.3s;
        &:active, &:hover {
            text-decoration: none;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    ol,
    ul,
    li {
        list-style: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    strong,
    b,
    th {
        font-weight: 600;
    }

    input,
    select,
    button:focus,
    button:active,
    textarea {
        outline: none;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background: ${(props) => props.theme.colors.white} url(${SelectArrow}) no-repeat center right 12px;
        ::-ms-expand {
            display: none;
        }
    }

    input::-ms-clear {
        display: none;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input::-moz-placeholder,
    textarea:focus::-moz-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input:-moz-placeholder,
    textarea:focus:-moz-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    textarea {
        overflow: auto;
    }

    button {
        appearance: button;
        -webkit-appearance: button;
        border-style: none;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.3s;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        &-thumb {
            height: 10%;
            background-color: ${(props) => props.theme.colors.gray400};
            border-radius: 5px;
        }
        &-track {
            background-color: ${(props) => props.theme.colors.gray300};
        }
        &-corner {
            background-color: ${(props) => props.theme.colors.gray300};
        }
    }

    .hidden,
    caption,
    legend {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        border: 0;
    }

    @page {
        size:A4; /*A4*/
        margin:5mm
    }

    #wrapper {
        min-width: 1900px;
    }
`;

export default GlobalStyle;
