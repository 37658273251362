import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { pageListNumber } from "../../utils/pageListNumber";
import { formatCurrency } from "../../utils/paymentUtils";
import CouponCheck from "./CouponCheck";

export const CouponTableTr = ({ totalElements, page, coupons, handleCheck }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      {coupons.map((coupon, index) => (
        <tr key={coupon.couponId}>
          <td>{coupon.useYn === "N" && <CouponCheck checked={coupon.checked} handleCheck={() => handleCheck(coupon.couponId)} />}</td>
          <td>{pageListNumber(totalElements, page, index)}</td>
          <td>{coupon.couponName}</td>
          <td>{coupon?.clinicName}</td>
          <td>
            {coupon?.doctorEngName} {coupon?.doctorKoreaName ? `(${coupon?.doctorKoreaName})` : ""}
          </td>
          <td>
            <S2.TextDiv $textColorRed600>
              -{formatCurrency(coupon.amount)}
              {t(IK.krw)}
            </S2.TextDiv>
          </td>
          <td>{dayjs(coupon.createdDate).format("YYYY-MM-DD")}</td>
          <td>{!!coupon.endDate ? `${coupon.startDate} ~ ${coupon.endDate}` : t(IK.no_limit)}</td>
          <td>{coupon.useYn === "Y" ? t(IK.use) : t(IK.not_used)}</td>
        </tr>
      ))}
    </>
  );
};
