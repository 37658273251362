import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/*------------API [처방전 리스트] ------------*/

/** 처방전 리스트 조회*/
export const useGetStudyList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudyList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/study/list", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 리스트 상단 탭 카운트 조회 */
export const useTabCount = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["TabCount", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/study/list/tab-count", {
        params: searchCondition,
      });
      return data;
    },
    {
      cacheTime: 0,
      onError: (error) => {
        Notiflix.Notify.warning(t(IK.server_error2));
      },
    }
  );
};

/**처방전 리스트 엑셀 다운로드 */
export const getLoadExcel = async (searchCondition, t, langInfo) => {
  Notiflix.Loading.standard("");
  try {
    const response = await axios.get(`/api/admin/study/excel`, {
      params: {
        ...searchCondition,
        language: langInfo,
      },
      responseType: "arraybuffer",
    });

    const fileBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = "studyList_serafin.xlsx";
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove(); // 성공 또는 실패 시 로딩 제거
  }
};

/*------------API [처방전] ------------*/

/**처방전 해당 환자의 처방전 요약 조회  */
export const useGetStudySummary = (studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudySummary", studyId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/${studyId}/summary`);
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 좌축 환자 정보 조회 */
export const useGetStudyInfo = (studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudyInfo", studyId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/${studyId}`);
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 해당 환자의 임상환경 조회 */
export const useGetPreference = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["Preference", patientId],
    async () => {
      const { data } = await axios.get(`/api/clinic-item/patient`, {
        params: { patientId },
      });
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 해당 환자의 프리시젼 컷 정보 조회 */
export const useGetPreCut = (patientId) => {
  return useQuery(["PreCut", patientId], async () => {
    const { data } = await axios.get(`/api/clinic-item/patient/elastic`, {
      params: {
        patientId,
      },
    });
    return data;
  });
};

/**처방전 추가교정장치 요약정보 */
export const useGetAdditionalList = (additionalId, id) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["AdditionalSummary", additionalId, id],
    async () => {
      const { data } = await axios.get(`/api/additional/${additionalId}/summary`, {
        params: id,
      });
      return data;
    },
    {
      enabled: !!additionalId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료옵션(세라핀 패키지) 수정*/
export const useEditPackages = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      const response = await axios.put(`/api/admin/study/${data.studyId}/packages?code=${data.code}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        queryClient.invalidateQueries("StudySummary");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.update_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 담당 직원 리스트 조회  */
export const useGetManagerList = (isManagerEdit) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["ManagerList"],
    async () => {
      const { data } = await axios.get(`/api/admin/study/info/manager-list`);
      return data;
    },
    {
      enabled: !!isManagerEdit,
      onError: (error) => {
        Notiflix.Notify.warning(t(IK.server_error2));
      },
    }
  );
};

/** 처방전 담당직원, 자문의 등록*/
export const useManagerRegistration = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/manager`, null, {
        params: payload.data,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 디자인 예정일, 장치 제작 예정일 수정*/
export const useEditExpectedDate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/expected-date`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.update_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료 시작일 수정*/
export const useEditStartDate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/start-date`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.update_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료 만료일 수정*/
export const useEditEndDate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/end-date`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.update_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 추가교정장치 만료일 수정*/
export const useEditAdditionalEndDate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/additional-end-date`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.update_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/** 본 처방전 삭제*/
export const useStudyDelete = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (studyId) => {
      const response = await axios.delete(`/api/admin/study/${studyId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyList");
        navigate("/studyList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.study_delete_fail), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료할 악궁선택 항목 수정*/
export const useEditArch = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/arch`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudySummary");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        if (response.response.data.code === 442) {
          return Notiflix.Report.failure(t(IK.update_fail), t(IK.arch_err_msg), t(IK.confirm), () => {});
        }
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 every10 항목 수정*/
export const useEditEvery10 = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyId}/every10`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudySummary");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 특별지시사항 항목 수정*/
export const useEditSpecialInstructions = (summaryLabel) => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/${payload.type}/${payload.id}/special-instructions`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(summaryLabel);
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 처방전 스와이퍼 조회 */
export const useGetStudySwiperList = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudySwiper", patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/tab`);
      return data;
    },
    {
      enabled: !!studyId && !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 가장 최근 처방전 */
export const useGetRecentStudy = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["RecentStudy", patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/latest`);
      return data;
    },
    {
      enabled: !!patientId && !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [처방전 치료상태 변경] ------------*/

/** 처방전 치료상태 접수 (detail 13 => 14로 변경)*/
export const useStudyReceipt = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/receipt`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.receipt_study), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        //날짜 오류감지용
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.study_status_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료상태 접수 반려 (detail 11로 변경)*/
export const useStudyReject = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/reject`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.reject_study), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료상태 디자인 중으로 변경 (detail 14 => 15로 변경)*/
export const useStudyDesign = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/design`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료상태 디자인 파일 재작업(디자인중으로 이동), 승인한 경우 승인유지 (detail 16이상에서 => 15로 변경)*/
export const useStudyRejectDesign = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/reject-design`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 처방전 치료상태 검토요청으로 변경 (detail 15이상에서 => 16 변경) */
export const useStudyReview = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/review`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        queryClient.invalidateQueries("SetupDesign");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        //디자인 파일 없을시 에러메세지
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.study_status_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 치료상태 디자인 승인 취소(검토요청 상태)로 변경 (detail 17일때는 => 16 변경, 그외에는 detail변경 없이 confirm만 1 => 0 처리) */
export const useStudyDesignCancel = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (studyDesignId) => {
      const response = await axios.put(`/api/admin/study/${studyDesignId}/design-cancel`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupDesign");
        queryClient.invalidateQueries("SetupDesignList");
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 치료상태 승인됨으로 상태 변경 : vip의사 사이트에서 승인 처리시 자동 변경되나 승인된 디자인을 재작업한 경우 관라지에서 단계 변경용으로 승인처리 (detail 16 => 17로 변경) */
export const useStudyDesignConfirm = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/design-confirm`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 치료상태 장치제작중으로 상태 변경 (detail 17 => 18로 변경) */
export const useStudyDeviceMaking = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/device-making`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        //날짜 오류감지용
        const errorMessage = IK[response?.response?.data?.message] || IK.again_message;
        Notiflix.Report.failure(t(IK.study_status_fail), t(errorMessage), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 치료상태 배송중으로 상태 변경 (detail 18 => 19로 변경) */
export const useStudyDeliveryStart = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/delivery-progress`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 배송됨으로 상태 변경 (detail 19 => 20으로 변경) */
export const useStudyDeliveryComplete = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/delivery-complete`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        Notiflix.Report.success(t(IK.study_status_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.study_status_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [set up 디자인 파일] ------------*/

/** 세라뷰 셋업 디자인 파일 리스트 조회*/
export const useGetSetupDesignList = (studyId, additionalId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["SetupDesignList", studyId, additionalId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/${studyId}/setup-design-list`, {
        params: { additionalId },
      });
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 세라뷰 셋업 디자인 파일 등록(새로 등록하는 경우)*/
export const useSetUpDesignFile = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/admin/study/${payload.studyId}/setup-design`, payload.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyInfo");
        queryClient.invalidateQueries("SetupDesignList");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Loading.remove();
        if (response?.response?.data?.message === "error_design_step") {
          return Notiflix.Report.info(t(IK.error_design_step), "", t(IK.confirm));
        }
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 세라뷰 셋업 디자인 파일 상세 조회*/
export const useGetSetupDesignFile = (studyId, studyDesignId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["SetupDesign", studyId, studyDesignId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/${studyId}/setup-design/${studyDesignId}`);
      return data;
    },
    {
      enabled: !!studyId && !!studyDesignId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 세라뷰 셋업 디자인 파일 재등록(수정)*/
export const useReSetUpDesignFile = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      Notiflix.Loading.standard("");
      const response = await axios.put(`/api/admin/study/${payload.studyId}/setup-design/${payload.studyDesignId}`, payload.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupDesign");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Loading.remove();
        if (response?.response?.data?.message === "error_design_step") {
          return Notiflix.Report.info(t(IK.error_design_step), "", t(IK.confirm));
        }
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 셋업 디자인 VIP 표출여부 수정*/
export const useShowDesignFileToVip = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.studyDesignId}/design-show?showYn=${payload.showYn}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupDesign");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 디자인 파일 임의승인 (최고 관리자만) */
export const useDesignFileApproval = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (studyDesignId) => {
      const response = await axios.put(`/api/admin/study/${studyDesignId}/design-confirm`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupDesign");
        queryClient.invalidateQueries("SetupDesignList");
        Notiflix.Report.success(t(IK.approval_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.approval_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [set up 디자인 파일 코멘트] ------------*/

/** 처방전 세라뷰 치료계획 디자인 코멘트 리스트 조회*/
export const useGetSetupCommentList = (studyDesignId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["SetupCommentList", studyDesignId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/comment/${studyDesignId}`);
      return data;
    },
    {
      enabled: !!studyDesignId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 세라뷰 셋업 디자인 파일 삭제*/
export const useDeleteDesignFile = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.delete(`/api/admin/study/${payload.studyId}/setup-design/${payload.studyDesignId}`, {
        params: payload,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupDesignList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        if (response?.response?.data?.message === "error_design_step") {
          return Notiflix.Report.info(t(IK.error_design_step), "", t(IK.confirm));
        }
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 셋업 디자인 파일 vip, advisor 코멘트 삭제*/
export const useDeleteDesignComment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.delete(`/api/admin/study/${payload.isVip ? "comment" : "comment-advisor"}/${payload.commentId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupCommentList");
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 셋업 디자인 파일 vip, advisor 코멘트 수정*/
export const useEditDesignComment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.put(`/api/admin/study/${payload.isVip ? "comment" : "comment-advisor"}/${payload.commentId}`, {
        comment: payload.comment,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupCommentList");
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 셋업 디자인 파일 vip, advisor 코멘트 등록*/
export const useNewDesignComment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.isVip ? "comment" : "comment-advisor"}/${payload.studyDesignId}`, {
        comment: payload.comment,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("SetupCommentList");
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [배송]  ------------*/

/** 배송정보 조회*/
export const useGetDeliveryInfo = (studyId, additionalId, replacementId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["DeliveryInfo", studyId, additionalId, replacementId],
    async () => {
      const { data } = await axios.get(`/api/admin/study/${studyId}/delivery`, {
        params: { additionalId, replacementId },
      });
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 배송정보 등록(수정, 덮어씌우기)*/
export const useSetDeliveryInfo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/admin/study/${payload.studyId}/delivery`, payload.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DeliveryInfo");
        Notiflix.Report.success(t(IK.study_delivery_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [처방전 추가교정장치]  ------------*/
/**비교중첩데이터 사진 보기 */
export const useGetOverlappingData = (additionalId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["Overlapping", additionalId],
    async () => {
      const { data } = await axios.get(`/api/additional/${additionalId}/overlap`);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!additionalId,
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**비교중첩데이터 등록/수정 */
export const useSetOverlappingData = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/additional/${payload.additionalId}/overlap`, payload.formData, {
        params: payload.params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Overlapping");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 추가교정장치 처방전 삭제*/
export const useAdditionalStudyDelete = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (additionalId) => {
      const response = await axios.delete(`/api/admin/additional/${additionalId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudySwiper");
        queryClient.invalidateQueries("StudyInfo");
        queryClient.invalidateQueries("RecentStudy");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.study_delete_fail), t(IK.confirm), () => {});
      },
    }
  );
};

/*------------API [처방전 대체교정장치]  ------------*/
/** 대체 처방전 요약 조회 */
export const useReplacementSummary = (replacementId, patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["ReplacementSummary", replacementId],
    async () => {
      const { data } = await axios.get(`/api/admin/replacement/${replacementId}`, {
        params: {
          patientId,
        },
      });
      return data;
    },
    {
      onError: (response) => {
        Notiflix.Report.failure(t(IK.try_again), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 대체교정장치 처방전 삭제*/
export const useReplacementStudyDelete = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (replacementId) => {
      const response = await axios.delete(`/api/admin/replacement/${replacementId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudySwiper");
        queryClient.invalidateQueries("StudyInfo");
        queryClient.invalidateQueries("RecentStudy");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.study_delete_fail), t(IK.confirm), () => {});
      },
    }
  );
};

/**---------------------------[관리자 링크 조회]-------------------------- */

export const getAminLink = async (payload, t) => {
  const { data } = await axios
    .get(`/api/admin/study/${payload.patientId}/link`, {
      params: {
        ...payload,
      },
    })
    .catch((error) => {
      return Notiflix.Notify.failure(t(IK.again_message));
    });
  return data;
};
