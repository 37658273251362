import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { TitleV3 } from "../../elements/Title/TitleText";
import { ContentBox } from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";

const BillDoneSkeleton = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <ContentBox>
        <TitleV3 title={t(IK.history_billing)} />
        <Skeleton height={40} count={2} />
      </ContentBox>
      <ContentBox>
        <TitleV3 title={t(IK.info_billing)} />
        <Skeleton height={40} count={2} />
      </ContentBox>
      <ContentBox>
        <TitleV3 title={t(IK.price_billing)} />
        <Skeleton height={40} count={2} />
      </ContentBox>
    </>
  );
};

export default BillDoneSkeleton;
