import "@ckeditor/ckeditor5-build-classic/build/translations/en-au";
import "@ckeditor/ckeditor5-build-classic/build/translations/ko";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { IK } from "../utils/i18n/keyStore";

/**텍스트 에디터
 * @param {function} setData - 에디터 작성 내용 저장 함수
 * @param {string} content - 에디터 작성 내용
 * @param {function} setContentLength - 에디터 글자수 저장 함수
 * @param {boolean} isModal - 모달에서 사용시 true
 * @param {boolean} disabled - 에디터 사용 가능 여부
 */

const TextEditor = ({ setData, content, setContentLength, isModal, disabled }) => {
  const { t } = useTranslation(["translation"]);
  const langInfo = localStorage.getItem("langInfo");

  /**이미지 업로드 */
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve) => {
          try {
            const file = await loader.file;
            const response = await axios.request({
              method: "POST",
              url: `/api/file`,
              data: {
                file: file,
              },
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            resolve({
              default: `${response.data}`,
            });
          } catch (error) {
            Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
          }
        });
      },
      abort: () => {},
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      key={langInfo}
      editor={Editor}
      disabled={disabled}
      config={{
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        wordCount: {
          onUpdate: function (stats) {
            setContentLength && setContentLength({ characters: stats.characters, words: stats.words });
          },
        },
        extraPlugins: [uploadPlugin],
        toolbar: {
          items: isModal
            ? ["bold", "underline", "strikethrough", "italic", "|", "highlight", "fontBackgroundColor", "fontColor", "fontSize", "|", "imageUpload"]
            : [
                "heading",
                "|",
                "bold",
                "underline",
                "strikethrough",
                "italic",
                "removeFormat",
                "|",
                "highlight",
                "fontBackgroundColor",
                "fontColor",
                "fontFamily",
                "fontSize",
                "|",
                "alignment",
                "bulletedList",
                "numberedList",
                "indent",
                "outdent",
                "|",
                "imageUpload",
                "mediaEmbed",
                "link",
                "|",
                "horizontalLine",
                "blockQuote",
                "insertTable",
                "|",
                "undo",
                "redo",
                "|",
                "findAndReplace",
                "accessibilityHelp",
                "sourceEditing",
              ],
        },
        language: langInfo,
        mediaEmbed: {
          previewsInData: true,
        },
        image: {
          toolbar: ["imageTextAlternative", "toggleImageCaption", "imageStyle:inline", "imageStyle:block", "imageStyle:side", "linkImage"],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
        },
        removePlugins: ["MediaEmbedToolbar"],
        placeholder: t(IK.board_placeholder),
      }}
      data={content ? content : "<p></p>"}
      onChange={(event, editor) => {
        setData(editor.getData()); // 에디터 작성 내용 저장
      }}
    />
  );
};

export default TextEditor;
