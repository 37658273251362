import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/** 의사 목록 조회  */
export const useGetDoctorList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["DoctorList", searchCondition],
    async () => {
      const { data } = await axios.get(`/api/admin/doctor/list`, { params: searchCondition });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 의사 상세 조회  */
export const useGetDoctorInfo = (doctorId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["DoctorInfo", doctorId],
    async () => {
      const { data } = await axios.get(`/api/admin/doctor/${doctorId}/info`);
      return data;
    },
    {
      enabled: !!doctorId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 의사 주소지 조회  */
export const useGetDoctorAddress = (doctorId) => {
  return useQuery(
    ["DoctorAddress", doctorId],
    async () => {
      const { data } = await axios.get(`/api/admin/doctor/${doctorId}/address/list`);
      return data;
    },
    {
      enabled: !!doctorId,
    }
  );
};

/** 주소 수정 */
export const useEditDoctorAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ value, id }) => {
      await axios.put(`/api/address/${id}`, value);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorAddress");
      },
    }
  );
};

/**의사리스트 엑셀 다운로드 */
export const getDoctorLoadExcel = async (searchCondition, t, langInfo) => {
  Notiflix.Loading.standard("");

  try {
    const response = await axios.get(`/api/admin/doctor/excel`, {
      responseType: "arraybuffer",
      params: {
        ...searchCondition,
        language: langInfo,
      },
    });

    const fileBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = "doctorList_serafin.xlsx";
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove(); // 성공 또는 실패 시 로딩 제거
  }
};

/**환자 정보 수정*/
export const useEditDoctorInfo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (submit) => {
      const response = await axios.put(`/api/admin/doctor/${submit.id}/info`, submit.data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**의사 삭제 */
export const useDeleteDoctor = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (doctorId) => {
      await axios.delete(`/api/admin/doctor/${doctorId}/info`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/doctorList"));
      },
      onError: (err) => {
        if (err.response.status === 443) {
          Notiflix.Report.info(t(IK.delete_fail), t(IK.doctor_delete_fail_message), t(IK.confirm), () => {});
        }
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 의사명, 아이디, 병원 명으로 의사 조회 (쿠폰용) */
export const useGetDoctorSearch = (searchKeyword) => {
  return useQuery(
    ["DoctorSearch", searchKeyword],
    async () => {
      const { data } = await axios.get(`/api/admin/doctor/search`, {
        params: {
          searchKeyword,
        },
      });
      return data;
    },
    {
      enabled: !!searchKeyword,
    }
  );
};

/**의사아이디로 관련 환자 조회 */

/*************************************************코인************************************************* */

/** 의사 코인 조회
 * @param {number} doctorId - 의사 ID
 */
export const useGetDoctorCoin = (doctorId) => {
  return useQuery(
    ["DoctorCoin", doctorId],
    async () => {
      const { data } = await axios.get(`/api/coins/admin/doctor`, {
        params: {
          doctorId,
        },
      });
      return data;
    },
    {
      enabled: !!doctorId,
    }
  );
};

/** 의사 코인 이력 조회
 * @param {boolean} isOpen - 모달 오픈 여부
 * @param {number} doctorId - 의사 ID
 * @param {object} searchQuery - 검색 쿼리
 */
export const useGetDoctorCoinHistory = (isOpen, doctorId, searchQuery) => {
  return useQuery(
    ["DoctorCoinHistory", doctorId, searchQuery],
    async () => {
      const { data } = await axios.get(`/api/coins/admin/doctor/history`, {
        params: {
          doctorId,
          ...searchQuery,
        },
      });
      return data;
    },
    {
      enabled: !!doctorId && isOpen,
    }
  );
};

/** 의사 코인 충전 / 차감 */
export const useEditDoctorCoin = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      await axios.put(`/api/coins/admin/doctor?doctorId=${payload.doctorId}`, payload.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorCoin");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: (response) => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};
