import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as S from "../../components/board/style/BoardList.style";
import { doctorGrade } from "../../utils/doctorUtils";
import { IK } from "../../utils/i18n/keyStore";
import { doctorTypeLabel } from "../../utils/searchLabel";

export const DoctorTableTr = ({ doctors }) => {
  const { t } = useTranslation(["translation"]);

  /**의사 승인상태 라벨 */
  const doctorStatusLabel = {
    UNAPPROVED: t(IK.status_pending), //승인대기
    REJECT: t(IK.status_reject), //반려
    APPROVED: t(IK.status_apporve), //승인
  };

  const doctorClassificationLabel = {
    ORTHODONTIST: t(IK.doctor_orthodontists), //교정과전문의
    PEDIATRIC: t(IK.doctor_pediatrics), //소아치과전문의
    UNIVERSITY: t(IK.doctor_university), //대학종합병원
    ETC: t(IK.other), //기타
    INTERNATIONAL: t(IK.doctor_International), //해외
  };

  return (
    <>
      {doctors.map((doctor) => (
        <tr key={doctor.doctorId}>
          <td>{doctor.doctorNumber}</td>
          <td>
            <S.BoardTableLink to={`/doctorList/${doctor.doctorId}`} state={{ herf: window.location.search }}>
              {doctor.doctorName} <br /> {doctor?.doctorKoreaName && `(${doctor.doctorKoreaName})`}
            </S.BoardTableLink>
          </td>
          <td>{doctor.doctorLoginId}</td>
          <td>{doctor.doctorPhoneNumber}</td>
          <td>{doctor.doctorLicense}</td>
          <td>{!!doctor?.doctorType ? t(doctorTypeLabel[doctor.doctorType]) : ""}</td>
          <td>{doctorClassificationLabel[doctor?.doctorClassification]}</td>
          <td>{`${doctor.shipAddressName} (${doctor.shipPostalCode}) ${doctor.shipAddress} ${doctor.shipAddressDetail ? doctor.shipAddressDetail : ""} `}</td>
          <td>{dayjs(doctor.createdDate).format("YYYY-MM-DD")}</td>
          <td>{doctor.caseProgress}</td>
          <td>{doctor?.doctorStatus === "APPROVED" ? (doctor?.doctorGrade === "DENTIS" ? t(IK.doctor_dentis) : doctorGrade[doctor?.doctorGrade]) : doctorStatusLabel[doctor?.doctorStatus]}</td>
          <td>{doctor.videoYn === "Y" ? "O" : "X"}</td>
          <td>{doctor.marketingYn === "N" ? "X" : "O"}</td>
        </tr>
      ))}
    </>
  );
};
