import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../../elements/DatePicker";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/SelectionControls/style/SelectionControls.style";
import * as S from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";

const StudyFilter = ({ searchCondition, setSearchCondition, setActiveTab }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, setValue, watch, reset } = useForm();
  const searchs = watch();

  useEffect(() => {
    if (searchCondition?.flag) {
      const filters = searchCondition?.flag.split(";");
      filters.forEach((filter) => {
        setValue(filter, true);
      });
    }
  }, [searchCondition?.flag, setValue]);

  /**기간 설정 */
  const handleDateChange = (event) => {
    const gap = event.target.value;
    if (gap) {
      const startDateFrom = new Date(dayjs().subtract(gap, "month").format("YYYY-MM-DD"));
      const startDateTo = new Date();
      setValue("startDateFrom", startDateFrom.toISOString().split("T")[0]);
      setValue("startDateTo", startDateTo.toISOString().split("T")[0]);
    } else {
      setValue("startDateFrom", "");
      setValue("startDateTo", "");
      setSearchCondition((prev) => ({ ...prev, startDateFrom: "", startDateTo: "" }));
    }
  };

  /**치료 시작 날짜 설정 */
  const handlestartDateFrom = (date) => {
    if (date) {
      setValue("startDateFrom", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("startDateFrom", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handlestartDateTo = (date) => {
    if (date) {
      setValue("startDateTo", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("startDateTo", "");
    }
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    const { additional, mine, replacement, study, additionalAmount, reOrder, ...rest } = data;

    // data.startDateFrom와 data.startDateTo 중 하나는 값이 있고 다른 하나는 없는 경우
    if ((data.startDateFrom && !data.startDateTo) || (!data.startDateFrom && data.startDateTo)) {
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }

    const flag = ["additional", "mine", "replacement", "study", "additionalAmount", "reOrder"].filter((flag) => data[flag]).join(";");

    setSearchCondition((prev) => {
      return { ...rest, detail: prev.detail || "total", flag, page: 1 };
    });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      clinicName: "",
      doctorName: "",
      patientNumber: "",
      patientName: "",
      packages: "",
      startDateFrom: "",
      startDateTo: "",
      additional: false,
      mine: false,
      replacement: false,
      study: false,
      additionalAmount: false,
      reOrder: false,
    });

    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;

    setActiveTab("total");
    setSearchCondition({ page: 1 });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            {/* 치과명 */}
            <th scope="row">{t(IK.search_clinic)}</th>
            <td>
              <S3.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.clinicName} {...register("clinicName")} />
            </td>
            {/* 환자명 */}
            <th scope="row">{t(IK.search_patient)}</th>
            <td>
              <S3.FormControls placeholder={t(IK.search_patient_input)} defaultValue={searchCondition?.patientName} {...register("patientName")} />
            </td>
          </tr>
          <tr>
            {/* 의사명 */}
            <th scope="row">{t(IK.search_doctor)}</th>
            <td>
              <S3.FormControls placeholder={t(IK.search_doctor_input)} defaultValue={searchCondition?.doctorName} {...register("doctorName")} />
            </td>
            {/* 환자 번호 */}
            <th scope="row">{t(IK.patient_number)}</th>
            <td>
              <S3.FormControls placeholder={t(IK.search_patient_number)} defaultValue={searchCondition?.patientNumber} {...register("patientNumber")} />
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.create_date)}</th>
            <td>
              <S3.FormControlsFlex>
                <S3.FormControls onChange={handleDateChange} as="select" $select id="dateControl">
                  <option value="">{t(IK.entire_date)}</option>
                  <option value={1}>{t(IK.month1)}</option>
                  <option value={3}>{t(IK.month3)}</option>
                  <option value={6}>{t(IK.month6)}</option>
                  <option value={12}>{t(IK.year1)}</option>
                </S3.FormControls>
                <DatePicker
                  handleDataChange={handlestartDateFrom}
                  placeholderText="ex) 2023-01-01"
                  selected={(searchs.startDateFrom && new Date(searchs.startDateFrom)) || (searchCondition.startDateFrom && new Date(searchCondition.startDateFrom))}
                  formControls
                />
                <DatePicker
                  handleDataChange={handlestartDateTo}
                  placeholderText="ex) 2023-01-01"
                  selected={(searchs.startDateTo && new Date(searchs.startDateTo)) || (searchCondition.startDateTo && new Date(searchCondition.startDateTo))}
                  formControls
                />
              </S3.FormControlsFlex>
            </td>
            <th scope="row">{t(IK.treatment_option)}</th>
            <td>
              <S3.FormControls {...register("packages")} defaultValue={searchCondition?.packages} as="select" $select>
                <option value="">{t(IK.select_treatment_options)}</option>
                <option value="R">R-active</option>
                <option value="RP">R-passive</option>
                <option value="S10">10</option>
                <option value="S20">20</option>
                <option value="SR">Regular</option>
                <option value="SAP">Regular AP</option>
                <option value="Si">i</option>
                <option value="SiAP">iAP</option>
              </S3.FormControls>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.filter)}</th>
            <td colSpan="3">
              <S2.SelectionItemList>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("mine")} checked={!!searchs.mine} />
                    <S2.SelectionItemSpan>{t(IK.charge_treatment_plan)}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("study")} checked={!!searchs.study} />
                    <S2.SelectionItemSpan>{t(IK.main_study)}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("additional")} checked={!!searchs.additional} />
                    <S2.SelectionItemSpan>{t(IK.additional_device)}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("replacement")} checked={!!searchs.replacement} />
                    <S2.SelectionItemSpan>{t(IK.alternative_correction_devices)}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("additionalAmount")} checked={!!searchs.additionalAmount} />
                    <S2.SelectionItemSpan>{`${t(IK.pay_need)} ${t(IK.additional_device)}`}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
                <S2.SelectionItem $Small>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" {...register("reOrder")} checked={!!searchs.reOrder} />
                    <S2.SelectionItemSpan>{t(IK.reorder)}</S2.SelectionItemSpan>
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
              </S2.SelectionItemList>
            </td>
          </tr>
        </tbody>
      </S.TableType2>
      <S4.ButtonCtBox>
        <S4.StyledButton type="button" onClick={() => onReset()} as="button">
          <i className="ri-refresh-line"></i> {t(IK.reset)}
        </S4.StyledButton>
        <S4.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line"></i> {t(IK.search)}
        </S4.StyledButton>
      </S4.ButtonCtBox>
    </form>
  );
};

export default StudyFilter;
