import { IK } from "./i18n/keyStore";

/**센터 권한 리스트 */
export const centerAdminLabel = {
  SUPER_ADMIN: IK.admin_super,
  FACTORY_ADMIN: IK.admin_factory,
  SALES_ADMIN: IK.admin_sales,
  ADVICE_ADMIN: IK.admin_advice,
  EDUCATION_ADMIN: IK.admin_education,
  BUSINESS_ADMIN: IK.admin_business,
  DENTIS_ADMIN: IK.admin_dentis,
};

/**닥터 타입 */
export const doctorTypeLabel = {
  TNS: IK.doctor_tns,
  DENTIS: IK.doctor_dentis,
};

/**국가별 나라코드 */
export const countryLabel = [
  { value: "", label: IK.select_country },
  { value: "00", label: IK.all_country },
  { value: "09", label: IK.australia },
  { value: "16", label: IK.brazil },
  { value: "03", label: IK.china },
  { value: "18", label: IK.canada },
  { value: "22", label: IK.cambodia },
  { value: "15", label: IK.egypt },
  { value: "12", label: IK.france },
  { value: "14", label: IK.germany },
  { value: "20", label: IK.india },
  { value: "13", label: IK.italy },
  { value: "02", label: IK.japan },
  { value: "01", label: IK.korea },
  { value: "10", label: IK.newzealand },
  { value: "21", label: IK.myanmar },
  { value: "17", label: IK.mexico },
  { value: "05", label: IK.mongolia },
  { value: "19", label: IK.russia },
  { value: "06", label: IK.taiwan },
  { value: "07", label: IK.thailand },
  { value: "04", label: IK.usa },
  { value: "11", label: IK.uk },
  { value: "08", label: IK.vietnam },
];

/**국가별 나라코드
 * 전체국가가 있음, 센터에서 사용
 */
export const countryObject = {
  "00": IK.all_country,
  "01": IK.korea,
  "02": IK.japan,
  "03": IK.china,
  "04": IK.usa,
  "05": IK.mongolia,
  "06": IK.taiwan,
  "07": IK.thailand,
  "08": IK.vietnam,
  "09": IK.australia,
  10: IK.newzealand,
  11: IK.uk,
  12: IK.france,
  13: IK.italy,
  14: IK.germany,
  15: IK.egypt,
  16: IK.brazil,
  17: IK.mexico,
  18: IK.canada,
  19: IK.russia,
  20: IK.india,
  21: IK.myanmar,
  22: IK.cambodia,
};
