import { useCallback, useEffect, useMemo } from "react";

/**
 * 총합 금액 계산
 * @param {Array} billsInfo - 청구 정보 배열
 * @param {boolean} isLoading - 로딩 상태
 * @param {Function} setTotals - 총액을 설정하는 함수
 */
const useCalculateTotal = (billsInfo, isLoading, setTotals) => {
  const calculateTotal = useCallback(() => {
    return billsInfo.reduce(
      (result, { discountPrice = 0, paymentPrice = 0, salePrice = 0, isModify }) => {
        result.discountPrice += discountPrice;
        result.paymentPrice += paymentPrice;
        result.salePrice += salePrice;

        if (isModify) {
          result.remainPrice += paymentPrice;
        }

        return result;
      },
      { discountPrice: 0, paymentPrice: 0, salePrice: 0, remainPrice: 0 }
    );
  }, [billsInfo]);

  useEffect(() => {
    if (!isLoading) {
      setTotals(calculateTotal());
    }
  }, [isLoading, calculateTotal, setTotals]);

  return useMemo(() => calculateTotal(), [calculateTotal]);
};

export default useCalculateTotal;
