import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import * as S from "../../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../elements/Tab/style/Tab.style";
import * as S4 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import StudyViewAdditionalSummary from "../studyContent/StudyViewAdditionalSummary";
import StudyViewDelivery from "../studyContent/StudyViewDelivery";
import StudyViewOverlap from "../studyContent/StudyViewOverlap";
import StudyViewSetup from "../studyContent/StudyViewSetup";
import * as S3 from "../style/Study.style";

const AdditionalDevice = ({ studyId, detail, studyInfo, additionalId, isNowStudy, studyAuth, selectedInfo }) => {
  const { t } = useTranslation(["translation"]);

  const componentRef = useRef();
  /**프린트 실행*/
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setBtn("summary_setup");
  }, [additionalId]);

  /**컴포넌트 클릭 상태값 관리 */
  const [btn, setBtn] = useState("summary_setup");

  const components = {
    summary_setup: (
      <>
        {(isNowStudy ? selectedInfo?.additionalProcess !== "none" && detail >= "15" : selectedInfo?.additionalProcess !== "none") && (
          <S4.ContentBox>
            <StudyViewSetup studyId={studyId} detail={detail} studyInfo={studyInfo} additionalId={additionalId} isNowStudy={isNowStudy} studyAuth={studyAuth} />
          </S4.ContentBox>
        )}
        <S4.ContentBox>
          <S3.PrintWrapper ref={componentRef}>
            <StudyViewAdditionalSummary studyId={studyId} additionalId={additionalId} patientId={studyInfo.patientId} handlePrint={handlePrint} studyAuth={studyAuth} />
          </S3.PrintWrapper>
        </S4.ContentBox>
      </>
    ),
    overlapping_data: <StudyViewOverlap additionalId={additionalId} patientId={studyInfo.patientId} studyId={studyId} studyAuth={studyAuth} />,
  };

  if (isNowStudy ? detail >= "15" : true) {
    components.delivery = <StudyViewDelivery studyId={studyId} studyInfo={studyInfo} studyAuth={studyAuth} additionalId={additionalId} />;
  }

  /**추가교정장치 수정 none인 경우 이름 처리 */
  const tabName = (btn) => {
    if (btn === "summary_setup" && (isNowStudy ? studyInfo?.additionalProcess === "none" || detail < "15" : studyInfo?.additionalProcess === "none")) {
      return "prescription_summary";
    }
    return btn;
  };

  return (
    <S.CardInner>
      <S2.TabType2>
        {Object.keys(components).map((key) => (
          <S2.TabType2Item key={key} onClick={() => setBtn(key)} $active={btn === key}>
            {t(IK[tabName(key)])}
          </S2.TabType2Item>
        ))}
      </S2.TabType2>

      <S3.PrintWrapper ref={componentRef}>{components[btn]}</S3.PrintWrapper>
    </S.CardInner>
  );
};

export default AdditionalDevice;
