import { css, styled } from "styled-components";

export const TeethContainer = styled.div`
  display:flex;
  align-items:center;
  gap:15px;
`;

export const TeethDirection = styled.span`
`;

export const TeethTable = styled.table`
  width:auto;
  display:block;

  tr:nth-child(2) {
    border-top:1px solid ${(props) => props.theme.colors.black};
  }

  tr:first-child td:first-child {
    border-right:1px solid ${(props) => props.theme.colors.black};
    text-align:right;
  }

  tr:first-child td:last-child {
    text-align:left;
  }

  tr:last-child td:first-child {
    border-right:1px solid ${(props) => props.theme.colors.black};
    text-align:right;
  }

  tr:last-child td:last-child {
    text-align:left;
  }
  
  td {
    padding:5px;
  }

  @media print {
    td {
      padding:4px;
    }
  }
`;

export const TeethItem = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin:5px;

  @media print {
    margin:4px;
  }
`;

export const TeethItemLabel = styled.label`
  position:relative;
  display: block;
  width: 35px;
  height: 35px;

  @media print {
    width: 22px;
    height: 22px;
  }
`;

export const TeethItemInput = styled.input`
  position:relative;
  width: 35px;
  height: 35px;
  appearance: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray400};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.$TeethCheckbox &&
    css`
      border-radius: 3px;
      &:checked {
        background-color:${(props) => props.theme.colors.blue400};
        border-color:${(props) => props.theme.colors.blue400};
        + span {
          color:${(props) => props.theme.colors.white}
        }
      }
    `
  }

  ${(props) =>
    props.$TeethRadio &&
    css`
      border-radius: 50%;
      &:checked {
        border: 4px solid ${(props) => props.theme.colors.blue400};
        + span {
          color:${(props) => props.theme.colors.blue400}
        }
      }

      @media print {
        &:checked {
          border: 2px solid ${(props) => props.theme.colors.blue400};
        } 
      }
    `
  }
  &:disabled {
    background-color:${(props) => props.theme.colors.gray400};
    + span {
      cursor:auto;
    }
  }

  @media print {
    width: 22px;
    height: 22px;
  }
`;

export const TeethItemSpan = styled.span`
  position:absolute;
  top:0;
  left:0;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size:14px;

  @media print {
    width: 22px;
    height: 22px;
    font-size:12px;
  }
`;