import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**
 * 프로그램 파일 조회: 파일명에 따른 프로그램 파일을 가져오는데 사용됩니다.
 *
 * @param {string} type - 프로그램 파일의 타입 (seraview, sera-quick)
 * @returns {Object} - 해당 파일 정보 반화
 */
export const useGetProgramFile = (type) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["GetProgramFile", type],
    async () => {
      const { data } = await axios.get(`/api/boards/support/${type}`);
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**프로그램 파일 등록
 * @param {string} type - 프로그램 파일의 타입 (seraview, sera-quick)
 */

export const usePostProgramFile = (type) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      await axios.post(`/api/admin/boards/support/${type}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`GetProgramFile`);
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**배송키 조회 */
export const useGetDeliveryKey = () => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["GetDeliveryKey"],
    async () => {
      const { data } = await axios.get("/api/admin/boards/support/delivery");
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**배송키 전송
 * @param {string} key - 배송키
 */
export const usePostDeliveryKey = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (key) => {
      await axios.post(`/api/admin/boards/support/delivery?key=${key}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetDeliveryKey");
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**교육영상 리스트 조회 */
export const useGetEducationVideo = () => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["EducationVideoList"],
    async () => {
      const { data } = await axios.get("/api/admin/support-links");
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**교육영상 리스트 단건 수정 */
export const usePutEducationVideo = (supportLinkId) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      await axios.put(`/api/admin/support-links/${supportLinkId}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EducationVideoList");
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**교육영상 리스트 단건 추가 */
export const usePostEducationVideo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      await axios.post(`/api/admin/support-links`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EducationVideoList");
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm));
      },
      onError: (error) => {
        if (error.response.status === 446) {
          Notiflix.Report.failure(t(IK.file_fail), t(IK.country_exist), t(IK.confirm));
        } else {
          Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm));
        }
      },
    }
  );
};

/**교육영상 리스트 단건 삭제 */
export const useDeleteEducationVideo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (supportLinkId) => {
      await axios.delete(`/api/admin/support-links/${supportLinkId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EducationVideoList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm));
      },
    }
  );
};
