import { styled } from "styled-components";

export const TitleV3 = styled.div`
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.blue400};
  }
  h2 {
    display: inline-block;
    font-size: 18px;
  }
  span {
    display: inline-block;
    margin-left: 5px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const TitleV4 = styled.div`
  margin-bottom: 10px;
  h2 {
    font-size: 15px;
  }
`;
