import * as S from "../../elements/SelectionControls/style/SelectionControls.style";

const CouponCheck = ({ handleCheck, checked }) => {
  return (
    <S.SelectionItem $labelhidden>
      <S.SelectionItemLabel>
        <S.SelectionItemInput $checkbox type="checkbox" checked={checked} onChange={handleCheck} />
        <S.SelectionItemSpan></S.SelectionItemSpan>
      </S.SelectionItemLabel>
    </S.SelectionItem>
  );
};

export default CouponCheck;
