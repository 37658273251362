import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorCard } from "../components/error/ErrorCard";
import * as S from "../components/error/style/Error.style";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import { IK } from "../utils/i18n/keyStore";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  return (
    <ErrorCard title={"404 ERROR"} subTitle={t(IK.error_subtitle)} contnetTxt={t(IK.error_content_txt2)}>
      <S.ErrorBtn>
        <S2.StyledButton $primary as="button" onClick={() => navigate("/")} className="btns btn_type1">
          {t(IK.home)}
        </S2.StyledButton>
      </S.ErrorBtn>
    </ErrorCard>
  );
};

export default NotFound;
