import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetBillList, useGetBillTotal } from "../apis/billApi";
import BillFilter from "../components/billing/BillFilter";
import BillTableTr from "../components/billing/BillTableTr";
import BoardTotal from "../components/board/BoardTotal";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { formatCurrency } from "../utils/paymentUtils";
import { billChangeParam, urlParamToObject } from "../utils/searchParams";
import * as S2 from "../components/board/style/BoardList.style";

const BillList = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });

  /**검색 조건 url에 추가 */
  useSearch(searchCondition);
  const { data: bills, isLoading } = useGetBillList(billChangeParam(searchCondition));
  const { data: totalBills, isLoading: isTotalLoading } = useGetBillTotal(billChangeParam(searchCondition));

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  const tableCol = ["90px", "100px", "*", "140px", "*", "160px", "160px", "160px", "160px", "180px"];
  const tableHead = [
    t(IK.number),
    t(IK.pay_month),
    t(IK.doctor_name),
    t(IK.doctor_type),
    t(IK.search_clinic),
    t(IK.pay_order),
    t(IK.pay_discount),
    t(IK.pay_charged),
    t(IK.pay_remaining),
    t(IK.pay_status),
  ];

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <BillFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} />
        </S.CardInner>
      </S.CardCol>
      {isLoading || isTotalLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={bills?.totalElements} totalBills={totalBills} />
              <S2.BoardTotal>
                <span>
                  {t(IK.pay_remaining)} : <strong>{formatCurrency(totalBills)}</strong> {t(IK.krw)}
                </span>
              </S2.BoardTotal>
            </CardHead>
            <DataTable tableCol={tableCol} tableHead={tableHead} dataLength={bills?.content.length} tableTr={<BillTableTr bills={bills?.content} />} />
            <CardTail>
              <Paging totalCount={bills?.totalElements} currentPage={searchCondition.page} size={bills?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default BillList;
