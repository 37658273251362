import Skeleton from "react-loading-skeleton";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S5 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Drop/style/Drop.style";
import * as S4 from "../../elements/Form/style/Form.style";
import { TitleV3 } from "../../elements/Title/TitleText";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { CardTail } from "../layout/CardLayout/CardLayout";

export const PatientInfoSkeleton = () => {
  return (
    <S.CardProfileWrapper>
      <S.CardProfileBox>
        <S.CardProfileHead>
          <S.CardProfileHeadInfo>
            <S.CardProfileHeadInfoImg>
              <div>
                <Skeleton circle={true} height={80} width={80} />
              </div>
            </S.CardProfileHeadInfoImg>
            <S.CardProfileHeadInfoTxt>
              <h2>
                <Skeleton width={280} height={40} />
              </h2>
              <p>
                <Skeleton width={100} height={20} />
              </p>
            </S.CardProfileHeadInfoTxt>
          </S.CardProfileHeadInfo>
          <S.CardProfileHeadBtnBottom>
            <Skeleton width={100} height={35} />
          </S.CardProfileHeadBtnBottom>
        </S.CardProfileHead>
        <S.CardProfileBodyInfo>
          <SkeletonDl />
          <SkeletonDl />
          <SkeletonDl />
          <SkeletonDl />
          <SkeletonDl />
          <SkeletonDl />
          <SkeletonDl />
        </S.CardProfileBodyInfo>
      </S.CardProfileBox>
    </S.CardProfileWrapper>
  );
};

export const SkeletonDl = () => {
  return (
    <dl>
      <dt>
        <Skeleton width={100} height={20} />
      </dt>
      <dd>
        <span>
          <Skeleton width={220} height={30} />
        </span>
      </dd>
    </dl>
  );
};

export const PatientFileSkeleton = ({ t }) => {
  return (
    <S.CardInner>
      <SkeletonImage title={t(IK.extraoral)} />
      <SkeletonImage title={t(IK.intraoral)} />
    </S.CardInner>
  );
};

const SkeletonImage = ({ title }) => {
  return (
    <S2.ContentBox>
      <TitleV3 title={title} />
      <S3.DropListWrap>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
        <S3.DropList>
          <S3.DropItem>
            <Skeleton height={190} />
          </S3.DropItem>
        </S3.DropList>
      </S3.DropListWrap>
    </S2.ContentBox>
  );
};

export const PatientModalSkeleton = () => {
  return (
    <>
      <S4.FormControlsContainer>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={80} />
          <Skeleton height={40} width={160} />
          <Skeleton height={40} width={160} />
        </S4.FormControlsFlex>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={80} />
          <Skeleton height={40} width={180} />
        </S4.FormControlsFlex>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={80} />
          <Skeleton height={40} width={320} />
        </S4.FormControlsFlex>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={90} />
          <Skeleton height={40} width={300} />
        </S4.FormControlsFlex>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={120} />
          <Skeleton height={140} width={330} />
        </S4.FormControlsFlex>
        <S4.FormControlsFlex>
          <Skeleton height={40} width={120} />
          <Skeleton height={140} width={330} />
        </S4.FormControlsFlex>
      </S4.FormControlsContainer>
      <CardTail line>
        <S5.ButtonLtBox>
          <S5.StyledButton as="button" type="button"></S5.StyledButton>
          <S5.StyledButton as="button" type="submit" $primary></S5.StyledButton>
        </S5.ButtonLtBox>
      </CardTail>
    </>
  );
};
