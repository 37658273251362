import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/** 팝업관리 글 작성*/
export const useAddPopup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/admin/v1/popups`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PopupList");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.add_success), "", t(IK.confirm), () => navigate("/support/popup"));
      },
      onError: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**팝업 상세 조회 */
export const useGetPopup = (popupId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["Popup", popupId],
    async () => {
      const { data } = await axios.get(`/api/admin/v1/popups/${popupId}`);
      return data;
    },
    {
      enabled: !!popupId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 팝업 리스트 조회 */
export const useGetPopupList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PopupList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/v1/popups", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 팝업 수정 */
export const useUpdatePopup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.put(`/api/admin/v1/popups`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PopupList");
        queryClient.invalidateQueries("Popup");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => navigate("/support/popup"));
      },
      onError: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 팝업 삭제 */
export const useDeletePopup = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  return useMutation(
    async (popupId) => {
      Notiflix.Loading.standard("");
      const response = await axios.delete(`/api/admin/v1/popups/${popupId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PopupList");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/support/popup"));
      },
      onError: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};
