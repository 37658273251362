import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BoardPostBox from "../components/board/BoardPostBox";
import { IK } from "../utils/i18n/keyStore";

const ResourcesPost = () => {
  const { t } = useTranslation(["translation"]);
  const { boardId } = useParams();

  return (
    <div>
      <BoardPostBox boardTitle={t(IK.resources)} boardSortCode="MARKETING" boardId={boardId} navi="/education/resources" attachFile />
    </div>
  );
};

export default ResourcesPost;
