import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S4 from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../../elements/DatePicker";
import * as S2 from "../../elements/Form/style/Form.style";
import * as S from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";

const CouponSearchFilter = ({ setSearchCondition, searchCondition }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, setValue, watch, reset } = useForm();

  const searchs = watch();

  /**기간 설정 */
  const handleDateChange = (event) => {
    const gap = event.target.value;
    if (gap) {
      const startDate = new Date(dayjs().subtract(gap, "month").format("YYYY-MM-DD"));
      const endDate = new Date();
      setValue("startDate", startDate.toISOString().split("T")[0]);
      setValue("endDate", endDate.toISOString().split("T")[0]);
    } else {
      setValue("startDate", "");
      setValue("endDate", "");
      setSearchCondition((prev) => ({ ...prev, startDate: "", endDate: "" }));
    }
  };

  /**가입 시작 날짜 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("startDate", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("startDate", "");
    }
  };

  /**가입 종료 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("endDate", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("endDate", "");
    }
  };

  /**검색 요청 */
  const onSubmitSearch = (data) => {
    if ((data.startDate || data.endDate) && !(data.startDate && data.endDate)) {
      // data.startDate와 data.endDate 중 하나는 값이 있고 다른 하나는 없는 경우
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }
    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      couponName: "",
      clinicName: "",
      startDate: "",
      endDate: "",
    });
    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;
    setSearchCondition({ page: 1 });
  };

  return (
    <S4.CardInner>
      <form onSubmit={handleSubmit(onSubmitSearch)}>
        <S.TableType2>
          <colgroup>
            <col width="200px"></col>
            <col width="*"></col>
            <col width="200px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{t(IK.coupon_name)}</th>
              <td>
                <S2.FormControls placeholder={t(IK.coupon_name)} defaultValue={searchCondition?.couponName} {...register("couponName")} />
              </td>
              <th scope="row">{t(IK.search_clinic)}</th>
              <td>
                <S2.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.clinicName} {...register("clinicName")} />
              </td>
            </tr>
            <tr>
              <th scope="row">{t(IK.coupon_date_limit)}</th>
              <td colSpan={3}>
                <S2.FormControlsFlex>
                  <S2.FormControls onChange={handleDateChange} as="select" $select id="dateControl">
                    <option value="">{t(IK.entire_date)}</option>
                    <option value={1}>{t(IK.month1)}</option>
                    <option value={3}>{t(IK.month3)}</option>
                    <option value={6}>{t(IK.month6)}</option>
                    <option value={12}>{t(IK.year1)}</option>
                  </S2.FormControls>
                  <DatePicker
                    handleDataChange={handleStartDate}
                    selected={(searchs.startDate && new Date(searchs.startDate)) || (searchCondition.startDate && new Date(searchCondition.startDate))}
                    formControls
                  />
                  <DatePicker
                    handleDataChange={handleEndDate}
                    selected={(searchs.endDate && new Date(searchs.endDate)) || (searchCondition.endDate && new Date(searchCondition.endDate))}
                    formControls
                  />
                </S2.FormControlsFlex>
              </td>
            </tr>
          </tbody>
        </S.TableType2>
        <S3.ButtonCtBox>
          <S3.StyledButton as="button" type="button" onClick={() => onReset()}>
            <i className="ri-refresh-line" /> {t(IK.reset)}
          </S3.StyledButton>
          <S3.StyledButton as="button" $large $primary type="submit">
            <i className="ri-search-2-line" /> {t(IK.search)}
          </S3.StyledButton>
        </S3.ButtonCtBox>
      </form>
    </S4.CardInner>
  );
};

export default CouponSearchFilter;
