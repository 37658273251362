import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/CardLayout.style";

export const CardTitle = ({ title, txt, children }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <S.CardTitle>
      <h2>{title}</h2>
      {txt && (
        <p>
          <span>*</span>
          {txt}
          {t(IK.marking_required_message)}
        </p>
      )}
      {children}
    </S.CardTitle>
  );
};

export const CardHead = ({ children, line }) => {
  return <S.CardHead $line={line}>{children}</S.CardHead>;
};

export const CardTail = ({ children, line }) => {
  return <S.CardTail $line={line}>{children}</S.CardTail>;
};
