import { css, styled } from "styled-components";

// 전체 컨테이너
export const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 89px);
  margin: 20px 20px 0 280px;

  .ck-editor {
    .ck-content {
      min-height: 500px;
      &.ck-column-resize_disabled {
        background-color: ${(props) => props.theme.colors.gray300};
      }
    }
    ol,
    ul {
      padding-inline-start: 40px;
      li {
        list-style: inherit;
      }
    }
  }
`;

// 뱃지
export const CountBadge = styled.span`
  display: inline-block;
  min-width: 25px;
  min-height: 19px;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 7px;
  background-color: ${(props) => (props.$red ? props.theme.colors.red600 : props.theme.colors.blue400)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.18) 0px 1px 2px;
  text-align: center;
  font-size: 11px;
  color: ${(props) => props.theme.colors.white};
`;

export const ContentBox = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
  ${(props) =>
    props.$printhide &&
    css`
      display: block;
      @media print {
        display: none;
      }
    `};
`;

export const ContentLine = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 7px solid ${(props) => props.theme.colors.gray200};
  }
`;

export const ContentScroll = styled.div`
  overflow: auto;
  max-height: ${(props) => (props.$small ? "68px" : "100px")};
`;

export const ListGroup = styled.dl`
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  overflow: hidden;
  dt {
    padding: 13px 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.gray300};
    font-size: 15px;
    font-weight: 600;
  }
  dd {
    padding: 20px;
  }
`;

export const TextDiv = styled.span`
  ${(props) =>
    props.$textColorBlue400 &&
    css`
      color: ${(props) => props.theme.colors.blue400};
    `};

  ${(props) =>
    props.$textColorRed600 &&
    css`
      color: ${(props) => props.theme.colors.red600};
    `};

  ${(props) =>
    props.$textSize24 &&
    css`
      font-size: 24px;
    `};

  ${(props) =>
    props.$textBold800 &&
    css`
      font-weight: 800;
    `};

  ${(props) =>
    props.$textpreline &&
    css`
      white-space: pre-line;
      word-break: break-all;
    `};

  ${(props) =>
    props.$blink &&
    css`
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.red600};
        animation: blink-effect 1s step-end infinite;
      }

      @keyframes blink-effect {
        50% {
          opacity: 0;
        }
      }
    `};
`;

// ImageViewer
export const ImageViewerBox = styled.div`
  .styles-module_wrapper__1I_qj {
    z-index: 3;
  }
`;
