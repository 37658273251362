import { useTranslation } from "react-i18next";
import { useGetEducationVideo } from "../apis/supportApi";
import { IK } from "../utils/i18n/keyStore";

//스타일 관련
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRecoilValue } from "recoil";
import { userAuth } from "../atom/userAtom";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import VideoAddModal from "../components/supportVideo/VideoAddModal";
import VideoForm from "../components/supportVideo/VideoForm";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import * as S2 from "../elements/Table/style/Table.style";

const EducationVideo = () => {
  /**다국어 변환 함수 */
  const { t } = useTranslation(["translation"]);

  /**유저정보 조회 */
  const { countryCode } = useRecoilValue(userAuth);
  const isAllCountry = countryCode === "00";

  /**해당 파일 데이터 조회 */
  const { data, isLoading, isError } = useGetEducationVideo();

  /**모달 관리 */
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    videoData: null,
  });

  /**모달 열기 */
  const handleOpenModal = () => setOpenModal({ isOpen: true, videoData: null });
  /**모달 닫기 */
  const handleCloseModal = () => setOpenModal({ isOpen: false, videoData: null });

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          {isLoading || isError ? (
            <>
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </>
          ) : (
            <div>
              <S2.TableType2>
                <colgroup>
                  <col width="200px"></col>
                  <col width="*"></col>
                </colgroup>
                <tbody>
                  {data?.map((video, index) => (
                    <VideoForm key={video?.supportLinkId} isDefault={index === 0} videoData={video} isAllCountry={isAllCountry} setOpenModal={setOpenModal} />
                  ))}
                </tbody>
              </S2.TableType2>
              {isAllCountry && (
                <S4.ButtonCtBox>
                  <S4.StyledButton $primary as="button" onClick={handleOpenModal}>
                    {t(IK.add)}
                  </S4.StyledButton>
                </S4.ButtonCtBox>
              )}
            </div>
          )}
          <VideoAddModal openModal={openModal} handleClose={handleCloseModal} isAllCountry={isAllCountry} />
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default EducationVideo;
