import { useEffect } from "react";

/**검색쿼리 기억하기 */
export const useSearch = (searchCondition) => {
  useEffect(() => {
    const queryStr = new URLSearchParams(searchCondition).toString();
    const location = window.location;
    const searchUrl = `${location.origin + location.pathname}?${queryStr}`;
    window.history.pushState("", null, searchUrl);
  }, [searchCondition]);
};
