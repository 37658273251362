import { NavLink, useLocation } from "react-router-dom";
import * as S from "./style/SideBar.style";

const SideNavi = ({ navi }) => {
  const { pathname } = useLocation();

  return (
    <li>
      <NavLink to={navi.nav}>
        <S.SideBarMenuIco>
          <i className={navi.icon} />
        </S.SideBarMenuIco>
        <S.SideBarMenuTxt>{navi.title}</S.SideBarMenuTxt>
        {navi.depth && navi.depth.length > 0 && (
          <S.SideBarMenuArrow>
            <i className="ri-arrow-drop-right-line" />
          </S.SideBarMenuArrow>
        )}
      </NavLink>
      {navi?.depth?.length > 0 && (
        <S.SideBarNavDepth2>
          {navi?.depth?.map((sub, i) => (
            <li key={sub.title}>
              <NavLink className={({ isActive }) => (isActive || (i === 0 && pathname === navi.nav) ? "active" : "")} to={sub.nav}>
                <S.SideBarMenuIco>
                  <i className="ri-arrow-drop-right-line" />
                </S.SideBarMenuIco>
                <S.SideBarMenuTxt>{sub.title}</S.SideBarMenuTxt>
              </NavLink>
            </li>
          ))}
        </S.SideBarNavDepth2>
      )}
    </li>
  );
};

export default SideNavi;
