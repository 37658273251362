import Skeleton from "react-loading-skeleton";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../elements/Form/style/Form.style";
import { FormSkeleton } from "../clinicalGallery/CliniCalGalleryInfoSkeleton";
import { CardTail } from "../layout/CardLayout/CardLayout";

const CenterSkeleton = () => {
  return (
    <S.CardInner>
      <S.CardTitle>
        <h2>
          <Skeleton width={500} height={40} />
        </h2>
      </S.CardTitle>
      <S2.FormControlsContainer>
        <FormSkeleton width={100} />
        <FormSkeleton width={400} />
        <FormSkeleton width={400} />
        <FormSkeleton width={400} />
        <FormSkeleton width={200} />
        <FormSkeleton width={200} />
      </S2.FormControlsContainer>
      <CardTail line>
        <S3.ButtonLtBox>
          <Skeleton width={109} height={45} />
          <Skeleton width={109} height={45} />
          <Skeleton width={109} height={45} />
        </S3.ButtonLtBox>
      </CardTail>
    </S.CardInner>
  );
};

export default CenterSkeleton;
