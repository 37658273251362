import { useTranslation } from "react-i18next";
import * as S from "../../components/board/style/BoardList.style";
import { centerAdminLabel, countryObject } from "../../utils/searchLabel";

export const CenterTableTr = ({ centers }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      {centers.map((center) => (
        <tr key={center.adminId}>
          <td>{center.loginId}</td>
          <td>
            <S.BoardTableLink to={`/centerList/${center.adminId}`} state={{ herf: window.location.search }}>
              {center.name}
            </S.BoardTableLink>
          </td>
          <td>{t(centerAdminLabel[center.roleName])}</td>
          <td>{t(countryObject[center.countryCode])}</td>
        </tr>
      ))}
    </>
  );
};
