import React, { useCallback, useMemo, useState } from "react";
import * as S2 from "../../styles/Common";
import * as S from "./style/Language.style";

const LANG_LIST = {
  ko: "한국어",
  en: "English",
  vi: "Tiếng Việt",
};

const LANG_CODE = {
  ko: "KR",
  en: "US",
  vi: "VN",
};

export const LanguageButton = React.memo(({ langInfo, setLangInfo }) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const toggleLanguageModal = useCallback(() => {
    setIsLanguageModalOpen((prev) => !prev);
  }, []);

  const onChangeLang = useCallback(
    (lang) => {
      setLangInfo(lang);
      localStorage.setItem("langInfo", lang);
      setIsLanguageModalOpen(false);
    },
    [setLangInfo]
  );

  const currentLangCode = LANG_CODE[langInfo] || "";
  const currentLangName = LANG_LIST[langInfo] || "";

  return (
    <S.LanguageWrap>
      <S.LanguageBtn type="button" onClick={toggleLanguageModal} aria-haspopup="true" aria-expanded={isLanguageModalOpen}>
        <S2.CountBadge>{currentLangCode}</S2.CountBadge>
        {currentLangName}
      </S.LanguageBtn>
      {isLanguageModalOpen && <LanguagePopup onChangeLang={onChangeLang} />}
    </S.LanguageWrap>
  );
});

export const LanguagePopup = React.memo(({ onChangeLang }) => {
  const languageButtons = useMemo(
    () =>
      Object.entries(LANG_LIST).map(([key, value]) => (
        <S.LanguageBtn type="button" key={key} onClick={() => onChangeLang(key)} aria-label={value}>
          <S2.CountBadge>{LANG_CODE[key]}</S2.CountBadge>
          {value}
        </S.LanguageBtn>
      )),
    [onChangeLang]
  );

  return <S.LanguagePopup role="menu">{languageButtons}</S.LanguagePopup>;
});
