import styled from "styled-components";

export const AlarmPop = styled.div`
  position: fixed;
  bottom: 42px;
  left: 160px;
  z-index: 6;
  width: 550px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

export const AlarmPopTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  background-color: ${(props) => props.theme.colors.blue400};
  border-radius: 5px 5px 0 0;
  color: ${(props) => props.theme.colors.white};
  h3 {
    line-height: 1;
    font-size: 18px;
  }
  button {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 27px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const AlarmPopSection = styled.div`
  padding: 15px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const AlarmPopBottom = styled.div`
  padding: 15px;
  text-align: right;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 0 0 5px 5px;
  button:not(:last-child) {
    position: relative;
    padding-right: 12px;
    margin-right: 12px;
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: -2px;
      width: 1px;
      height: 11px;
      background-color: ${(props) => props.theme.colors.gray400};
    }
  }
  i {
    margin-right: 5px;
  }
`;

export const AlarmPopTab = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 15px 0;
`;

export const AlarmPopTabBtn = styled.button`
  padding: 8px 14px;
  border-radius: 30px;
  border: 1px solid ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.gray200)};
  background-color: ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.gray300)};
  color: ${(props) => (props.$active ? props.theme.colors.white : props.theme.colors.black)};
`;

export const AlarmPopBox = styled.div`
  max-height: 380px;
  overflow: auto;
  dl {
    &:not(:first-child) {
      margin-top: 20px;
    }
    dt {
      margin-bottom: 7px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.blue400};
    }
  }
`;

export const AlarmScrollDetection = styled.div`
  height: 1px;
`;

export const AlarmPopAllSelect = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const AlarmPopList = styled.ul`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const AlarmPopListItem = styled.li`
  display: flex;
  gap: 15px;
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 0;
`;

export const AlarmPopListItemIco = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? props.theme.colors.gray400 : props.theme.colors.blue400)};
  color: ${(props) => props.theme.colors.white};
`;

export const AlarmPopListItemBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const AlarmPopListItemTxt = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  color: ${(props) => (props.$active ? props.theme.colors.gray500 : props.theme.colors.black)};
  p {
    span {
      color: ${(props) => (props.$active ? props.theme.colors.gray500 : props.theme.colors.blue400)};
    }
  }
`;

export const AlarmPopListItemControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AlarmPopListItemClose = styled.button`
  width: 19px;
  height: 19px;
  line-height: 19px;
  font-size: 16px;
`;
