import { useForm } from "react-hook-form";
import { IK } from "../../utils/i18n/keyStore";

//스타일 관련
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

const BoardFilter = ({ searchCondition, setSearchCondition, t, isAllCountry, boardType }) => {
  /**검색 forrm 설정 */
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      country_list: searchCondition?.country_list?.split(";") || [],
    },
  });

  const searchs = watch();

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition((prev) => ({ ...prev, ...data, page: 1, country_list: data?.country_list?.join(";") || "" }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      boardSelect: "title",
      boardText: "",
      country_list: [],
    });
    setSearchCondition({ page: 1, boardSort: boardType });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S2.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          {isAllCountry && (
            <tr>
              <th scope="row">{t(IK.select_country)}</th>
              <td>
                <CountryBtn setValue={setValue} fieldName="country_list" watch={searchs?.country_list} />
              </td>
              <th scope="row">{`${t(IK.country)} ${t(IK.list)}`}</th>
              <td>{searchs?.country_list?.length > 0 && <SelectedCountryList watch={searchs.country_list} fieldName="country_list" setValue={setValue} t={t} />}</td>
            </tr>
          )}
          <tr>
            <th scope="row">
              {t(IK.board_title)}/{t(IK.board_content)}
            </th>
            <td colSpan={3}>
              <S3.FormControlsFlex $rowCenter>
                <S3.FormControls $select as="select" defaultValue={searchCondition?.boardSelect} {...register("boardSelect")}>
                  <option value="title">{t(IK.board_title)}</option>
                  <option value="content">{t(IK.board_content)}</option>
                </S3.FormControls>
                <S3.FormControls type="text" placeholder={t(IK.search_text)} defaultValue={searchCondition?.boardText} {...register("boardText")} />
              </S3.FormControlsFlex>
            </td>
          </tr>
        </tbody>
      </S2.TableType2>
      <S4.ButtonCtBox>
        <S4.StyledButton type="button" onClick={() => onReset()} as="button">
          <i className="ri-refresh-line" /> {t(IK.reset)}
        </S4.StyledButton>
        <S4.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line" /> {t(IK.search)}
        </S4.StyledButton>
      </S4.ButtonCtBox>
    </form>
  );
};

export default BoardFilter;
