import * as S from "../components/board/style/BoardList.style";
import { EmptyCard } from "./EmptyComponent/EmptyCard";

const DataTable = ({ tableCol, tableTr, dataLength, tableHead, BoardTableType2 }) => {
  return (
    <S.BoardTable $BoardTableType2={BoardTableType2}>
      <colgroup>
        {tableCol?.map((col, i) => (
          <col key={i} width={col} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {tableHead.map((head, i) => (
            <th key={i}>{head}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataLength > 0 ? (
          tableTr
        ) : (
          <tr>
            <td colSpan="13">
              <EmptyCard />
            </td>
          </tr>
        )}
      </tbody>
    </S.BoardTable>
  );
};

export default DataTable;
