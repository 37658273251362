import { css, styled } from "styled-components";
import { StyledSmallButton } from "../../../elements/Button/style/ButtonLayout.style";
import { ContentBox } from "../../../styles/Common";
import { CardTail } from "../../layout/CardLayout/style/CardLayout.style";

/**환자리스트 탭 */
export const StudyTab = styled.ul`
  display: flex;
  margin-bottom: 20px;

  li {
    flex: 1;
    min-height: 64px;
  }
`;

export const StudyTabButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: 100%;
  padding: 15px;
  border: 1px solid ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.gray200)};
  background-color: ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.gray300)};
  color: ${(props) => (props.$active ? props.theme.colors.white : props.theme.colors.gray600)};
`;

export const StudyTabTxt = styled.span`
  font-size: 15px;
  font-weight: 600;
`;

export const StudyTabCount = styled.h2`
  font-size: 22px;
  font-weight: 900;
`;

/**처방전 내역 */
export const StudyHistoryList = styled.ul`
  li {
    position: relative;
    display: flex;
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 28px;
      left: 11px;
      width: 2px;
      height: calc(100% - 33px);
      background-color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

export const StudyHistoryCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-right: 15px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  &:before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.blue400};
  }
`;

export const StudyHistoryItem = styled.div`
  felx: 1;
`;

export const StudyHistoryDepth1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-size: 15px;
  }
  span {
    display: block;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

/**환자정보 열기/닫기 */
export const StudyViewToggleBtn = styled.button`
  position: fixed;
  top: 164px;
  left: 251px;
  z-index: 1;
  line-height: 1;
  padding: 20px 8px;
  writing-mode: vertical-rl;
  border-radius: 7px 0 0 7px;
  background-color: ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.white};
  i {
    margin-top: 5px;
  }
`;

/**처방전 상세 탭 */
export const StudyViewTab = styled.div`
  margin-bottom: 15px;

  .swiper {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 10px;
    .swiper-slide {
      width: auto;
      padding: 17px 25px;
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
      background-color: ${(props) => props.theme.colors.white};
      cursor: pointer;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 1px solid ${(props) => props.theme.colors.gray200};
      }
      &.active {
        background-color: ${(props) => props.theme.colors.blue400};
        color: ${(props) => props.theme.colors.white};
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 16px;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

/**처방전 요약 */
export const StudySummary = styled.ul`
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const StudySummaryItem = styled.li`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-top: 0;
  ${(props) =>
    props.$printshow &&
    css`
      display: none;
      @media print {
        display: block;
      }
    `};
  dl {
    position: relative;
    display: flex;

    &:before {
      content: "";
      position: absolute;
      width: 250px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray300};
    }

    &:after {
      content: "";
      position: absolute;
      left: 250px;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray200};
    }

    dt {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5px;
      width: 250px;
      padding: 15px;
      h2 {
        flex: 1;
        font-size: 15px;
        word-break: break-all;
      }
      button {
        display: flex;
        gap: 3px;
        font-size: 15px;
      }
    }

    dd {
      flex: 1;
      padding: 15px;
      font-size: 15px;
    }
  }
`;

/**처방전 요약 인쇄 */
export const PrintWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  @media print {
    * {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }

    ${StudySummaryItem} {
      page-break-inside: avoid;
      page-break-after: auto;
      dl {
        &:before {
          width: 160px;
        }
        &:after {
          left: 160px;
        }
        dt {
          width: 160px;
        }
      }
    }

    ${ContentBox} {
      margin-top: 0;
      ${StudySummary} {
        border-top: 0;
      }
    }

    ${CardTail},
    .printhide ${StyledSmallButton} {
      display: none;
    }
  }
`;

/**처방전 요약 디자인 탭 */
export const StudyDesignTab = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  overflow: hidden;
`;

export const StudyDesignTabBtn = styled.button`
  padding: 12px 20px;
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => (props.$primary ? props.theme.colors.blue400 : props.$secondary ? props.theme.colors.blue200 : props.theme.colors.gray300)};
  color: ${(props) => (props.$primary ? props.theme.colors.white : props.$secondary ? props.theme.colors.blue400 : props.theme.colors.gray600)};
  font-weight: 600;
  &:last-child {
    border-right: 0;
  }
`;

/**처방전 요약 디자인 코멘트 */
export const StudyComment = styled.div`
  padding: 15px;
  white-space: pre-line;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 5px;
`;
