import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import { studyTypeLabel } from "../../../utils/studyStatus";
import * as S from "../style/Study.style";

const ReceiptStudy = ({ studyInfo }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <S.StudyHistoryCircle />
      <S.StudyHistoryItem>
        <S.StudyHistoryDepth1>
          <h3>
            [{t(IK[studyTypeLabel(studyInfo)])}] {t(IK.receipt_study)}
            {studyInfo?.expectedDate && `(${t(IK.seraview_expected)}: ${dayjs(studyInfo?.expectedDate).format("YYYY-MM-DD")} )`}
          </h3>
          <span>
            {studyInfo?.createdDate} {studyInfo?.createdBy && studyInfo.writerShow && `(${studyInfo.createdBy})`}
          </span>
          {studyInfo?.modifiedDate && studyInfo?.createdDate !== studyInfo?.modifiedDate && (
            <span>
              {t(IK.modify)} : {studyInfo?.modifiedDate} {studyInfo?.modifiedBy && studyInfo.writerShow && `(${studyInfo.modifiedBy})`}
            </span>
          )}
        </S.StudyHistoryDepth1>
      </S.StudyHistoryItem>
    </>
  );
};

export default ReceiptStudy;
