import { yupResolver } from "@hookform/resolvers/yup";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useInsertAgreementFile } from "../../apis/patientApi";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S from "../../elements/Form/style/Form.style";
import { ErrorMessage } from "../../elements/Message/MessageText";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";
import * as S2 from "../Modal/style/Modal.style";

export const PatientAgreementModal = ({ agreementModalOpen, setAgreementModalOpen, patientId }) => {
  const { t } = useTranslation(["translation"]);

  const schema = Yup.object().shape({
    patientAgreement: Yup.mixed().nullable().required(t(IK.patient_consent_form_required)),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const agreeFileMutation = useInsertAgreementFile();

  const closeAgreementModal = () => setAgreementModalOpen(false);

  /**의무정보 제공 동의서 제출 */
  const postAgreement = (value) => {
    const data = new FormData();
    data.append("agreementFile", value.patientAgreement);
    agreeFileMutation.mutate(
      { patientId, data },
      {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.add_success), "", t(IK.confirm), () => {
            closeAgreementModal();
            setValue("patientAgreement", null);
          });
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
        },
      }
    );
  };

  return (
    <ModalLayout open={agreementModalOpen} handleClose={closeAgreementModal} header={t(IK.patient_consent_form)}>
      <form onSubmit={handleSubmit(postAgreement)}>
        <S.FormControlsFlex>
          <S.FormControls $full type="file" id="patientAgreement" name="patientAgreement" onChange={(event) => setValue("patientAgreement", event.target.files[0])} />
          <S.FormControlsBtn as="label" htmlFor="patientAgreement" type="button">
            {t(IK.file_btn)}
          </S.FormControlsBtn>
        </S.FormControlsFlex>
        <ErrorMessage errors={errors} name="patientAgreement" />
        <S2.ModalBoxBtn>
          <S3.ButtonFlex>
            <S3.StyledButton as="button" type="button" onClick={closeAgreementModal}>
              {t(IK.cancel)}
            </S3.StyledButton>
            <S3.StyledButton as="button" type="submit" $primary>
              {t(IK.confirm)}
            </S3.StyledButton>
          </S3.ButtonFlex>
        </S2.ModalBoxBtn>
      </form>
    </ModalLayout>
  );
};
