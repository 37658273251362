import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as S4 from "../../components/Modal/style/Modal.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../elements/SelectionControls/style/SelectionControls.style";
import * as S from "../../elements/Table/style/Table.style";
import { continentsData } from "../../utils/countryUtils";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";

const CountryCheckBox = ({ countryModal, setCountryModal, onClose, onConfirm }) => {
  const { t, i18n } = useTranslation(["translation"]);
  //글로벌 버전에 따른 스타일 조정
  const isGlobal = i18n.language === "en";

  //대륙별 국가 목록 중 가장 많은 국가 수(아시아 10개)
  const maxRows = 10;
  //   const maxRows = useMemo(() => Math.max(...continentsData.map((c) => c.countries.length)), []);

  /**국가 선택(단건) */
  const handleCountryChange = useCallback(
    (country, isChecked) => {
      setCountryModal((prev) => ({
        ...prev,
        selectedCountries: isChecked ? [...prev.selectedCountries, country] : prev.selectedCountries.filter((c) => c !== country),
      }));
    },
    [setCountryModal]
  );

  /**국가 전체 선택 */
  const handleSelectAll = useCallback(
    (isChecked) => {
      setCountryModal((prev) => ({
        ...prev,
        selectedCountries: isChecked ? continentsData.flatMap((continent) => continent.countries) : [],
      }));
    },
    [setCountryModal]
  );

  return (
    <ModalLayout open={countryModal.isOpen} header={`${t(IK.country)} ${t(IK.list)}`} handleClose={onClose}>
      <S.TableType1>
        <colgroup>
          {continentsData.map((_, index) => (
            <col key={index} width={isGlobal ? "160px" : "110px"} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {continentsData.map((continent) => (
              <th key={continent.name}>{t(IK[continent.name])}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: maxRows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {continentsData.map((continent) => (
                <td key={`${continent.name}-${rowIndex}`}>
                  <CountryCheckboxItem country={continent.countries[rowIndex]} onChange={handleCountryChange} isChecked={countryModal.selectedCountries.includes(continent.countries[rowIndex])} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </S.TableType1>

      {/* 전체 선택 버튼 */}
      <S.TableBottom>
        <S2.SelectionItem>
          <S2.SelectionItemLabel>
            <S2.SelectionItemInput
              type="checkbox"
              $checkbox
              onChange={(e) => handleSelectAll(e.target.checked)}
              name="all_country"
              checked={countryModal.selectedCountries.length === continentsData.flatMap((c) => c.countries).length}
            />
            <S2.SelectionItemSpan>{t(IK.select_all)}</S2.SelectionItemSpan>
          </S2.SelectionItemLabel>
        </S2.SelectionItem>
      </S.TableBottom>

      <S4.ModalBoxBtn>
        <S3.ButtonFlex>
          <S3.StyledButton as="button" type="button" onClick={onClose}>
            {t(IK.cancel)}
          </S3.StyledButton>
          <S3.StyledButton as="button" type="button" $primary onClick={onConfirm}>
            {t(IK.confirm)}
          </S3.StyledButton>
        </S3.ButtonFlex>
      </S4.ModalBoxBtn>
    </ModalLayout>
  );
};

export default CountryCheckBox;

const CountryCheckboxItem = React.memo(({ country, onChange, isChecked }) => {
  const { t } = useTranslation(["translation"]);

  //빈칸이면 null 반환
  if (!country) return null;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <S2.SelectionItem>
        <S2.SelectionItemLabel>
          <S2.SelectionItemInput type="checkbox" $checkbox name={country} checked={isChecked} onChange={(e) => onChange(country, e.target.checked)} />
          <S2.SelectionItemSpan>{t(IK[country])}</S2.SelectionItemSpan>
        </S2.SelectionItemLabel>
      </S2.SelectionItem>
    </div>
  );
});
