import styled from "styled-components";

export const ErrorMessage = styled.div`
  display: flex;
  margin-top: 7px;
  color: ${(props) => props.theme.colors.red600};
  i {
    margin-right: 5px;
  }
`;

export const SuccessMessage = styled.div`
  display: flex;
  margin-top: 7px;
  color: #21b497;
  i {
    margin-right: 5px;
  }
`;

export const NotiMessage = styled.div`
  display: flex;
  margin-top: ${(props) => (props.$mt0 ? "0px" : "7px")};
  color: ${(props) => props.theme.colors.gray600};
  i {
    margin-right: 5px;
  }
`;
