/**해당하는 치아 번호를 반환 */
export const getPrecutTeeth = (data, classType, elastic, type) => {
  const teethobj = data?.[classType]?.[elastic] || {};
  //치아 정보가 없을 경우 빈 문자열 반환
  if (!teethobj) return "";

  //해당 치아 번호 배열 반환
  const teethArr = [];
  Object.entries(teethobj).forEach(([key, value]) => {
    if (value === type) {
      const label = key.split("_")[1];
      teethArr.push(label);
    }
  });

  return teethArr.sort((a, b) => a - b).join(", ");
};
