import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useEditPatientInfo, useGetEditPatientInfo } from "../../apis/patientApi";
import * as S4 from "../../components/Modal/style/Modal.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S from "../../elements/Form/style/Form.style";
import { ErrorMessage } from "../../elements/Message/MessageText";
import * as S2 from "../../elements/SelectionControls/style/SelectionControls.style";
import { enterKeyDown } from "../../utils/enterKeydown";
import { formatDate } from "../../utils/formatDate";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";
import { AddressPopupBtn } from "../doctor/AddressEditModal";
import { PatientModalSkeleton } from "./PatientSkeleton";

const PatientModal = ({ openModal, setOpenModal }) => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);

  const patientInfoMutation = useEditPatientInfo();

  /**환자 정보 가져오기 */
  const { data, isLoading } = useGetEditPatientInfo(patientId, openModal);
  const patientEditData = data?.data;

  const formSchema = Yup.object({
    koreaName: patientEditData?.koreaName
      ? Yup.string()
          .required(t(IK.korea_name_required))
          .max(20, t(IK.name_length_limit))
          .matches(/^[가-힣0-9]+$/, t(IK.only_korean_number))
      : Yup.string(),
    lastName: Yup.string()
      .required(t(IK.english_name_required))
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh)),
    firstName: Yup.string()
      .required(t(IK.english_name_required))
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh)),
    gender: Yup.string().required(t(IK.gender_required)),
    birthday: Yup.string()
      .required(t(IK.birthday_required))
      .matches(/^\d{4}-\d{2}-\d{2}$/, t(IK.date_format_valid)),
    race: Yup.string().required(t(IK.race_required)),
    shipAddressName: Yup.string().required(t(IK.clinic_name_required)),
    shipPostalCode: Yup.string().required(t(IK.postcode_required)),
    shipAddress: Yup.string().required(t(IK.address_required)),
    billAddressName: Yup.string().required(t(IK.clinic_name_required)),
    billPostalCode: Yup.string().required(t(IK.postcode_required)),
    billAddress: Yup.string().required(t(IK.address_required)),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(formSchema) });

  /**모달 닫기 */
  const handleClose = () => {
    setOpenModal(false);
    reset({
      firstName: patientEditData?.firstName,
      lastName: patientEditData?.lastName,
      birthday: patientEditData?.birthday,
      race: patientEditData?.race,
      gender: patientEditData?.gender,
      shipAddressName: patientEditData?.patientAddress?.shipAddressName,
      shipPostalCode: patientEditData?.patientAddress?.shipPostalCode,
      shipAddress: patientEditData?.patientAddress?.shipAddress,
      shipAddressDetail: patientEditData?.patientAddress?.shipAddressDetail,
      billAddressName: patientEditData?.patientAddress?.billAddressName,
      billPostalCode: patientEditData?.patientAddress?.billPostalCode,
      billAddress: patientEditData?.patientAddress?.billAddress,
      billAddressDetail: patientEditData?.patientAddress?.billAddressDetail,
    });
  };

  /**생년월일 입력 */
  const onBlurDate = (e) => {
    const formatDated = formatDate(e.target.value);
    if (formatDated) {
      setValue("birthday", formatDated);
    } else {
      setValue("birthday", "");
      setError("birthday", { type: "manual", message: t(IK.date_format_valid) });
    }
  };

  /**환자정보 수정하기 */
  const onEditInfo = (data) => {
    const { birthday, ...rest } = data;
    patientInfoMutation.mutate(
      { id: patientId, data: { ...rest, birthday: dayjs(birthday).format("YYYY-MM-DD") } },
      {
        onSuccess: () => {
          setOpenModal(false);
        },
      }
    );
  };

  return (
    <ModalLayout open={openModal} handleClose={handleClose} header={t(IK.modification_patient_information)}>
      {isLoading ? (
        <PatientModalSkeleton />
      ) : (
        <>
          <form onSubmit={handleSubmit(onEditInfo)} onKeyDown={enterKeyDown}>
            <S.FormControlsContainer>
              {patientEditData?.koreaName && (
                <FormControlsBox required regular title={`${t(IK.korea_name)}`}>
                  <div className="frm_div">
                    <S.FormControls $regular placeholder={t(IK.name)} {...register("koreaName")} defaultValue={patientEditData?.koreaName} />
                    <ErrorMessage errors={errors} name="koreaName" />
                  </div>
                </FormControlsBox>
              )}
              <FormControlsBox required regular title={`${t(IK.english_name)}`}>
                <S.FormControlsFlex>
                  <div>
                    <S.FormControls $regular placeholder={t(IK.first_name)} {...register("firstName")} defaultValue={patientEditData?.firstName} />
                    <ErrorMessage errors={errors} name="firstName" />
                  </div>
                  <div>
                    <S.FormControls $regular placeholder={t(IK.last_name)} {...register("lastName")} defaultValue={patientEditData?.lastName} />
                    <ErrorMessage errors={errors} name="lastName" />
                  </div>
                </S.FormControlsFlex>
              </FormControlsBox>

              <FormControlsBox required regular title={t(IK.gender)}>
                <S2.SelectionItemList>
                  <S2.SelectionItem $Small>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" id="gender_male" {...register("gender")} value="male" defaultChecked={patientEditData?.gender === "male"} />
                      <S2.SelectionItemSpan htmlFor="gender_male">{t(IK.male)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <S2.SelectionItem $Small>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" id="gender_female" {...register("gender")} value="female" defaultChecked={patientEditData?.gender === "female"} />
                      <S2.SelectionItemSpan htmlFor="gender_female">{t(IK.female)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <ErrorMessage errors={errors} name="gender_female" />
                </S2.SelectionItemList>
              </FormControlsBox>

              <FormControlsBox required regular title={t(IK.race)}>
                <S2.SelectionItemList>
                  <S2.SelectionItem $Small>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" id="race_caucasian" value="Caucasian" {...register("race")} defaultChecked={patientEditData?.race === "Caucasian"} />
                      <S2.SelectionItemSpan htmlFor="race_caucasian">{t(IK.caucasian)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <S2.SelectionItem $Small>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" id="race_asian" value="Asian" {...register("race")} defaultChecked={patientEditData?.race === "Asian"} />
                      <S2.SelectionItemSpan htmlFor="race_asian">{t(IK.asian)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <S2.SelectionItem $Small>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" id="race_african" value="African" {...register("race")} defaultChecked={patientEditData?.race === "African"} />
                      <S2.SelectionItemSpan htmlFor="race_african">{t(IK.african)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <ErrorMessage errors={errors} name="race" />
                </S2.SelectionItemList>
              </FormControlsBox>
              <FormControlsBox required regular title={t(IK.birthday)}>
                <S.FormControlsFlex>
                  <S.FormControls
                    $regular
                    placeholder="ex)20230425"
                    {...register("birthday", {
                      onBlur: onBlurDate,
                    })}
                    defaultValue={patientEditData?.birthday}
                  />
                </S.FormControlsFlex>
                <ErrorMessage errors={errors} name="birthday" />
              </FormControlsBox>

              <FormControlsBox regular required title={t(IK.shipping_address)}>
                <S.FormControlsColumn>
                  <S.FormControls placeholder={t(IK.clinic_name)} {...register("shipAddressName")} defaultValue={patientEditData?.patientAddress?.shipAddressName} />
                  <ErrorMessage errors={errors} name="shipAddressName" />
                  <S.FormControlsFlex>
                    <S.FormControls $regular placeholder={t(IK.postcode)} {...register("shipPostalCode")} defaultValue={patientEditData?.patientAddress?.shipPostalCode} />
                    <AddressPopupBtn countryCode={patientEditData?.koreaName ? "01" : ""} setValue={setValue} basicAddressId={"shipAddress"} zoneCodeId={"shipPostalCode"} />
                  </S.FormControlsFlex>
                  <ErrorMessage errors={errors} name="shipPostalCode" />
                  <S.FormControls placeholder={t(IK.address)} {...register("shipAddress")} defaultValue={patientEditData?.patientAddress?.shipAddress} />
                  <ErrorMessage errors={errors} name="shipAddress" />
                  <S.FormControls placeholder={t(IK.detailed_address)} {...register("shipAddressDetail")} defaultValue={patientEditData?.patientAddress?.shipAddressDetail} />
                </S.FormControlsColumn>
              </FormControlsBox>

              <FormControlsBox regular required title={t(IK.billing_address)}>
                <S.FormControlsColumn>
                  <S.FormControls placeholder={t(IK.clinic_name)} {...register("billAddressName")} defaultValue={patientEditData?.patientAddress?.billAddressName} />
                  <ErrorMessage errors={errors} name="billAddressName" />
                  <S.FormControlsFlex>
                    <S.FormControls $regular placeholder={t(IK.postcode)} {...register("billPostalCode")} defaultValue={patientEditData?.patientAddress?.billPostalCode} />
                    <AddressPopupBtn countryCode={patientEditData?.koreaName ? "01" : ""} setValue={setValue} basicAddressId={"billAddress"} zoneCodeId={"billPostalCode"} />
                  </S.FormControlsFlex>
                  <ErrorMessage errors={errors} name="billPostalCode" />
                  <S.FormControls placeholder={t(IK.address)} {...register("billAddress")} defaultValue={patientEditData?.patientAddress?.billAddress} />
                  <ErrorMessage errors={errors} name="billAddress" />
                  <S.FormControls placeholder={t(IK.detailed_address)} {...register("billAddressDetail")} defaultValue={patientEditData?.patientAddress?.billAddressDetail} />
                  <ErrorMessage errors={errors} name="billAddressDetail" />
                </S.FormControlsColumn>
              </FormControlsBox>
            </S.FormControlsContainer>

            <S4.ModalBoxBtn>
              <S3.ButtonFlex>
                <S3.StyledButton as="button" type="button" onClick={handleClose}>
                  {t(IK.cancel)}
                </S3.StyledButton>
                <S3.StyledButton as="button" type="submit" $primary>
                  {t(IK.modify)}
                </S3.StyledButton>
              </S3.ButtonFlex>
            </S4.ModalBoxBtn>
          </form>
        </>
      )}
    </ModalLayout>
  );
};

export default PatientModal;
