import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteRefreshToken } from "../../../apis/accountApi";
import { useGetAlarmCount } from "../../../apis/alarmApi";
import * as S2 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/SideBar.style";

const SideTail = ({ setAlarmPopup }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);

  /**알람 카운트 조회*/
  const { data } = useGetAlarmCount();
  const alramCount = data?.data;

  /**로그아웃 */
  const onLogOut = () => {
    localStorage.removeItem("accessToken");
    deleteRefreshToken();
    localStorage.removeItem("user");
    localStorage.removeItem("recoil-persist");
    navigate("/");
  };

  return (
    <S.SideBarTail>
      <S.SideBarMenuList>
        <li>
          <button type="button" onClick={() => setAlarmPopup(true)}>
            <S.SideBarMenuIco>
              <i className="ri-megaphone-fill"></i>
            </S.SideBarMenuIco>
            <S.SideBarMenuTxt>{t(IK.alarm)}</S.SideBarMenuTxt>
            <S2.CountBadge $red>{alramCount}</S2.CountBadge>
          </button>
        </li>
        <li>
          <button type="button" onClick={onLogOut}>
            <S.SideBarMenuIco>
              <i className="ri-logout-box-r-fill"></i>
            </S.SideBarMenuIco>
            <S.SideBarMenuTxt>{t(IK.logout)}</S.SideBarMenuTxt>
          </button>
        </li>
      </S.SideBarMenuList>
    </S.SideBarTail>
  );
};

export default SideTail;
