export const Theme = {
    colors: {
        red600: '#e91e63',
        blue200: '#ebf2ff',
        blue400: '#377dff',
        white: '#fff',
        gray200: '#e9ebf1',
        gray300: '#f5f5f5',
        gray400: '#ccc',
        gray500: '#999',
        gray600: '#666',
        black: '#000',
    },
};
  