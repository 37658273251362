import Skeleton from "react-loading-skeleton";
import * as S from "../../layout/CardLayout/style/CardLayout.style";
const StudyViewSkeleton = () => {
  const skeletonCard = [
    { wt: 50, wd: 80 },
    { wt: 40, wd: 100 },
    { wt: 80, wd: 120 },
    { wt: 60, wd: 80 },
    { wt: 60, wd: 100 },
    { wt: 60, wd: 120 },
    { wt: 60, wd: 120 },
  ];

  return (
    <S.CardProfileWrapper>
      <S.CardProfileBox>
        <S.CardProfileHead>
          <S.CardProfileHeadInfo>
            <S.CardProfileHeadInfoImg>
              <Skeleton circle={true} height={80} width={80} />
            </S.CardProfileHeadInfoImg>
            <S.CardProfileHeadInfoTxt>
              <Skeleton width={200} height={40} />
            </S.CardProfileHeadInfoTxt>
          </S.CardProfileHeadInfo>
          <S.CardProfileHeadBtnBottom>
            <Skeleton width={80} height={30} />
            <Skeleton width={80} height={30} />
          </S.CardProfileHeadBtnBottom>
        </S.CardProfileHead>
        <S.CardProfileBodyInfo>
          {skeletonCard.map((card, i) => (
            <SkeletonDl key={i} card={card} />
          ))}
        </S.CardProfileBodyInfo>
      </S.CardProfileBox>
    </S.CardProfileWrapper>
  );
};

export default StudyViewSkeleton;

export const SkeletonDl = ({ card }) => {
  return (
    <dl>
      <dt>
        <Skeleton height={20} width={card.wt} />
      </dt>
      <dd>
        <Skeleton height={20} width={card.wd} />
      </dd>
    </dl>
  );
};
