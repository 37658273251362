import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import CountryBadge from "../country/CountryBadge";

const EventCard = ({ content, isCountryManager }) => {
  return (
    <li>
      <Link to={`/support/event/post/${content.eventBoardId}`} state={{ herf: window.location.search }}>
        <dl>
          <dt>
            <img src={content.thumb} alt="thumbnail" />
            {/**국가별 뱃지 */}
            {isCountryManager && <CountryBadge countryCodes={content?.countryCodes} />}
          </dt>
          <dd>
            <h3>{content.title}</h3>
            <span>
              {dayjs(content.startDate).format("YYYY.MM.DD")} - {dayjs(content.endDate).format("YYYY.MM.DD")}
            </span>
          </dd>
        </dl>
      </Link>
    </li>
  );
};

export default EventCard;
