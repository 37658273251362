import Skeleton from "react-loading-skeleton";
import { CardHead } from "../layout/CardLayout/CardLayout";
import * as S from "./style/BoardList.style";

const BoardSkeleton = () => {
  return (
    <>
      {/**헤더 */}
      <CardHead line>
        <Skeleton width="100px" height="24px" />
        <Skeleton width="100px" height="48px" />
      </CardHead>
      {/**카드리스트 */}
      <S.BoardCardList>
        {[...Array(10)].map((_, index) => (
          <BoardSkeletonCard key={index} />
        ))}
      </S.BoardCardList>
    </>
  );
};

export default BoardSkeleton;

export const BoardSkeletonCard = () => {
  return (
    <li>
      <dl>
        <dt>
          <Skeleton width="100%" height={180} />
        </dt>
        <dd>
          <h3>
            <Skeleton width="100%" height={45} />
          </h3>
        </dd>
      </dl>
    </li>
  );
};
