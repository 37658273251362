import Skeleton from "react-loading-skeleton";
import * as S from "../style/Study.style";

const PrescriptionSkeleton = () => {
  return (
    <S.StudyHistoryList>
      <li>
        <S.StudyHistoryCircle />
        <S.StudyHistoryItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.StudyHistoryItem>
      </li>
      <li>
        <S.StudyHistoryCircle />
        <S.StudyHistoryItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.StudyHistoryItem>
      </li>
      <li>
        <S.StudyHistoryCircle />
        <S.StudyHistoryItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.StudyHistoryItem>
      </li>
      <li>
        <S.StudyHistoryCircle />
        <S.StudyHistoryItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.StudyHistoryItem>
      </li>
      <li>
        <S.StudyHistoryCircle />
        <S.StudyHistoryItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.StudyHistoryItem>
      </li>
    </S.StudyHistoryList>
  );
};

export default PrescriptionSkeleton;
