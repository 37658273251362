export const doctorGrade = {
  DENTIS: "DENTIS",
  WHITE: "0%",
  GREEN: "15%",
  RED: "20%",
  BLUE: "25%",
  RUBY: "27%",
  PURPLE: "30%",
  BLACK: "32%",
  SERAFIN: "35%",
};
