import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import { downloadFile } from "../../apis/downloadApi";
import * as S2 from "../../styles/Common";
import imageRotate from "../../utils/imageRotate";
import * as S from "./style/Drop.style";

const DragImage = ({ files, setValue, name, deleteFileIds, setDeleteFileIds, trigger, isRadiation, disabled }) => {
  const { t } = useTranslation(["translation"]);

  const [filesData, setFilesData] = useState(files ? [files] : []);

  /**이미지 드래그앤 드랍 */
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    disabled: disabled,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setValue(name, acceptedFiles[0]);
      trigger && trigger(name);
      setFilesData(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploaded: false,
          })
        )
      );
    },
  });

  /**파일 삭제 */
  const handleOnClickClose = (e) => {
    setFilesData([]);
    const fileId = e.currentTarget.dataset.fileId;
    if (fileId && !deleteFileIds.includes(fileId)) {
      setDeleteFileIds([...deleteFileIds, fileId]);
    }
    setValue(name, null);
    trigger && trigger(name);
  };

  /**이미지 회전 */
  const generateHandler = (orientation) => (e) => {
    imageRotate(filesData, setFilesData, orientation, setValue, name);
  };

  const handleOnClickRefresh = generateHandler(6);
  const handleOnClickArrow1 = generateHandler(2);
  const handleOnClickArrow2 = generateHandler(4);

  useEffect(() => {
    if (files?.boardFileId || files?.additionalFileId) {
      //임상증례는 이미 저장되어있어서 스킵
      setFilesData([files]);
      setValue(name, files);
    }
  }, [files, name, setValue]);

  /**이미지 다운로드 */
  const handleDownload = (id, name) => {
    downloadFile(id, name, t);
  };

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  return (
    <S.DropItem $radiation={isRadiation}>
      <S.DropItemZone {...getRootProps()}>
        <input id={name} name={name} {...getInputProps()} />
      </S.DropItemZone>
      {filesData.map((file) => (
        <S.DropItemImg key={file?.fileId || file?.preview || files?.clinicalFileId}>
          <img src={file?.distFileName || file?.preview} alt="preview" />
          {file?.distFileName ? (
            <>
              <S.DropItemControlBtn type="button" onClick={() => handleDownload(files?.fileId, files?.orgFileName)} $downloadbtn>
                <i className="ri-download-line"></i>
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={() => handleImageViewer(file?.distFileName)} $enlargebtn>
                <i className="ri-fullscreen-line"></i>
              </S.DropItemControlBtn>
            </>
          ) : (
            <>
              <S.DropItemControlBtn type="button" onClick={handleOnClickRefresh} $refresh>
                <i className="ri-refresh-line" />
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={handleOnClickArrow1} $arrowbtn1>
                <i className="ri-arrow-left-right-line" />
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={handleOnClickArrow2} $arrowbtn2>
                <i className="ri-arrow-up-down-line" />
              </S.DropItemControlBtn>
            </>
          )}
          <>
            <S.DropItemControlBtn type="button" disabled={disabled} onClick={handleOnClickClose} data-file-id={file?.boardFileId || file?.clinicalFileId || files?.additionalFileId} $close>
              <i className="ri-close-line" />
            </S.DropItemControlBtn>
          </>
        </S.DropItemImg>
      ))}
      {imageViewer.isView && (
        <S2.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S2.ImageViewerBox>
      )}
    </S.DropItem>
  );
};

export default DragImage;
