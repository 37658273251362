import React from "react";
import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";

const MemoModal = ({ memoModal, setMemoModal }) => {
  const { t } = useTranslation(["translation"]);
  const handleClose = () => {
    setMemoModal({ isOpen: false, memo: "" });
  };
  return (
    <ModalLayout open={memoModal.isOpen} header={t(IK.memo)} handleClose={handleClose}>
      {memoModal.memo}
    </ModalLayout>
  );
};

export default MemoModal;
