import { useTranslation } from "react-i18next";

import Notiflix from "notiflix";
import { useDeleteEducationVideo } from "../../apis/supportApi";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import { IK } from "../../utils/i18n/keyStore";
import CountryBadge from "../country/CountryBadge";

const VideoForm = ({ videoData, isDefault, isAllCountry, setOpenModal }) => {
  const { t } = useTranslation(["translation"]);

  const videoDelete = useDeleteEducationVideo();
  /**게시판 단건 삭제 */
  const handleDelete = () => {
    Notiflix.Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => videoDelete.mutate(videoData?.supportLinkId));
  };

  /**게시판 수정 */
  const handleModify = () => {
    setOpenModal({ isOpen: true, videoData });
  };

  return (
    <tr>
      <th scope="row">{isDefault ? t(IK.support_link) : isAllCountry && <CountryBadge countryCodes={videoData?.countryCodes} />}</th>
      <td>
        <S3.FormControlsFlex>
          <S3.FormControls $full type="text" maxLength={20} value={videoData?.video?.video1 || ""} disabled />
          <S3.FormControls $full type="text" maxLength={20} value={videoData?.video?.video2 || ""} disabled />
          <S3.FormControls $full type="text" maxLength={20} value={videoData?.video?.video3 || ""} disabled />
          <S3.FormControls $full type="text" maxLength={20} value={videoData?.video?.video4 || ""} disabled />
          {isAllCountry && (
            <>
              <S4.StyledButton $primary as="button" onClick={handleModify}>
                {t(IK.modify)}
              </S4.StyledButton>
              {!isDefault && (
                <S4.StyledButton $secondary as="button" onClick={handleDelete}>
                  {t(IK.delete)}
                </S4.StyledButton>
              )}
            </>
          )}
        </S3.FormControlsFlex>
      </td>
    </tr>
  );
};

export default VideoForm;
