import styled from "styled-components";
import LoginBg from "../../../assets/images/login/login_bg.jpg";
import { SelectionItemLabel } from "../../../elements/SelectionControls/style/SelectionControls.style";

export const LoginContainer = styled.div`
  position: relative;
  height: 100vh;
  background: url(${LoginBg}) no-repeat center/cover;
`;

export const LoginBox = styled.form`
  position: absolute;
  top: 50%;
  right: 290px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 680px;
  margin-top: -340px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 100px 40px 40px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
`;

export const LoginLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const LoginFormBox = styled.div`
  margin-bottom: 13px;
`;

export const LoginFormFocus = styled.div`
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  transform: scaleX(1.1) scaleY(1.3);
`;

export const LoginForm = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  input {
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 15px;
    padding: 0 26px;
    transition: all 0.3s;
    border-radius: 10px;
    border: 0;
    &:focus + ${LoginFormFocus} {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const LoginContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${SelectionItemLabel} {
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const LoginButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  margin-top: 30px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.blue400};
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  &:hover {
    opacity: 0.8;
  }
`;

export const LoginCopy = styled.p`
  margin-top: auto;
  text-align: center;
  color: ${(props) => props.theme.colors.gray600};
`;
