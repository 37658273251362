import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

/**사용자 정보 */
export const userAuth = atom({
  key: "userAuth",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

/**사용자 타임존 */
export const userTimezone = atom({
  key: "userTimezone",
  default: Intl.DateTimeFormat().resolvedOptions().timeZone,
  effects_UNSTABLE: [persistAtom],
});
