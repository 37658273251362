import * as S from "./style/Message.style";

/**에러 메세지 */
export const ErrorMessage = ({ errors, name }) => {
  return (
    <>
      {errors[name] && (
        <S.ErrorMessage>
          <i className="ri-error-warning-fill" />
          {errors[name].message}
        </S.ErrorMessage>
      )}
    </>
  );
};

/**배열 에러메세지 */
export const ErrorsMessage = ({ errors, name }) => {
  const errorItem = errors[name]?.find((item) => item && item.message) || null;

  return (
    <>
      {errorItem && (
        <S.ErrorMessage>
          <i className="ri-error-warning-fill" />
          {errorItem.message}
        </S.ErrorMessage>
      )}
    </>
  );
};

/** text 에러 메세지 */
export const TextErrorMessage = ({ errors, message }) => {
  return (
    <>
      {errors && (
        <S.ErrorMessage>
          <i className="ri-error-warning-fill" />
          {message}
        </S.ErrorMessage>
      )}
    </>
  );
};

/**성공 메세지 */
export const SuccessMessage = ({ text }) => {
  return (
    <S.SuccessMessage>
      <i className="ri-checkbox-circle-fill" /> {text}
    </S.SuccessMessage>
  );
};

/**공지 메세지 */
export const NotiMessage = ({ text, mt0 }) => {
  return (
    <S.NotiMessage $mt0={mt0}>
      <i className="ri-error-warning-fill" /> {text}
    </S.NotiMessage>
  );
};
