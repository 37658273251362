import styled, { css } from "styled-components";

// 검색 테이블
export const TableType2 = styled.table`
  table-layout: fixed;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  border-left: 1px solid ${(props) => props.theme.colors.gray200};
  th {
    padding: 12px 25px;
    text-align: left;
    background-color: ${(props) => props.theme.colors.gray300};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }

  td {
    padding: 12px;
    background-color: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;

export const TableType1 = styled.table`
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-right: 0;
  thead th {
    height: 55px;
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.gray300};
  }
  tbody th {
    height: 55px;
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.gray300};
  }
  tbody td {
    height: 55px;
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }

  ${(props) =>
    props.$rowTop &&
    css`
      tbody td {
        vertical-align: top;
      }
    `};

  ${(props) =>
    props.$colLeft &&
    css`
      tbody td {
        text-align: left;
      }
    `};
`;

export const TableBottom = styled.div`
  margin-top: 10px;
  text-align: right;
`;
