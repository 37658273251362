import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetRecentStudy, useGetStudyInfo, useGetStudySwiperList } from "../apis/studyApi";
import { userAuth } from "../atom/userAtom";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import StudyViewInfo from "../components/study/StudyViewInfo";
import AdditionalDevice from "../components/study/studyDevices/AdditionalDevice";
import ReplaceDevice from "../components/study/studyDevices/ReplaceDevice";
import StudyDevice from "../components/study/studyDevices/StudyDevice";
import StudyViewCardSkeleton from "../components/study/studySkeleton/StudyViewCardSkeleton";
import StudyViewSkeleton from "../components/study/studySkeleton/StudyViewSkeleton";
import * as S2 from "../components/study/style/Study.style";
import { isPermissionStatus } from "../utils/centerStatus";
import { IK } from "../utils/i18n/keyStore";

const StudyView = () => {
  const { studyId } = useParams();
  const { t } = useTranslation(["translation"]);

  /**환자 정보 열기/닫기 */
  const [isStudyInfoVisible, setIsStudyInfoVisible] = useState(true);

  const handleToggleStudyInfo = () => {
    setIsStudyInfoVisible((prevVisible) => !prevVisible);
  };

  /**권한처리 */
  const { roleNames } = useRecoilValue(userAuth);
  const studyAuth = isPermissionStatus(roleNames, "studyList");

  /**선택된 처방전의 id */
  const [selectedId, setSelectedId] = useState({});

  /**선택된 처방전의 부가정보 */
  const [selectedInfo, setSelectedInfo] = useState({});

  /**현재 어떤 처방전인지 판단 */
  const [isNowStudy, setIsNowStudy] = useState(false);

  /**환자 정보 조회 */
  const { data: patientData, isLoading: isStudyLoading, isError: isStudyError } = useGetStudyInfo(studyId);
  const studyInfo = patientData?.data;

  /**선택된 스터디의 스와이퍼 리스트 */
  const { data: swiperData, isLoading: isSwiperLoading, isError: isSwiperError } = useGetStudySwiperList(studyInfo?.patientId, studyId);
  const studySwiperList = swiperData?.data;

  /**가장 최근에 작성된 처방전 조회*/
  const { data: recentData, isLoading: isRecentLoading, isError: isRecentErorr } = useGetRecentStudy(studyInfo?.patientId, studyId);
  const recentStudy = recentData?.data;

  /**최근 작성된 아이디와 처방전 상태를 설정 */
  useEffect(() => {
    if (!isRecentLoading && recentStudy?.deviceName) {
      setSelectedId({ [recentStudy.deviceName]: recentStudy.deviceId });
      setSelectedInfo(recentStudy);
    }
  }, [isRecentLoading, recentStudy]);

  /**현재진행중인 스터디인지 판단 */
  useEffect(() => {
    if (!isRecentLoading && recentStudy?.deviceId === Object?.values(selectedId)[0]) {
      setIsNowStudy(true);
    } else {
      setIsNowStudy(false);
    }
  }, [isRecentLoading, recentStudy, selectedId]);

  /**장치 리스트 */
  const studyComponents = {
    study: <StudyDevice studyInfo={studyInfo} detail={studyInfo?.detail} studyId={studyId} isNowStudy={isNowStudy} studyAuth={studyAuth} />,
    additional: (
      <AdditionalDevice
        detail={studyInfo?.detail}
        studyId={studyId}
        additionalId={selectedId?.additional}
        studyInfo={studyInfo}
        isNowStudy={isNowStudy}
        studyAuth={studyAuth}
        selectedInfo={selectedInfo}
      />
    ),
    replacement: <ReplaceDevice studyAuth={studyAuth} detail={studyInfo?.detail} studyInfo={studyInfo} studyId={studyId} replacementId={selectedId?.replacement} isNowStudy={isNowStudy} />,
  };

  /**추가교정장치 선택시 아이디값 변경 */
  const handleChangeDeviceId = (id, idInfo) => {
    setSelectedId(id);
    setSelectedInfo(idInfo);
  };

  const nameList = {
    additional: "additional_device",
    replacement: "alternative_correction_devices",
    study: "main_study",
  };

  const isErrorStatus = isStudyError || isSwiperError || isRecentErorr;
  const isLoadingStatus = isStudyLoading || isSwiperLoading || isRecentLoading;

  return (
    <S.CardFlex>
      {isErrorStatus || isLoadingStatus ? (
        <>
          <StudyViewSkeleton />
          <S.CardColWd $full>
            <StudyViewCardSkeleton />
          </S.CardColWd>
        </>
      ) : (
        <>
          <S2.StudyViewToggleBtn type="button" onClick={handleToggleStudyInfo}>
            {t(IK.patient_information)}
            {isStudyInfoVisible ? <i className="ri-arrow-right-s-fill"></i> : <i className="ri-arrow-left-s-fill"></i>}
          </S2.StudyViewToggleBtn>
          {isStudyInfoVisible && <StudyViewInfo studyId={studyId} studyInfo={studyInfo} studyAuth={studyAuth} roleNames={roleNames} />}
          <S.CardColWd $full>
            <S2.StudyViewTab>
              <Swiper slidesPerView={"auto"} navigation={true} modules={[Navigation]}>
                {studySwiperList.map((item, index) => (
                  <SwiperSlide
                    key={`${index}_${item?.createdDate}`}
                    className={item[`${item.type}Id`] === selectedId?.[item.type] ? "active" : ""}
                    onClick={() =>
                      handleChangeDeviceId(
                        {
                          [item.type]: item[`${item.type}Id`],
                        },
                        item
                      )
                    }
                  >
                    {t(IK[nameList[item.type]])}({dayjs(item.createdDate).format("YY-MM-DD")})
                  </SwiperSlide>
                ))}
              </Swiper>
            </S2.StudyViewTab>
            {studyComponents[Object.keys(selectedId)[0]]}
          </S.CardColWd>
        </>
      )}
    </S.CardFlex>
  );
};

export default StudyView;
