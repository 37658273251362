import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { TimePicker } from "../../elements/DatePicker";
import * as S2 from "../../elements/Form/style/Form.style";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/AlarmPopup.style";

const AlarmFilter = ({ t, setAlarmParams, alarmParams, scrollRef, alarmCount, setSelectedAlarm }) => {
  /**알람 필터 */
  const alarmfilter = [
    { text: t(IK.total), value: "", count: "allCount" },
    { text: t(IK.charge_treatment_plan), value: "ASSIGNED_ALARM", count: "assignedAlarmCount" },
    { text: t(IK.alarm_sign_up), value: "REGISTER_FILTER", count: "registerFilterCount" },
    { text: t(IK.alarm_submit), value: "SUBMIT_FILTER", count: "submitFilterCount" },
    { text: t(IK.alarm_modify), value: "SETUP_EDIT_FILTER", count: "setupEditFilterCount" },
    { text: t(IK.alarm_approve), value: "SETUP_ACCEPT_FILTER", count: "setupAcceptFilterCount" },
    { text: t(IK.alarm_vip), value: "VIP_COMMENT_FILTER", count: "vipCommentFilterCount" },
    { text: t(IK.alarm_delivery), value: "DELIVERY_RECEPTION", count: "deliveryReceptionCount" },
    { text: t(IK.delay), value: "DELAY", count: "delayCount" },
  ];

  const { handleSubmit, register, reset, watch, setValue } = useForm();
  const searchs = watch();

  /**검색버튼 클릭 */
  const onSubmitData = (data) => {
    if (data.FROM > data.TO) {
      //종료일이 시작일 보다 빠른 경우 경고
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    const filteredData = Object.fromEntries(Object.entries({ alarmFilterCode: alarmParams.alarmFilterCode, ...data }).filter(([key, value]) => value !== ""));
    setAlarmParams({ alarmCode: "ADMIN", ...filteredData });
    setSelectedAlarm([]); //알람 선택 초기화
  };

  /**검색 필터 설정 */
  const alramSearch = (value) => {
    /**필터버튼 클릭시 스크롤 상단으로 이동 */
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    setAlarmParams((prev) => ({ ...prev, alarmFilterCode: value }));
    setSelectedAlarm([]); //알람 선택 초기화
  };
  /**검색 초기화 */
  const onReset = () => {
    reset();
    /**초기화 클릭시 스크롤 상단으로 이동 */
    scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    setAlarmParams({
      alarmCode: "ADMIN",
      alarmFilterCode: "",
    });
    setSelectedAlarm([]); //알람 선택 초기화
  };

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("from", dayjs(date).format("YYYY-MM-DD HH:mm"));
    } else {
      setValue("from", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("to", dayjs(date).format("YYYY-MM-DD HH:mm"));
    } else {
      setValue("to", "");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <S2.FormControlsColumn>
          <S2.FormControlsFlex>
            <TimePicker handleDataChange={handleStartDate} placeholderText={t(IK.from_time)} selected={searchs.from ? new Date(searchs.from) : null} formTimeControls full />
            <TimePicker handleDataChange={handleEndDate} placeholderText={t(IK.to_time)} selected={searchs.to ? new Date(searchs.to) : null} formTimeControls full />
          </S2.FormControlsFlex>
          <S2.FormControlsFlex>
            <S2.FormControls $full type="text" placeholder={t(IK.doctor_name)} {...register("doctorName")} />
            <S2.FormControls $full type="text" placeholder={t(IK.patient_name)} {...register("patientName")} />
            <S3.StyledButton type="submit" as="button" $primary>
              <i className="ri-search-2-line"></i> {t(IK.search)}
            </S3.StyledButton>
            <S3.StyledButton type="button" as="button" onClick={onReset}>
              <i className="ri-refresh-line"></i> {t(IK.reset)}
            </S3.StyledButton>
          </S2.FormControlsFlex>
        </S2.FormControlsColumn>
      </form>

      <S.AlarmPopTab>
        {alarmfilter.map((item) => (
          <li key={item.value}>
            <S.AlarmPopTabBtn $active={item.value === alarmParams.alarmFilterCode} onClick={() => alramSearch(item.value)}>
              {item.text} {<strong>({alarmCount ? alarmCount[item.count] : "0"})</strong>}
            </S.AlarmPopTabBtn>
          </li>
        ))}
      </S.AlarmPopTab>
    </>
  );
};

export default AlarmFilter;
