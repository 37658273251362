import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import * as S from "../../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../elements/Tab/style/Tab.style";
import { IK } from "../../../utils/i18n/keyStore";
import StudyViewDelivery from "../studyContent/StudyViewDelivery";
import StudyViewReplaceSummary from "../studyContent/StudyViewReplaceSummary";
import * as S3 from "../style/Study.style";

const ReplaceDevice = ({ studyAuth, studyId, studyInfo, detail, replacementId, isNowStudy }) => {
  const { t } = useTranslation(["translation"]);

  const componentRef = useRef();
  /**프린트 실행*/
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**컴포넌트 클릭 상태값 관리 */
  const [btn, setBtn] = useState("prescription_summary");

  useEffect(() => {
    setBtn("prescription_summary");
  }, [replacementId]);

  const components = {
    prescription_summary: <StudyViewReplaceSummary studyAuth={studyAuth} handlePrint={handlePrint} patientId={studyInfo.patientId} replacementId={replacementId} />,
    delivery: <StudyViewDelivery studyId={studyId} studyInfo={studyInfo} studyAuth={studyAuth} replacementId={replacementId} />,
  };

  return (
    <S.CardInner>
      {(isNowStudy ? detail >= "15" : true) && (
        <S2.TabType2>
          {Object.keys(components).map((key) => (
            <S2.TabType2Item key={key} onClick={() => setBtn(key)} $active={btn === key}>
              {t(IK[key])}
            </S2.TabType2Item>
          ))}
        </S2.TabType2>
      )}
      <S3.PrintWrapper ref={componentRef}>{components[btn]}</S3.PrintWrapper>
    </S.CardInner>
  );
};

export default ReplaceDevice;
