import Pagination from "react-js-pagination";
import { styled } from "styled-components";

const Paging = ({ currentPage, handleChangePage, totalCount, size }) => {
  return (
    <StPagination>
      <Pagination
        activePage={Number(currentPage)}
        itemsCountPerPage={Number(size)}
        totalItemsCount={totalCount}
        onChange={handleChangePage}
        prevPageText={<i className="ri-arrow-left-s-line"></i>}
        nextPageText={<i className="ri-arrow-right-s-line"></i>}
        lastPageText={<i className="ri-arrow-right-double-line"></i>}
        firstPageText={<i className="ri-arrow-left-double-line"></i>}
        itemClass="pg_num pg_page"
        activeClass="pg_current"
        itemClassFirst="pg_page pg_start"
        itemClassPrev="pg_page pg_prev"
        itemClassNext="pg_page pg_next"
        itemClassLast="pg_page pg_end"
      />
    </StPagination>
  );
};

export default Paging;

const StPagination = styled.div`
  .pagination {
    display: flex;
    gap: 8px;
  }

  .pg_page a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.gray300};
    font-size: 13px;
    cursor: pointer;
  }

  .pg_page:hover a {
    border-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.blue400};
  }

  .pg_page.disabled a {
    cursor: auto;
  }

  .pg_page.disabled:hover a {
    border-color: ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.black};
  }

  .pg_current a {
    border-color: ${(props) => props.theme.colors.blue400};
    background-color: ${(props) => props.theme.colors.blue400};
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
  }

  .pg_current:hover a {
    background-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.white} !important;
  }

  .pg_prev {
    margin-right: 10px;
  }

  .pg_next {
    margin-left: 10px;
  }
`;
