import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetProgramFile, usePostProgramFile } from "../../apis/supportApi";
import { TextErrorMessage } from "../../elements/Message/MessageText";
import { enterKeyDown } from "../../utils/enterKeydown";
import { IK } from "../../utils/i18n/keyStore";

//스타일 관련
import Skeleton from "react-loading-skeleton";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";

/**프로그램 파일 등록 폼
 * @param {string} programType - 프로그램 타입(seraview, sera-quick)
 */
const ProgramFileForm = ({ programType }) => {
  /**다국어 변환 함수 */
  const { t } = useTranslation(["translation"]);

  /**해당 파일 데이터 조회 */
  const { data, isLoading } = useGetProgramFile(programType);

  /**프로그램 파일 정보
   * @type {Object}
   * @property {string} version - 파일 버전
   * @property {string} orgFileName - 원본 파일명
   * @property {number} fileId - 파일 아이디
   */
  const programData = data?.data;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  //업로드 파일 추적
  const fileInfo = watch("setupFile");

  /**파일 등록 */
  const filePost = usePostProgramFile(programType);

  /**파일 업로드*/
  const uploadFile = (data) => {
    // FormData 생성
    const formData = new FormData();
    formData.append("version", data.version);
    formData.append("setupFile", data.setupFile[0]);

    //성공하면 폼 초기화
    filePost.mutate(formData, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <Skeleton height={150} />
          ) : (
            <form onSubmit={handleSubmit(uploadFile)} onKeyDown={enterKeyDown}>
              <S2.TableType2>
                <colgroup>
                  <col width="200px"></col>
                  <col width="*"></col>
                  <col width="200px"></col>
                  <col width="*"></col>
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">version</th>
                    <td>
                      <S3.FormControls
                        type="text"
                        maxLength={8}
                        defaultValue={programData?.version}
                        {...register("version", {
                          required: true,
                          maxLength: 8,
                        })}
                        disabled={!fileInfo?.[0]?.name}
                      />
                      <TextErrorMessage errors={errors.version} message={t(IK.seraview_file_version_error)} />
                    </td>
                    <th scope="row">{programType === "seraview" ? t(IK.seraview_file) : t(IK.seraquick_file)}</th>
                    <td>
                      <S3.FormControlsFlex>
                        <S3.FormControls $full value={fileInfo?.[0]?.name || programData?.orgFileName} disabled />
                        <S4.StyledButton $primary htmlFor="serafile" as="label">
                          {t(IK.modify)}
                        </S4.StyledButton>
                        <S3.FormControls id="serafile" type="file" {...register("setupFile")} hidden />
                      </S3.FormControlsFlex>
                    </td>
                  </tr>
                </tbody>
              </S2.TableType2>
              <S4.ButtonCtBox>
                <S4.StyledButton $primary as="button" disabled={!fileInfo?.[0]?.name}>
                  {t(IK.registration)}
                </S4.StyledButton>
              </S4.ButtonCtBox>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default ProgramFileForm;
