import { keyframes, styled } from "styled-components";

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerWrapper>
      <div className="spinner" />
    </LoadingSpinnerWrapper>
  );
};

export default LoadingSpinner;

const spinnerRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .spinner {
    position: relative;
    width: 40px;
    height: 40px;

    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px;
      margin-left: -16px;
      border-radius: 50%;
      border: 2px solid ${(props) => props.theme.colors.blue400};
      border-top-color: transparent;
      animation: ${spinnerRotate} 1s linear infinite;
    }
  }
`;
