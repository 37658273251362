import { css, styled } from "styled-components";

export const TabType1 = styled.ul`
  display: flex;
`;

export const TabType1Item = styled.li`
  padding: 15px 20px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  &:first-child {
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
  }
  cursor: pointer;
  ${(props) =>
    props.$active &&
    css`
      color: red;
    `}
`;

export const TabType2 = styled.ul`
  display: flex;
  margin-bottom: 30px;
`;

export const TabType2Item = styled.li`
  position: relative;
  flex: 1;
  height: 60px;
  line-height: 58px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.gray300};
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  cursor: pointer;
  font-size: 15px;
  &:first-child {
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
  }
  &:last-child {
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }
  &:nth-child(n + 2):before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray400};
  }
  a {
    display: block;
  }

  ${(props) =>
    props.$active &&
    css`
      border-bottom-color: transparent;
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
      background-color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      &:before {
        display: none;
      }
      + li:before {
        display: none;
      }
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 2px;
        background-color: ${(props) => props.theme.colors.blue400};
      }
    `}
`;
