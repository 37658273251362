import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S2 from "../../elements/Form/style/Form.style";
import { codeToCountry, countryToCode } from "../../utils/countryUtils";
import { IK } from "../../utils/i18n/keyStore";
import CountryCheckBox from "./CountryCheckBox";

/**국가 선택 버튼
 * @param {object} setValue - useForm의 setValue
 * @description 국가선택 key값은 countryCodes
 * @returns {JSX.Element} 국가 선택 버튼
 */
const CountryBtn = ({ setValue, watch = [], clearErrors, fieldName = "countryCodes" }) => {
  const { t } = useTranslation(["translation"]);

  /**국가 선택 관리*/
  const [countryModal, setCountryModal] = useState({
    isOpen: false,
    selectedCountries: watch?.map((code) => codeToCountry(code)) || [],
  });

  /**국가 선택 모달 열기 */
  const openCountryModal = useCallback(
    () =>
      setCountryModal({
        selectedCountries: watch?.map((code) => codeToCountry(code)) || [],
        isOpen: true,
      }),
    [watch]
  );

  /**국가 선택 모달 닫기 */
  const handleClose = useCallback(() => setCountryModal({ selectedCountries: [], isOpen: false }), []);

  /**국가 선택 확인 */
  const handleConfirm = useCallback(() => {
    //국가를 코드로 변환
    const countryCodes = countryModal.selectedCountries.map((country) => countryToCode(country));
    setValue(fieldName, countryCodes);
    //에러 제거
    if (clearErrors) clearErrors(fieldName);

    handleClose();
  }, [handleClose, setValue, countryModal.selectedCountries, clearErrors, fieldName]);

  return (
    <>
      <S2.FormControlsBtn as="button" type="button" onClick={openCountryModal}>
        {t(IK.select_country)}
      </S2.FormControlsBtn>
      {countryModal.isOpen && <CountryCheckBox countryModal={countryModal} setCountryModal={setCountryModal} onClose={handleClose} onConfirm={handleConfirm} />}
    </>
  );
};

export default CountryBtn;
