import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**쿠폰 리스트 조회 */
export const useGetCouponList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["CouponList", searchCondition],
    async () => {
      const { data } = await axios.get(`/api/admin/coupon`, {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**발급된 쿠폰 정보 보기 */
export const useGetCouponInfo = (couponId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["CouponInfo", couponId],
    async () => {
      const { data } = await axios.get(`/api/admin/coupon/${couponId}`);
      return data;
    },
    {
      enabled: !!couponId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**쿠폰 생성 */
export const useCreateCoupon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.post(`/api/admin/coupon`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CouponList");
      },
    }
  );
};

/**쿠폰 삭제 */
export const useDeleteCoupon = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      await axios.delete(`/api/admin/coupon`, {
        params: { deleteCouponIdList: data.join(",") },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CouponList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {});
      },
      onError: (error) => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.coupon_error_msg), t(IK.confirm), () => {});
      },
    }
  );
};
