import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { IK } from "../../utils/i18n/keyStore";
import { CardTail } from "../layout/CardLayout/CardLayout";

/**게시판 글작성 스켈레톤 */
const BoardPostSkeleton = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <div>
      <S2.FormControlsContainer>
        <FormControlsBox title={t(IK.board_title)}>
          <Skeleton height={45} width={300} />
        </FormControlsBox>
        <FormControlsBox title={t(IK.board_content)}>
          <Skeleton height={400} />
        </FormControlsBox>
        <FormControlsBox>
          <Skeleton height={45} width={400} />
        </FormControlsBox>
      </S2.FormControlsContainer>
      <CardTail line>
        <S3.ButtonLtBox>
          <Skeleton width={109} height={45} />
          <Skeleton width={109} height={45} />
        </S3.ButtonLtBox>
      </CardTail>
    </div>
  );
};

export default BoardPostSkeleton;
