import Skeleton from "react-loading-skeleton";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../elements/Form/style/Form.style";
import { FormSkeleton } from "../clinicalGallery/CliniCalGalleryInfoSkeleton";

const DoctorViewSkeleton = () => {
  return (
    <S.CardInner>
      <S.CardTitle>
        <h2>
          <Skeleton width={500} height={40} />
        </h2>
      </S.CardTitle>
      <S2.FormCardList $bgwhite>
        <li>
          <S2.FormControlsContainer>
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
          </S2.FormControlsContainer>
        </li>
        <li>
          <S2.FormControlsContainer>
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
            <FormSkeleton width={400} />
          </S2.FormControlsContainer>
        </li>
      </S2.FormCardList>
    </S.CardInner>
  );
};

export default DoctorViewSkeleton;
