import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useGetPopupList } from "../apis/popupApi";
import { userAuth } from "../atom/userAtom";
import BoardTotal from "../components/board/BoardTotal";
import * as S5 from "../components/board/style/BoardList.style";
import CountryBadge from "../components/country/CountryBadge";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import PopUpFilter from "../components/popUp/PopUpFilter";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearchParam } from "../hooks/useSearchParam";
import { IK } from "../utils/i18n/keyStore";
import { pageListNumber } from "../utils/pageListNumber";
import { changeParam } from "../utils/searchParams";

const PopUpList = () => {
  const { t } = useTranslation(["translation"]);

  /**유저정보 조회 */
  const { countryCode } = useRecoilValue(userAuth);
  const isAllCountry = countryCode === "00";

  /**검색 조건 설정*/
  const { searchCondition, setSearchCondition } = useSearchParam({ page: 1 });
  /**페이지 사이즈(고정) */
  const searchSize = 10;

  /**이벤트 리스트 조회 */
  const { data, isLoading } = useGetPopupList(changeParam(searchCondition));
  const popups = data?.content;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /**정렬 */
  const handleSorting = () => {
    if (!searchCondition?.sort) {
      setSearchCondition((prev) => ({
        ...prev,
        sort: "order,asc",
        page: 1,
      }));
    } else if (searchCondition?.sort === "order,asc") {
      setSearchCondition((prev) => ({
        ...prev,
        sort: "order,desc",
        page: 1,
      }));
    } else if (searchCondition?.sort === "order,desc") {
      setSearchCondition((prev) => {
        const { sort, ...rest } = prev;
        return { ...rest, page: 1 };
      });
    }
  };

  const tableCol = ["70px", "110px", "*", "150px", "150px", "*", "180px"];
  const tableHead = [
    "No.",
    t(IK.pop_type),
    t(IK.pop_name),
    t(IK.pop_used),
    <S5.BoardTableSortBox type="button" onClick={handleSorting} $Primary={!searchCondition?.sort}>
      {/* BoardTableSortBox 클릭 시 "Primary" props 생성 */}
      {t(IK.pop_order)}
      {/* 오름차순일 경우 "SortAsc" props 생성 / 내림차순일 경우 "SortDesc" props 생성 */}
      <S5.BoardTableSortBoxSpan $SortAsc={searchCondition?.sort === "order,asc"} $SortDesc={searchCondition?.sort === "order,desc"}>
        <i className="ri-arrow-up-s-fill" />
        <i className="ri-arrow-down-s-fill" />
      </S5.BoardTableSortBoxSpan>
    </S5.BoardTableSortBox>,
    t(IK.pop_date),
    t(IK.register_date),
  ];

  if (isAllCountry) {
    //국가코드가 00일 경우 국가별 뱃지 추가
    tableCol.push("200px");
    tableHead.push(t(IK.country));
    //인트로 노출 여부
    tableCol.push("100px");
    tableHead.push(t(IK.intro_use));
  }

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <PopUpFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} isCountryManager={isAllCountry} />
        </S.CardInner>
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead line>
              <BoardTotal total={data?.totalElements} />
              {isAllCountry && (
                <S4.StyledButton $primary to="/support/popup/post">
                  {t(IK.registration)}
                </S4.StyledButton>
              )}
            </CardHead>
            <DataTable
              dataLength={data?.totalElements}
              tableCol={tableCol}
              tableHead={tableHead}
              tableTr={
                <>
                  {popups?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{pageListNumber(data?.totalElements, searchCondition?.page, index)}</td>
                      <td>{item?.type === "IMAGE" ? t(IK.pop_type_image) : t(IK.pop_type_video)}</td>
                      <td>
                        <S5.BoardTableLink to={`/support/popup/post/${item.id}`} state={{ herf: window.location.search }}>
                          {item?.name}
                        </S5.BoardTableLink>
                      </td>
                      <td>{item?.display ? t(IK.pop_used_use) : t(IK.pop_used_not_use)}</td>
                      <td>{item?.order}</td>
                      <td>{!item?.startDate ? t(IK.no_limit) : `${dayjs(item?.startDate).format("YYYY-MM-DD-HH:mm")} ~ ${dayjs(item?.endDate).format("YYYY-MM-DD-HH:mm")}`}</td>
                      <td>{dayjs(item?.createdDate).format("YYYY-MM-DD")}</td>
                      {/**국가별 뱃지 */}
                      {isAllCountry && <td>{item?.countryCodes && !item?.introDisplay ? <CountryBadge countryCodes={item?.countryCodes} /> : t(IK.all_country)}</td>}
                      {/**인트로 노출 여부 */}
                      {isAllCountry && <td>{item?.introDisplay ? "O" : "X"}</td>}
                    </tr>
                  ))}
                </>
              }
            />
            <CardTail line>
              <Paging totalCount={data?.totalElements} currentPage={searchCondition.page} size={searchSize} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default PopUpList;
