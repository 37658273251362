import { Confirm } from "notiflix";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { downloadFile } from "../../../apis/downloadApi";
import { useEditArch, useEditEvery10, useEditSpecialInstructions, useGetStudySummary, useStudyDelete } from "../../../apis/studyApi";
import * as S5 from "../../../elements/Button/style/ButtonLayout.style";
import { DropImageBox, DropImageBoxPrint } from "../../../elements/Drop/DropImageBox";
import { FormControlsBox } from "../../../elements/Form/FormLayout";
import * as S3 from "../../../elements/Form/style/Form.style";
import * as S4 from "../../../elements/SelectionControls/style/SelectionControls.style";
import TeethSummaryBox from "../../../elements/TeethCheckBox/TeethSummaryBox";
import * as S6 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { getSummaryLabel, studyExtroImages, studyFormConfig, studyIntroImages, studyRadiographs } from "../../../utils/studyStatus";
import { CardTail } from "../../layout/CardLayout/CardLayout";
import * as S from "../../layout/CardLayout/style/CardLayout.style";
import * as S2 from "../style/Study.style";

const StudyViewSummary = ({ studyId, handlePrint, studyAuth }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**처방전 정보 */
  const { data: studyData, isLoading: isSummary, isError } = useGetStudySummary(studyId);
  const studyInfo = studyData?.data;
  const config = studyFormConfig[studyInfo?.study?.packages];

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  /**특별지시사항 수정 */
  const [isEditSpecialInstruction, setIsEditSpecialInstruction] = useState(false);
  const [specialInstruction, setSpecialInstruction] = useState(studyInfo?.SI13);

  /**every10 수정 */
  const [isEditEvery10, setIsEditEvery10] = useState(false);
  const [every10, setEvery10] = useState(studyInfo?.SI15);

  const archLabel = useMemo(
    () => ({
      1: "BOTH",
      2: "UPPER",
      3: "LOWER",
    }),
    []
  );

  /**치료해야할 악궁 */
  const [isEditDentalArchTreated, setIsEditDentalArchTreated] = useState(false);
  const [dentalArchTreated, setDentalArchTreated] = useState(studyInfo?.SI01);

  useEffect(() => {
    if (!isSummary && studyInfo) {
      setSpecialInstruction(studyInfo?.SI13);
      setEvery10(studyInfo?.SI15);
      setDentalArchTreated(archLabel[studyInfo?.SI01]);
    }
  }, [isSummary, studyInfo, archLabel]);

  const specialInstructionMutation = useEditSpecialInstructions("StudySummary");
  /**특별지시사항 등록 */
  const editSpecialInstruction = () => {
    specialInstructionMutation.mutate(
      {
        type: "study",
        id: studyId,
        data: { memo: specialInstruction },
      },
      {
        onSuccess: () => {
          setIsEditSpecialInstruction(false);
        },
      }
    );
  };

  const every10Mutation = useEditEvery10();
  /**every10 등록 */
  const editEvery10 = () => {
    every10Mutation.mutate(
      {
        studyId,
        data: { code: every10 },
      },
      {
        onSuccess: () => {
          setIsEditEvery10(false);
        },
      }
    );
  };

  const dentalArchTreatedMutation = useEditArch();
  /**악궁수정 등록 */
  const editDentalArchTreated = () => {
    dentalArchTreatedMutation.mutate(
      {
        studyId,
        data: { code: dentalArchTreated },
      },
      {
        onSuccess: () => {
          setIsEditDentalArchTreated(false);
        },
      }
    );
  };

  const studyDeleteMutation = useStudyDelete();
  /**처방전 삭제 */
  const deleteStudy = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        studyDeleteMutation.mutate(studyId);
      },
      () => {}
    );
  };

  return (
    <>
      {isSummary || isError ? (
        <Skeleton height={800} />
      ) : (
        <>
          <S2.StudySummary>
            {/* 의사명 */}
            <SummaryTableLi title={t(IK.doctor_name)}>
              {studyInfo?.doctor && (
                <p>
                  {studyInfo.doctor.name} {studyInfo.doctor?.koreaName && `(${studyInfo.doctor.koreaName})`}
                </p>
              )}
            </SummaryTableLi>

            {/* 인쇄시 환자명, 치료옵션 보이도록 */}
            <SummaryTableLi printshow title={t(IK.patient_name)}>
              {studyInfo?.patient && <p>{`${studyInfo.patient?.firstName} ${studyInfo.patient?.lastName}${studyInfo.patient?.korName ? ` (${studyInfo.patient?.korName})` : ""}`}</p>}
            </SummaryTableLi>
            <SummaryTableLi printshow title={t(IK.treatment_option)}>
              {studyInfo.study && <p>{`${t(IK[studyInfo.study?.packages])}`}</p>}
            </SummaryTableLi>

            {/* 배송지 */}
            <SummaryTableLi title={t(IK.shipping_address)}>{studyInfo.patient && <p>{studyInfo.patient.shipAddressName}</p>}</SummaryTableLi>

            {/* 청구지 */}
            <SummaryTableLi title={t(IK.billing_address)}>{studyInfo.patient && <p>{studyInfo.patient.billAddressName}</p>}</SummaryTableLi>

            {/* 의사 연락처 */}
            <SummaryTableLi title={t(IK.doctor_tel)}>{studyInfo?.doctor && <p>{studyInfo?.doctor?.phoneNumber}</p>}</SummaryTableLi>

            {/* 치료해야 할 악궁 */}
            {config.DentalArchTreated && (
              <SummaryTableLi title={t(IK.dental_arch_treated)} printhide>
                {isEditDentalArchTreated ? (
                  <S3.FormControlsColumn>
                    <S4.SelectionItemList>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $radio
                            type="radio"
                            name="studyInfoSI01"
                            value="BOTH"
                            id="BOTH"
                            defaultChecked={studyInfo.SI01 === "1"}
                            onChange={(e) => setDentalArchTreated(e.target.value)}
                          />
                          <S4.SelectionItemSpan>{t(IK.bimaxillay)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>

                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $radio
                            type="radio"
                            name="studyInfoSI01"
                            value="UPPER"
                            id="UPPER"
                            defaultChecked={studyInfo.SI01 === "2"}
                            onChange={(e) => setDentalArchTreated(e.target.value)}
                          />
                          <S4.SelectionItemSpan>{t(IK.maxilla)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>

                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $radio
                            type="radio"
                            name="studyInfoSI01"
                            value="LOWER"
                            id="LOWER"
                            defaultChecked={studyInfo.SI01 === "3"}
                            onChange={(e) => setDentalArchTreated(e.target.value)}
                          />
                          <S4.SelectionItemSpan>{t(IK.mandible)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    </S4.SelectionItemList>
                    <S5.ButtonFlex $small>
                      <S5.StyledSmallButton as="button" type="button" onClick={editDentalArchTreated}>
                        {t(IK.registration)}
                      </S5.StyledSmallButton>
                      <S5.StyledSmallButton $secondary as="button" type="button" onClick={() => setIsEditDentalArchTreated(false)}>
                        {t(IK.cancel)}
                      </S5.StyledSmallButton>
                    </S5.ButtonFlex>
                  </S3.FormControlsColumn>
                ) : (
                  <S3.FormControlsFlex>
                    <p>
                      {getSummaryLabel(studyInfo, "SI01", t)}
                      {studyInfo.SI01 === "2" && !!studyInfo.SI01_2_01 && `(${getSummaryLabel(studyInfo, "SI01_2_01", t)})`}
                      {studyInfo.SI01 === "3" && !!studyInfo.SI01_3_01 && `(${getSummaryLabel(studyInfo, "SI01_3_01", t)})`}
                    </p>
                    {studyAuth.studyArchFix && (
                      <S5.StyledSmallButton as="button" type="button" onClick={() => setIsEditDentalArchTreated(true)}>
                        {t(IK.modify)}
                      </S5.StyledSmallButton>
                    )}
                  </S3.FormControlsFlex>
                )}
              </SummaryTableLi>
            )}

            {/* 발치 할 치아 */}
            {config.Extraction && (
              <SummaryTableLi title={t(IK.extraction)}>
                {studyInfo.SI12 === "1" && <p>{t(IK.extraction_item1)}</p>}
                {studyInfo.SI12 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.Extraction?.extraction_item2} code="SI12" />}
              </SummaryTableLi>
            )}

            {/* 이동에 제한이 있는 치아 */}
            {config.TeethLimitedMobility && (
              <SummaryTableLi title={t(IK.teeth_limited_mobility)}>
                {studyInfo.SI02 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
                {studyInfo.SI02 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.TeethLimitedMobility?.teeth_limited_mobility_item2_teeth} code="SI02" />}
              </SummaryTableLi>
            )}

            {/* 어태치먼트 */}
            {config.Attachment && (
              <SummaryTableLi title={t(IK.attachment)}>
                {studyInfo.SI03 === "1" && <p>{t(IK.attachment_item1)}</p>}
                {studyInfo.SI03 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.Attachment?.attachment_item2_teeth} code="SI03" />}
              </SummaryTableLi>
            )}

            {/* 어태치먼트 AP*/}
            {config.ApAttachment && (
              <SummaryTableLi title={t(IK.ap_attachment)} dataId="ApAttachment">
                {studyInfo.SI16 === "1" && <p>{t(IK.ap_attachment_text_1)}</p>}
                {studyInfo.SI16 === "2" && <p>{t(IK.ap_attachment_text_2)}</p>}
                {studyInfo.SI16 === "3" && <p>{t(IK.ap_attachment_text_3)}</p>}
              </SummaryTableLi>
            )}

            {/* 악궁 확장 */}
            {config.DentalArchExtension && (
              <SummaryTableLi title={t(IK.dental_arch_extension)}>
                <S3.FormControlsColumn>
                  {studyInfo.SI04_01 && (
                    <p>
                      {t(IK.maxilla)} : {getSummaryLabel(studyInfo, "SI04_01", t)}
                      {studyInfo?.SI04_01 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_01_01} mm`}
                    </p>
                  )}
                  {studyInfo.SI04_02 && (
                    <p>
                      {t(IK.mandible)} : {getSummaryLabel(studyInfo, "SI04_02", t)}
                      {studyInfo?.SI04_02 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_02_01} mm`}
                    </p>
                  )}
                </S3.FormControlsColumn>
              </SummaryTableLi>
            )}

            {/* 전후방관계(A-P) */}
            {config.AP && (
              <SummaryTableLi title={t(IK.ap_relation)}>
                <S3.FormControlsColumn>
                  {studyInfo["SI05_01"] && (
                    <p>
                      {`${t(IK.ap_item1_title)} : ${getSummaryLabel(studyInfo, "SI05_01", t)}`}
                      {studyInfo["SI05_01"] === "2" && `(${studyInfo["SI05_01_2_01"]}mm)`}
                      {studyInfo["SI05_01"] === "3" && `(${studyInfo["SI05_01_3_01"]}mm)`}
                    </p>
                  )}
                  {studyInfo["SI05_05"] === "1" ? (
                    <p>{t(IK.ap_item2_title)}</p>
                  ) : (
                    <div>
                      {studyInfo["SI05_02"] && <p>{getSummaryLabel(studyInfo, "SI05_02", t)}</p>}
                      {studyInfo["SI05_03"] && <p>{getSummaryLabel(studyInfo, "SI05_03", t)}</p>}
                    </div>
                  )}
                  {studyInfo["SI05_04"] && (
                    <div>
                      <p>
                        {t(IK.teeth_move_option)} : {getSummaryLabel(studyInfo, "SI05_04", t)}
                      </p>
                    </div>
                  )}
                </S3.FormControlsColumn>
              </SummaryTableLi>
            )}

            {/* 수평피개(Overjet) */}
            {config.Overjet && (
              <SummaryTableLi title={t(IK.overjet)}>
                <p>
                  {getSummaryLabel(studyInfo, "SI06", t)}
                  {studyInfo.SI06 === "3" && `(${studyInfo.SI06_3_01}mm)`}
                </p>
              </SummaryTableLi>
            )}

            {/* 수직피개(Overbite) */}
            {config.Deepbite && (
              <SummaryTableLi title={t(IK.deepbite_title)}>
                <S3.FormControlsColumn>
                  {studyInfo?.SI07_01 && (
                    <p>
                      {t(IK.deepbite_item1)} : {getSummaryLabel(studyInfo, "SI07_01", t)}
                      {studyInfo.SI07_01 === "2" && `(${studyInfo.SI07_01_2_01}mm)`}
                      {studyInfo.SI07_01 === "3" && `(${studyInfo.SI07_01_3_01}mm)`}
                    </p>
                  )}
                  {studyInfo.SI07_02 && (
                    <p>
                      {t(IK.overbite_item)} : {getSummaryLabel(studyInfo, "SI07_02", t)}
                      {studyInfo.SI07_02 === "3" && `(${studyInfo.SI07_02_3_01}mm)`}
                    </p>
                  )}
                  {studyInfo.SI07_03 && (
                    <p>
                      {t(IK.deepbite_item3)}
                      {studyInfo["SI07_03"] < 5 ? (
                        <>
                          {`: ${getSummaryLabel(studyInfo, "SI07_03", t)}`}
                          {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("1") && `(${t(IK.maxilla)})`}
                          {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("2") && `(${t(IK.mandible)})`}
                        </>
                      ) : studyInfo?.SI07_03_5_01 ? (
                        `: ${studyInfo.SI07_03_5_01}(${getSummaryLabel(studyInfo, "SI07_03", t)})`
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                  {studyInfo.SI07_04 && (
                    <p>
                      {t(IK.deepbite_item4)}
                      {studyInfo["SI07_04"] < 5 ? (
                        <>
                          {`: ${getSummaryLabel(studyInfo, "SI07_04", t)}`}
                          {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("1") && `(${t(IK.maxilla)})`}
                          {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("2") && `(${t(IK.mandible)})`}
                        </>
                      ) : studyInfo?.SI07_04_5_01 ? (
                        `: ${studyInfo.SI07_04_5_01}(${getSummaryLabel(studyInfo, "SI07_04", t)})`
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </S3.FormControlsColumn>
              </SummaryTableLi>
            )}

            {/* 교합이개장치(Bite Ramps) */}
            {config.Biteramp && studyInfo?.SI10 && (
              <SummaryTableLi title={t(IK.biteramp)}>
                <p>
                  {getSummaryLabel(studyInfo, "SI10", t)}
                  {studyInfo["SI10"] === "2" && `(${getSummaryLabel(studyInfo, "SI10_2_01", t)}${studyInfo["SI10_2_01"] === "1" ? " : " + getSummaryLabel(studyInfo, "SI10_2_01_1_01", t) : ""})`}
                </p>
              </SummaryTableLi>
            )}

            {/* 정중선(Midline) */}
            {config.Midline && (
              <SummaryTableLi title={t(IK.midline)}>
                <S3.FormControlsColumn>
                  <p>
                    {getSummaryLabel(studyInfo, "SI08", t)} {studyInfo.SI08_3_02 ? `: ${getSummaryLabel(studyInfo, "SI08_3_02", t)}` : ""}
                  </p>
                  {studyInfo["SI08"] === "3" && studyInfo["SI08_3_02"] === "3" && (
                    <>
                      <p> {studyInfo["SI08_3_01"].split(",").includes("1") && `- ${t(IK.maxilla)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_1_01"]}mm)`}</p>
                      <p>{studyInfo["SI08_3_01"].split(",").includes("2") && `- ${t(IK.maxilla)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_2_01"]}mm)`}</p>
                      <p> {studyInfo["SI08_3_01"].split(",").includes("3") && `- ${t(IK.mandible)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_3_01"]}mm)`}</p>
                      <p>{studyInfo["SI08_3_01"].split(",").includes("4") && `- ${t(IK.mandible)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_4_01"]}mm)`}</p>
                    </>
                  )}
                </S3.FormControlsColumn>
              </SummaryTableLi>
            )}

            {/* Spacing */}
            {config.Spacing && (
              <SummaryTableLi title="Spacing">
                <p>{getSummaryLabel(studyInfo, "SI09_01", t)}</p>
              </SummaryTableLi>
            )}

            {/* Crowding */}
            {config.Crowding && (
              <SummaryTableLi title="Crowding">
                <S3.FormControlsFlex>
                  <S.CardColWd $full>
                    <S3.FormControlsColumn>
                      <FormControlsBox title={`${t(IK.maxilla)} ${t(IK.crowding_item1_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_01", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item2_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_02", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item3_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_03", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item4_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_04", t)}</FormControlsBox>
                    </S3.FormControlsColumn>
                  </S.CardColWd>

                  <S.CardColWd $full>
                    <S3.FormControlsColumn>
                      <FormControlsBox title={`${t(IK.mandible)} ${t(IK.crowding_item5_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_01", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item6_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_02", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item7_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_03", t)}</FormControlsBox>

                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item8_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_04", t)}</FormControlsBox>
                    </S3.FormControlsColumn>
                  </S.CardColWd>
                </S3.FormControlsFlex>
              </SummaryTableLi>
            )}

            {/* 구치부 반대교합(Crossbite) */}
            {config.Crossbite && (
              <SummaryTableLi title={t(IK.posterior_crossbite)}>
                <p>{getSummaryLabel(studyInfo, "SI11", t)}</p>
              </SummaryTableLi>
            )}

            {/* 영구치예측 */}
            {config.PermanentTooth && (
              <SummaryTableLi title={t(IK.prmanent_tooth)}>
                {studyInfo["SI14_01"] === "2" ? (
                  <S3.FormControlsColumn>
                    <TeethSummaryBox label="permanent_predict" studyInfo={studyInfo} config={config?.PermanentTooth.permanent_tooth_item1_title?.permanent_tooth_item1_2} code="SI14_01" />
                    <p>
                      {t(IK.prmanent_tooth_item2_title)}
                      {!!studyInfo["SI14_03"] ? `: ${studyInfo["SI14_03"] + t(IK.level)}` : `: ${t(IK.not_applicable)}`}
                    </p>
                  </S3.FormControlsColumn>
                ) : (
                  <p>{t(IK.not_applicable)}</p>
                )}
              </SummaryTableLi>
            )}

            {/* 특별지시사항 */}
            {config.Instructions && (
              <SummaryTableLi title={t(IK.special_instructions)} printhide>
                {isEditSpecialInstruction ? (
                  <S3.FormControlsColumn>
                    <S3.FormControls as="textarea" $textarea $full defaultValue={studyInfo?.SI13} onChange={(e) => setSpecialInstruction(e.target.value)} />
                    <S5.ButtonFlex $small>
                      <S5.StyledSmallButton as="button" type="button" onClick={editSpecialInstruction}>
                        {t(IK.registration)}
                      </S5.StyledSmallButton>
                      <S5.StyledSmallButton $secondary as="button" type="button" onClick={() => setIsEditSpecialInstruction(false)}>
                        {t(IK.cancel)}
                      </S5.StyledSmallButton>
                    </S5.ButtonFlex>
                  </S3.FormControlsColumn>
                ) : (
                  <S3.FormControlsFlex>
                    {studyInfo?.SI13 ? <S6.TextDiv $textpreline>{studyInfo?.SI13}</S6.TextDiv> : <></>}
                    {studyAuth.studySpecialFix && (
                      <S5.StyledSmallButton as="button" type="button" onClick={() => setIsEditSpecialInstruction(true)}>
                        {t(IK.modify)}
                      </S5.StyledSmallButton>
                    )}
                  </S3.FormControlsFlex>
                )}
              </SummaryTableLi>
            )}

            {/* Every10 (치아이동촉진기) */}
            {config.Every10 && (
              <SummaryTableLi title={t(IK.every10)} printhide>
                {isEditEvery10 ? (
                  <S3.FormControlsColumn>
                    <S4.SelectionItemList>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" name="studyInfoSI15" value="YES" id="YES" defaultChecked={studyInfo.SI15 === "1"} onChange={(e) => setEvery10(e.target.value)} />
                          <S4.SelectionItemSpan>{t(IK.yes)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>

                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" name="studyInfoSI15" value="NO" id="NO" defaultChecked={studyInfo.SI15 === "2"} onChange={(e) => setEvery10(e.target.value)} />
                          <S4.SelectionItemSpan>{t(IK.no)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    </S4.SelectionItemList>
                    <S5.ButtonFlex $small>
                      <S5.StyledSmallButton as="button" type="button" onClick={editEvery10}>
                        {t(IK.registration)}
                      </S5.StyledSmallButton>
                      <S5.StyledSmallButton $secondary as="button" type="button" onClick={() => setIsEditEvery10(false)}>
                        {t(IK.cancel)}
                      </S5.StyledSmallButton>
                    </S5.ButtonFlex>
                  </S3.FormControlsColumn>
                ) : (
                  <S3.FormControlsFlex>
                    <p>{getSummaryLabel(studyInfo, "SI15", t)}</p>
                    {studyAuth.studyEvery10fix && (
                      <S5.StyledSmallButton as="button" type="button" onClick={() => setIsEditEvery10(true)}>
                        {t(IK.modify)}
                      </S5.StyledSmallButton>
                    )}
                  </S3.FormControlsFlex>
                )}
              </SummaryTableLi>
            )}

            {/* 구외 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.extraoral)} imagesArr={studyExtroImages} file={studyInfo} printshow />

            {/* 구내 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.intraoral)} imagesArr={studyIntroImages} file={studyInfo} printshow />

            {/* 방사선 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.radiograph)} imagesArr={studyRadiographs} file={studyInfo} isRadiation printshow />
          </S2.StudySummary>

          {/* 구외 사진 */}
          <DropImageBox text={t(IK.extraoral)} imagesArr={studyExtroImages} file={studyInfo} handleImageViewer={handleImageViewer} />

          {/* 구내 사진 */}
          <DropImageBox text={t(IK.intraoral)} imagesArr={studyIntroImages} file={studyInfo} handleImageViewer={handleImageViewer} />

          {/* 방사선 사진 */}
          <DropImageBox text={t(IK.radiograph)} imagesArr={studyRadiographs} file={studyInfo} isRadiation handleImageViewer={handleImageViewer} />

          <S6.ContentBox>
            <S2.StudySummary>
              {/* 스캔파일 등록 */}
              <SummaryTableLi title={t(IK.scan_file_registration)}>
                {studyInfo?.scan?.length > 0 ? (
                  <S5.ButtonFlex $small>
                    {studyInfo?.scan?.map((item, index) => {
                      return (
                        <S5.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName, t)}>
                          {item.orgFileName}
                        </S5.StyledSmallButton>
                      );
                    })}
                  </S5.ButtonFlex>
                ) : (
                  <p>
                    {/** 석고모델 하위옵션이 있을때만 표출 */}
                    {t(IK.send_model)}
                    {studyInfo?.SIPlasterModel && `: ${getSummaryLabel(studyInfo, "SIPlasterModel", t)}`} {studyInfo?.SIPhoneNumber && `(${studyInfo?.SIPhoneNumber})`}
                  </p>
                )}
              </SummaryTableLi>
            </S2.StudySummary>
          </S6.ContentBox>

          <CardTail line>
            <S5.ButtonLtBox>
              <S5.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
                {t(IK.list)}
              </S5.StyledButton>
              <S5.StyledButton as="button" type="button" $primary onClick={handlePrint}>
                {t(IK.print)}
              </S5.StyledButton>
              {studyAuth.studySuperAuth && (
                <S5.StyledButton as="button" type="button" $primary onClick={deleteStudy}>
                  {t(IK.delete)}
                </S5.StyledButton>
              )}
            </S5.ButtonLtBox>
          </CardTail>
        </>
      )}
      {imageViewer.isView && (
        <S6.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S6.ImageViewerBox>
      )}
    </>
  );
};

export default StudyViewSummary;

export const SummaryTableLi = ({ children, title, printhide, printshow }) => {
  return (
    <S2.StudySummaryItem $printshow={printshow}>
      <dl>
        <dt>
          <h2>{title}</h2>
        </dt>
        <dd className={printhide ? "printhide" : ""}>{children}</dd>
      </dl>
    </S2.StudySummaryItem>
  );
};
