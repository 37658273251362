import profImg from "../assets/images/common/no_profile.png";

const ProfileImage = ({ profile }) => {
  function handleProfileImageError(e) {
    e.target.src = profImg; // 이미지 로딩에 실패한 경우 기본 이미지로 대체
  }

  return <img src={profile || profImg} alt="Profile_image" onError={handleProfileImageError} />;
};

export default ProfileImage;
