import Notiflix from "notiflix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import SetupInterceptors from "../../apis/SetupInterceptors";
import * as S from "../../styles/Common";
import i18n from "../../utils/i18n";
import { IK } from "../../utils/i18n/keyStore";
import SideBar from "./sideBar/SideBar";
import Footer from "./sidetail/Footer";

const Layout = () => {
  const isLogin = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    SetupInterceptors(navigate, t);
  }, [navigate, t]);

  /**미로그인 유저 접근 금지 */
  if (!isLogin) {
    i18n.changeLanguage(localStorage.getItem("langInfo"));
    return <Navigate to="/" {...Notiflix.Report.failure(t(IK.login_expiration_message1), t(IK.login_expiration_message2), t(IK.confirm))} />;
  }

  return (
    <>
      <SideBar />
      <S.Container>
        <Outlet />
      </S.Container>
      <Footer />
    </>
  );
};

export default Layout;
