import Skeleton from "react-loading-skeleton";
import * as S from "../../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../style/Study.style";

const StudyViewCardSkeleton = () => {
  return (
    <>
      <S2.StudyViewTab>
        <Skeleton height={55} />
      </S2.StudyViewTab>
      <S.CardInner>
        <S2.PrintWrapper>
          <Skeleton height={1000} />
        </S2.PrintWrapper>
      </S.CardInner>
    </>
  );
};

export default StudyViewCardSkeleton;
