import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useTabCount } from "../../apis/studyApi";
import { IK } from "../../utils/i18n/keyStore";
import { changeParam } from "../../utils/searchParams";
import * as S from "./style/Study.style";

const StudyTabs = ({ setSearchCondition, searchCondition, activeTab, setActiveTab }) => {
  const { data, isLoading } = useTabCount(changeParam(searchCondition));
  const { t } = useTranslation(["translation"]);
  const countData = data?.data;

  /**탭 필터 기능 */
  const filterTab = (detail) => {
    setSearchCondition((prev) => {
      return { ...prev, detail, page: 1 };
    });
    setActiveTab(detail);
  };

  const tabs = [
    { title: t(IK.total), count: countData?.totalCount, detail: "total" },
    { title: t(IK.tab_writing_study), count: countData?.writingCount, detail: "writing" },
    { title: t(IK.tab_reject_study), count: countData?.rejectCount, detail: "reject" },
    { title: t(IK.tab_submit_study), count: countData?.submitCount, detail: "submit" },
    { title: t(IK.tab_receipt_study), count: countData?.receiptCount, detail: "receipt" },
    { title: t(IK.tab_design_study), count: countData?.designCount, detail: "design" },
    { title: t(IK.tab_review_study), count: countData?.reviewCount, detail: "review" },
    { title: t(IK.tab_approved_seraview), count: countData?.approvalCount, detail: "approval" },
    { title: t(IK.tab_progress_aligner), count: countData?.productionCount, detail: "production" },
    { title: t(IK.tab_transit_device), count: countData?.deliveryCount, detail: "delivery" },
    { title: t(IK.tab_end_of_treatment), count: countData?.endCount, detail: "end" },
  ];

  return (
    <S.StudyTab>
      {tabs.map((tab) => (
        <li key={tab.title}>
          <S.StudyTabButton onClick={() => filterTab(tab.detail)} $active={activeTab === tab.detail}>
            <S.StudyTabTxt>{tab.title}</S.StudyTabTxt>
            {isLoading ? <Skeleton width={30} height={20} /> : <S.StudyTabCount>{tab.count}</S.StudyTabCount>}
          </S.StudyTabButton>
        </li>
      ))}
    </S.StudyTab>
  );
};

export default StudyTabs;
