import { yupResolver } from "@hookform/resolvers/yup";
import Notiflix from "notiflix";
import React, { useEffect } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useEditDoctorAddress } from "../../apis/doctorApi";
import * as S from "../../components/Modal/style/Modal.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { ErrorMessage } from "../../elements/Message/MessageText";
import { enterKeyDown } from "../../utils/enterKeydown";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";

const AddressEditModal = ({ addressModal, handleClose, countryCode, addressData, setSelecteShip, setSelecteBill, sameIdAddress }) => {
  const { t } = useTranslation(["translation"]);

  /**유효성 검사 */
  const formSchema = Yup.object({
    addressName: Yup.string().required(t(IK.clinic_name_required)),
    postalCode: Yup.string().required(t(IK.postcode_required)),
    address: Yup.string().required(t(IK.address_required)),
  });

  const editAddressMutaion = useEditDoctorAddress();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  /**수정하기 */
  const onSubmit = (value) => {
    editAddressMutaion.mutate(
      { value, id: addressData.addressId },
      {
        onSuccess: () => {
          handleClose();
          if (sameIdAddress) {
            setSelecteShip({ ...addressData, ...value });
            setSelecteBill({ ...addressData, ...value });
          } else {
            addressModal.type === "ship" ? setSelecteShip({ ...addressData, ...value }) : setSelecteBill({ ...addressData, ...value });
          }
          Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm));
        },
      }
    );
  };

  useEffect(() => {
    setValue("addressName", addressData.addressName);
    setValue("postalCode", addressData.postalCode);
    setValue("address", addressData.address);
    setValue("addressDetail", addressData.addressDetail);
  }, [addressData, setValue]);

  return (
    <ModalLayout open={addressModal.isOpen} header={t(IK.edit_address)} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={enterKeyDown}>
        <S2.FormControlsColumn>
          <FormControlsBox required regular title={t(IK.clinic_name)}>
            <S2.FormControls placeholder={t(IK.clinic_name)} {...register("addressName")} />
            <ErrorMessage errors={errors} name="addressName" />
          </FormControlsBox>
          <FormControlsBox required regular title={t(IK.clinic_address)}>
            <S2.FormControlsColumn>
              <S2.FormControlsFlex>
                <S2.FormControls placeholder={t(IK.postcode)} {...register("postalCode")} />
                <AddressPopupBtn countryCode={countryCode} setValue={setValue} basicAddressId={"address"} zoneCodeId={"postalCode"} />
              </S2.FormControlsFlex>
              <ErrorMessage errors={errors} name="postalCode" />
              <S2.FormControls placeholder={t(IK.address)} {...register("address")} />
              <ErrorMessage errors={errors} name="address" />
              <S2.FormControls placeholder={t(IK.detailed_address)} {...register("addressDetail")} />
              <ErrorMessage errors={errors} name="addressDetail" />
            </S2.FormControlsColumn>
          </FormControlsBox>
        </S2.FormControlsColumn>

        <S.ModalBoxBtn>
          <S3.ButtonFlex>
            <S3.StyledButton as="button" type="button" onClick={handleClose}>
              {t(IK.cancel)}
            </S3.StyledButton>
            <S3.StyledButton as="button" type="submit" $primary>
              {t(IK.modify)}
            </S3.StyledButton>
          </S3.ButtonFlex>
        </S.ModalBoxBtn>
      </form>
    </ModalLayout>
  );
};

export default AddressEditModal;

export const AddressPopupBtn = ({ countryCode, setValue, zoneCodeId, basicAddressId }) => {
  const open = useDaumPostcodePopup();
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    setValue(zoneCodeId, data.zonecode);
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(basicAddressId, fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    countryCode === "01" && (
      <S2.FormControlsBtn as="button" type="button" onClick={handleClick}>
        우편번호 검색
      </S2.FormControlsBtn>
    )
  );
};
