import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useCreateCoupon } from "../apis/couponApi";
import { useGetDoctorSearch } from "../apis/doctorApi";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../elements/DatePicker";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage } from "../elements/Message/MessageText";
import * as S4 from "../elements/SelectionControls/style/SelectionControls.style";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";

const CouponAdd = () => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const createCouponMutation = useCreateCoupon();

  const formSchema = Yup.object({
    couponName: Yup.string().required(t(IK.coupon_name_required)),
    amount: Yup.string().required(t(IK.coupon_amount_required)).max(10, t(IK.coupon_amount_max)),
    startDate: Yup.string().when("expDate", {
      is: (val) => !val,
      then: (val) => Yup.string().required(t(IK.coupon_start_required)),
      otherwise: (val) => Yup.string(),
    }),
    endDate: Yup.string().when("expDate", {
      is: (val) => !val,
      then: (val) => Yup.string().required(t(IK.coupon_end_required)),
      otherwise: (val) => Yup.string(),
    }),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const searchs = watch();

  /**키워드 검색 */
  const { data } = useGetDoctorSearch(searchs.keyword);

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("startDate", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("startDate", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("endDate", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("endDate", "");
    }
  };

  /**제한없음 설정 */
  const handleExpDate = () => {
    setValue("startDate", "");
    setValue("endDate", "");
  };

  /**발급대상 리스트 */
  const [applyList, setApplyList] = useState([]);

  /**발급대상 선택 */
  const applyTarget = (value) => {
    //이미 등록된 아이디인지 확인
    const isDuplicate = applyList.some((obj) => obj.doctorId === value.doctorId);

    if (!isDuplicate) {
      setApplyList((prev) => [...prev, value]);
      setValue("keyword", "");
    }
  };

  /**발급대상 삭제 */
  const handleDeleteId = (id) => {
    const updateList = applyList.filter((x) => x.doctorId !== id);
    setApplyList(updateList);
  };

  /**쿠폰 발급하기 */
  const onAddCoupon = (data) => {
    const { keyword, expDate, ...rest } = data;

    if (data.startDate > data.endDate) {
      //종료일이 시작일 보다 빠른 경우 경고
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    if (applyList.length === 0) return Notiflix.Notify.failure(t(IK.coupon_for_required));
    createCouponMutation.mutate(
      { ...rest, targetDoctorIdList: applyList.map((item) => item.doctorId) },
      {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.coupon_success), "", t(IK.confirm), () => {
            navigate("/couponList");
          });
        },
        onError: () => Notiflix.Report.failure(t(IK.coupon_fail), "", t(IK.confirm), () => {}),
      }
    );
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.add_coupon)} txt />
          <form onSubmit={handleSubmit(onAddCoupon)} onKeyDown={enterKeyDown}>
            <S2.FormControlsContainer>
              <FormControlsBox required title={t(IK.coupon_name)}>
                <S2.FormControls placeholder={t(IK.coupon_name_limit)} maxLength={100} {...register("couponName")} />
                <ErrorMessage errors={errors} name="couponName" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.pay_discount)}>
                <S2.FormControls type="number" {...register("amount")} />
                <ErrorMessage errors={errors} name="amount" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.coupon_date_limit)}>
                <S2.FormControlsFlex>
                  <div>
                    <DatePicker
                      disabled={searchs.expDate}
                      handleDataChange={handleStartDate}
                      placeholderText={t(IK.create_date)}
                      selected={searchs.startDate && new Date(searchs.startDate)}
                      formControls
                    />
                    {!searchs.expDate && !searchs.startDate && <ErrorMessage errors={errors} name="startDate" />}
                  </div>

                  <div>
                    <DatePicker
                      disabled={searchs.expDate}
                      handleDataChange={handleEndDate}
                      placeholderText={t(IK.event_end_date)}
                      selected={searchs.endDate && new Date(searchs.endDate)}
                      formControls
                    />
                    {!searchs.expDate && !searchs.endDate && <ErrorMessage errors={errors} name="endDate" />}
                  </div>
                  <S4.SelectionItem>
                    <S4.SelectionItemLabel>
                      <S4.SelectionItemInput $checkbox type="checkbox" id="expDate" {...register("expDate")} onClick={handleExpDate} />
                      <S4.SelectionItemSpan>{t(IK.no_limit)}</S4.SelectionItemSpan>
                    </S4.SelectionItemLabel>
                  </S4.SelectionItem>
                </S2.FormControlsFlex>
              </FormControlsBox>

              <FormControlsBox required title={t(IK.coupon_for)}>
                <S2.FormSelectListBox>
                  <S2.FormControls placeholder={t(IK.coupon_for_placeholder)} {...register("keyword")} />
                  {data ? (
                    <S2.FormSelectList>
                      {data?.map((suggestion, index) => (
                        <li key={index} onClick={() => applyTarget(suggestion)}>
                          <p>
                            {suggestion.loginId}, {suggestion.addressName}
                          </p>
                          <p>
                            {suggestion.doctorName} {suggestion?.doctorKoreanName ? `(${suggestion?.doctorKoreanName})` : ""}{" "}
                          </p>
                        </li>
                      ))}
                    </S2.FormSelectList>
                  ) : (
                    ""
                  )}
                </S2.FormSelectListBox>
              </FormControlsBox>
              <FormControlsBox required title={`${t(IK.coupon_for)} ${t(IK.list)}`}>
                {applyList.length > 0 && (
                  <S2.FormFileList>
                    {applyList?.map((apply) => (
                      <li key={apply.doctorId}>
                        <div>
                          <p>{apply.addressName}</p>
                          <p>
                            {apply.doctorName} {apply?.doctorKoreanName ? `(${apply?.doctorKoreanName})` : ""}
                          </p>
                          <p>{apply.loginId}</p>
                        </div>
                        <button type="button" onClick={() => handleDeleteId(apply.doctorId)}>
                          <i className="ri-close-line"></i>
                        </button>
                      </li>
                    ))}
                  </S2.FormFileList>
                )}
              </FormControlsBox>
            </S2.FormControlsContainer>

            <CardTail line>
              <S3.ButtonLtBox>
                <S3.StyledButton to="/couponList">{t(IK.cancel)}</S3.StyledButton>
                <S3.StyledButton $primary type="submit" as="button">
                  {t(IK.add_coupon)}
                </S3.StyledButton>
              </S3.ButtonLtBox>
            </CardTail>
          </form>
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default CouponAdd;
