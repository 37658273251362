import { useForm } from "react-hook-form";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

const ClinicalSearchFilter = ({ setSearchCondition, searchCondition, t, isAllCountry }) => {
  /**검색 forrm 설정 */
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      country_list: searchCondition?.country_list?.split(";") || [],
    },
  });

  const searchs = watch();

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition((prev) => ({ ...prev, ...data, page: 1, country_list: data?.country_list?.join(";") || "" }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      dentistName: "",
      patientName: "",
      submissionStatus: "",
      country_list: [],
    });
    setSearchCondition({ page: 1 });
  };

  return (
    <S.CardInner>
      <form onSubmit={handleSubmit(onSubmitSearch)}>
        <S2.TableType2>
          <colgroup>
            <col width="200px"></col>
            <col width="*"></col>
            <col width="200px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            {isAllCountry && (
              <tr>
                <th scope="row">{t(IK.select_country)}</th>
                <td>
                  <CountryBtn setValue={setValue} fieldName="country_list" watch={searchs?.country_list} />
                </td>
                <th scope="row">{`${t(IK.country)} ${t(IK.list)}`}</th>
                <td>{searchs?.country_list?.length > 0 && <SelectedCountryList watch={searchs.country_list} fieldName="country_list" setValue={setValue} t={t} />}</td>
              </tr>
            )}
            <tr>
              <th scope="row">{t(IK.clinic_name)}</th>
              <td>
                <S3.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.dentistName} {...register("dentistName")} />
              </td>
              <th scope="row">{t(IK.patient_name)}</th>
              <td>
                <S3.FormControls placeholder={t(IK.search_patient_input)} defaultValue={searchCondition?.patientName} {...register("patientName")} />
              </td>
            </tr>
            <tr>
              <th scope="row">{t(IK.clinical_is_submit)}</th>
              <td colSpan={3}>
                <S3.FormControls {...register("submissionStatus")} defaultValue={searchCondition?.submissionStatus} as="select" $select>
                  <option value="">{t(IK.clinical_is_submit)}</option>
                  <option value="REGISTERED">{t(IK.clinical_submit)}</option>
                  <option value="NOT_REGISTERED">{t(IK.clinical_not_submit)}</option>
                </S3.FormControls>
              </td>
            </tr>
          </tbody>
        </S2.TableType2>
        <S4.ButtonCtBox>
          <S4.StyledButton type="button" onClick={() => onReset()} as="button">
            <i className="ri-refresh-line"></i> {t(IK.reset)}
          </S4.StyledButton>
          <S4.StyledButton $primary type="submit" as="button">
            <i className="ri-search-2-line"></i> {t(IK.search)}
          </S4.StyledButton>
        </S4.ButtonCtBox>
      </form>
    </S.CardInner>
  );
};

export default ClinicalSearchFilter;
