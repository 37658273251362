import * as S from "./style/Form.style";

export const FormControlsBox = ({ title, children, required, regular }) => {
    return (
        <S.FormControlsBox>
            <S.FormControlsTitle $required={required} $regular={regular}>
                <h3>{title}</h3>
            </S.FormControlsTitle>
            <S.FormControlsDiv>{children}</S.FormControlsDiv>
        </S.FormControlsBox>
    );
};