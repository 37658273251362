import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/**이벤트 게시판 리스트 조회 */
export const useGetEventList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["EventList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/event-boards", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: () => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**이벤트게시판 상세조회 */
export const useGetEventInfo = (eventBoardId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["EventInfo", eventBoardId],
    async () => {
      const { data } = await axios.get(`/api/admin/event-boards/${eventBoardId}`);
      return data;
    },
    {
      enabled: !!eventBoardId,
      onError: () => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 이벤트 글 작성*/
export const useAddEvent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      const response = await axios.post(`/api/admin/event-boards`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EventList");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.add_success), "", t(IK.confirm), () => navigate("/support/event"));
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 이벤트 글 삭제*/
export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (eventBoardId) => {
      const response = await axios.delete(`/api/admin/event-boards/${eventBoardId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EventList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/support/event"));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/** 이벤트 글 수정*/
export const useEditEvent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (payload) => {
      Notiflix.Loading.standard("");
      const response = await axios.put(`/api/admin/event-boards/${payload.eventBoardId}`, payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EventList");
        queryClient.invalidateQueries("EventInfo");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => navigate("/support/event"));
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm));
      },
    }
  );
};
