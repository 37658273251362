import Skeleton from "react-loading-skeleton";
import * as S2 from "../../elements/Form/style/Form.style";

const CliniCalGalleryInfoSkeleton = () => {
  return (
    <S2.FormControlsContainer>
      <FormSkeleton width={300} />
      <FormSkeleton width={400} />
      <FormSkeleton width={200} />
      <FormSkeleton height={200} />
      <FormSkeleton height={300} />
      <FormSkeleton width={400} />
    </S2.FormControlsContainer>
  );
};

export default CliniCalGalleryInfoSkeleton;

export const FormSkeleton = ({ width, height = 40 }) => {
  return (
    <S2.FormControlsBox>
      <S2.FormControlsTitle>
        <h3>
          <Skeleton width={100} height={40} />
        </h3>
      </S2.FormControlsTitle>
      <S2.FormControlsDiv>
        <Skeleton width={width} height={height} />
      </S2.FormControlsDiv>
    </S2.FormControlsBox>
  );
};
