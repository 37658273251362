import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteAlarm, useReadAlarm } from "../../apis/alarmApi";
import { EmptyCard } from "../../elements/EmptyComponent/EmptyCard";
import * as S2 from "../../elements/SelectionControls/style/SelectionControls.style";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/AlarmPopup.style";

const AlarmList = ({ alarmList, handleCloseAlarmPopup, scrollRef, viewRef, setSelectedAlarm, selectedAlarm }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  const deleteAlarmMutation = useDeleteAlarm();
  const readAlarmMutation = useReadAlarm();
  const [deletedAlarms, setDeletedAlarms] = useState([]);

  /**해당 알림으로 이동 */
  const handleOnClickAlarm = (data) => {
    const { alarmId, studyId, alarmCode } = data;
    readAlarmMutation.mutate(alarmId, {
      onSuccess: () => {
        handleCloseAlarmPopup();
        if (alarmCode === "AC99") return navigate(`/doctorList`);
        if (alarmCode === "AC81") return navigate("/billList");
        return navigate(`/studyList/${studyId}`);
      },
    });
  };

  /**단건 알림 삭제 */
  const handleClickDelete = (alarmId) => {
    //화면에서 삭제와 동시에 알림 제거
    setDeletedAlarms((prevDeletedAlarms) => [...prevDeletedAlarms, alarmId]);
    deleteAlarmMutation.mutate(alarmId, {
      onSuccess: () => {
        setSelectedAlarm([]);
      },
    });
  };

  /**보이는 알람 전체 체크박스 */
  const handleSelectAll = () => {
    if (selectedAlarm.length === alarmList.length) {
      setSelectedAlarm([]);
    } else {
      setSelectedAlarm(alarmList.map((alarm) => alarm.alarmId));
    }
  };

  /**알람 체크박스*/
  const handleSelectAlarm = (alarmId) => {
    setSelectedAlarm((prevSelectedAlarm) => {
      if (prevSelectedAlarm.includes(alarmId)) {
        return prevSelectedAlarm.filter((selectedAlarm) => selectedAlarm !== alarmId);
      }
      return [...prevSelectedAlarm, alarmId];
    });
  };

  function renderNotificationsByDate(notifications) {
    const notificationsByDate = notifications.reduce((acc, notification) => {
      const createdDate = new Date(notification.createdDate);
      const dateKey = createdDate.toDateString();
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(notification);
      return acc;
    }, {});

    const renderedNotifications = Object.entries(notificationsByDate).map(([date, notifications]) => {
      const formattedDate = new Date(date).toLocaleString("ko-KR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });

      const notificationItems = notifications.map((notification) => {
        const createdTime = dayjs(notification.createdDate).format("A h:mm:ss");
        if (deletedAlarms.includes(notification.alarmId)) {
          return null;
        }

        return (
          <S.AlarmPopListItem key={notification?.alarmId}>
            <S.AlarmPopListItemIco $active={notification.readYn === "Y"}>
              <i className="ri-notification-3-fill"></i>
            </S.AlarmPopListItemIco>

            <S.AlarmPopListItemBox>
              <S.AlarmPopListItemTxt onClick={() => handleOnClickAlarm(notification)}>
                <p>{createdTime}</p>
                {notification.alarmCode === "AC99" ? (
                  <>
                    <p>{t(IK.AC99)} </p>
                    {notification?.clinicName && (
                      <p>
                        {notification?.clinicName} - {notification?.doctorName}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p>
                      <strong>{t(notification.alarmCode)}</strong>
                    </p>
                    {notification.patientName ? (
                      <>
                        <p>
                          <span>
                            {notification.patientName} {notification?.packages && `[${t(notification.packages)}]`}
                          </span>
                        </p>
                        <p>{notification.doctorName}</p>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </S.AlarmPopListItemTxt>

              <S.AlarmPopListItemControl>
                <S2.SelectionItem $labelhidden>
                  <S2.SelectionItemLabel>
                    <S2.SelectionItemInput $checkbox type="checkbox" checked={selectedAlarm?.includes(notification?.alarmId)} onChange={() => handleSelectAlarm(notification?.alarmId)} />
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>

                <S.AlarmPopListItemClose type="button" onClick={() => handleClickDelete(notification.alarmId)}>
                  <i className="ri-delete-bin-fill"></i>
                </S.AlarmPopListItemClose>
              </S.AlarmPopListItemControl>
            </S.AlarmPopListItemBox>
          </S.AlarmPopListItem>
        );
      });

      return (
        <dl key={date}>
          <dt>{formattedDate}</dt>
          <dd>
            <S.AlarmPopList>{notificationItems}</S.AlarmPopList>
          </dd>
        </dl>
      );
    });

    return (
      <>
        <S.AlarmPopAllSelect>
          <S2.SelectionItem $Small>
            <S2.SelectionItemLabel>
              <S2.SelectionItemInput $checkbox type="checkbox" onChange={handleSelectAll} checked={selectedAlarm?.length === alarmList?.length && alarmList?.length > 0} />
              <S2.SelectionItemSpan>{t(IK.select_all)}</S2.SelectionItemSpan>
            </S2.SelectionItemLabel>
          </S2.SelectionItem>
        </S.AlarmPopAllSelect>
        <S.AlarmPopBox ref={scrollRef}>
          {renderedNotifications}
          <S.AlarmScrollDetection ref={viewRef} />
        </S.AlarmPopBox>
      </>
    );
  }

  return <>{alarmList?.length === 0 ? <EmptyCard /> : renderNotificationsByDate(alarmList)}</>;
};

export default AlarmList;
