import Skeleton from "react-loading-skeleton";
import * as S2 from "../components/board/style/BoardList.style";
import { CardHead } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
const DataTableSkeleton = ({ tableCol, tableHead }) => {
  const arr = new Array(10).fill(0);
  return (
    <S.CardCol>
      <S.CardInner>
        <CardHead>
          <Skeleton width={100} height={20} />
          <Skeleton width={109} height={45} />
        </CardHead>
        <S2.BoardTable>
          <colgroup>
            {tableCol?.map((col, i) => (
              <col key={i} width={col || 100} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {tableHead?.map((head, i) => (
                <th key={i}>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {arr.map((x, i) => (
              <tr key={i}>
                {tableHead?.map((head, i) => (
                  <td key={i}>
                    <Skeleton height={24} width={head.length < 8 ? head.length * 15 : head.length * 5 || 100} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </S2.BoardTable>
      </S.CardInner>
    </S.CardCol>
  );
};

export default DataTableSkeleton;
