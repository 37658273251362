import { IK } from "./i18n/keyStore";

/**원화 세자리씩 끊어서 표기 */
export function formatCurrency(number) {
  return number ? `${number.toLocaleString("ko-KR")}` : 0;
}

export const payMethodStatus = {
  card: IK.card, //신용카드
  trans: IK.trans, //실시간 계좌이체
  vbank: IK.vbank, //가상계좌, 무통장
  prepaid: IK.prepayment, //선수금
};

/**결제 리스트 상태코드 */
export const payStatus = {
  0: IK.upcoming_billing, // 결제 예정, 어드민에서만 쓰는 상태값
  5: IK.all_except, //잔체 결제 제외
  10: IK.request_billing, // 결제 요청
  20: IK.partial_orders, // 일부 주문
  30: IK.partial_pay, // 일부 결제
  40: IK.total_pay_complete, // 전체 결제 완료
};

/**결제 상태 */
export const paymentStatus = {
  prepare: IK.payment_status_prepare, //결제 준비
  ready: IK.payment_status_ready, //가상계좌 발급
  paid: IK.payment_status_paid, //결제 완료
  cancel: IK.payment_status_cancel, //모듈 취소
  failed: IK.payment_status_failed, //결제 실패
  refund: IK.payment_status_refund, //결제 환불
  prepaid: IK.payment_status_prepaid, //선수금 결제
  cancelled: IK.payment_status_cancelled, //결제 취소
};

/**지불 상세 상태코드 표기 */
export const billsStatus = {
  0: IK.upcoming_billing, // 결제 예정
  10: IK.outstanding, // 미결제 (결제 요청)
  15: IK.payment_wait, // 입금 대기
  20: IK.payment_status_paid, // 결제 완료
  60: IK.payment_status_cancelled, //결제취소
  70: IK.full_discount, //전액 할인
  80: IK.exclude, // 제외
  90: IK.direct_pay, // 직접 결제
  99: IK.carryover, // 이월
};
