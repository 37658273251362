import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetDeliveryKey, usePostDeliveryKey } from "../apis/supportApi";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";

//스타일 관련
import Skeleton from "react-loading-skeleton";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import * as S3 from "../elements/Form/style/Form.style";
import { TextErrorMessage } from "../elements/Message/MessageText";
import * as S2 from "../elements/Table/style/Table.style";

const DeliveryKey = () => {
  const { t } = useTranslation(["translation"]);

  /**배송키 조회 */
  const { data, isLoading } = useGetDeliveryKey();

  /**배송키 전송 */
  const deliveryMutation = usePostDeliveryKey();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**배송키 수정여부 감지 */
  const [keyInput, setKeyInput] = useState(true);

  /**배송키 입력시 수정 버튼 활성화 */
  const handleKeyInputChange = () => {
    setKeyInput(false);
  };

  /**배송키 등록 */
  const onSubmitKey = ({ key }) => {
    //배송키가 같으면 리턴
    if (key === `${data}`) return;
    deliveryMutation.mutate(key);
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <Skeleton height={150} />
          ) : (
            <form onSubmit={handleSubmit(onSubmitKey)} onKeyDown={enterKeyDown}>
              <S2.TableType2>
                <colgroup>
                  <col width="200px"></col>
                  <col width="*"></col>
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">Key</th>
                    <td>
                      <S3.FormControls
                        maxLength="50"
                        type="text"
                        defaultValue={data}
                        {...register("key", {
                          required: true,
                        })}
                        onKeyDown={handleKeyInputChange}
                      />
                      <TextErrorMessage errors={errors.key} message={t(IK.delivery_key_upload_error)} />
                    </td>
                  </tr>
                </tbody>
              </S2.TableType2>
              <S4.ButtonCtBox>
                <S4.StyledButton $primary as="button" disabled={keyInput}>
                  {t(IK.registration)}
                </S4.StyledButton>
              </S4.ButtonCtBox>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default DeliveryKey;
