import dayjs from "dayjs";
import Notiflix from "notiflix";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../../elements/DatePicker";
import * as S2 from "../../elements/Form/style/Form.style";
import * as S from "../../elements/Table/style/Table.style";
import { doctorGrade } from "../../utils/doctorUtils";
import { IK } from "../../utils/i18n/keyStore";

const DoctorFilter = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const searchs = watch();

  /**기간 설정 */
  const handleDateChange = (event) => {
    const gap = event.target.value;
    if (gap) {
      const createdDateFrom = new Date(dayjs().subtract(gap, "month").format("YYYY-MM-DD"));
      const createdDateTo = new Date();
      setValue("createdDateFrom", createdDateFrom.toISOString().split("T")[0]);
      setValue("createdDateTo", createdDateTo.toISOString().split("T")[0]);
    } else {
      setValue("createdDateFrom", "");
      setValue("createdDateTo", "");
      setSearchCondition((prev) => ({ ...prev, createdDateFrom: "", createdDateTo: "" }));
    }
  };

  /**가입 시작 날짜 설정 */
  const handlecreatedDateFrom = (date) => {
    if (date) {
      setValue("createdDateFrom", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("createdDateFrom", "");
    }
  };

  /**가입 종료 날짜 설정 */
  const handlecreatedDateTo = (date) => {
    if (date) {
      setValue("createdDateTo", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("createdDateTo", "");
    }
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    if ((data.createdDateFrom || data.createdDateTo) && !(data.createdDateFrom && data.createdDateTo)) {
      // data.createdDateFrom와 data.createdDateTo 중 하나는 값이 있고 다른 하나는 없는 경우
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }
    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      doctorName: "",
      clinicName: "",
      phoneNumber: "",
      createdDateFrom: "",
      createdDateTo: "",
      grade: "",
      status: "",
      video: "",
      marketing: "",
      doctorType: "",
      doctorClassification: "",
    });
    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;
    setSearchCondition({ page: 1 });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            {/* 치과명 */}
            <th scope="row">{t(IK.search_clinic)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.clinicName} {...register("clinicName")} />
            </td>
            {/* 의사명 */}
            <th scope="row">{t(IK.search_doctor)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_doctor_input)} defaultValue={searchCondition?.doctorName} {...register("doctorName")} />
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.search_phone)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_phone_input)} defaultValue={searchCondition?.phoneNumber} {...register("phoneNumber")} />
            </td>
            <th scope="row">{t(IK.search_doctor_apply)}</th>
            <td>
              <S2.FormControlsFlex>
                <S2.FormControls onChange={handleDateChange} as="select" $select id="dateControl">
                  <option value="">{t(IK.entire_date)}</option>
                  <option value={1}>{t(IK.month1)}</option>
                  <option value={3}>{t(IK.month3)}</option>
                  <option value={6}>{t(IK.month6)}</option>
                  <option value={12}>{t(IK.year1)}</option>
                </S2.FormControls>
                <DatePicker
                  handleDataChange={handlecreatedDateFrom}
                  selected={(searchs.createdDateFrom && new Date(searchs.createdDateFrom)) || (searchCondition.createdDateFrom && new Date(searchCondition.createdDateFrom))}
                  formControls
                />
                <DatePicker
                  handleDataChange={handlecreatedDateTo}
                  selected={(searchs.createdDateTo && new Date(searchs.createdDateTo)) || (searchCondition.createdDateTo && new Date(searchCondition.createdDateTo))}
                  formControls
                />
              </S2.FormControlsFlex>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.doctor_discount_grade)}</th>
            <td>
              <S2.FormControls {...register("grade")} defaultValue={searchCondition?.grade} as="select" $select>
                <option value="">{t(IK.select)}</option>
                {Object.entries(doctorGrade).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value === "DENTIS" ? t(IK.doctor_dentis) : value}
                  </option>
                ))}
              </S2.FormControls>
            </td>
            <th scope="row">{t(IK.search_status)}</th>
            <td>
              <S2.FormControls {...register("status")} defaultValue={searchCondition?.status} as="select" $select>
                <option value="">{t(IK.select)}</option>
                <option value="1">{t(IK.status_apporve)}</option>
                <option value="-1">{t(IK.status_reject)}</option>
                <option value="0">{t(IK.status_pending)}</option>
              </S2.FormControls>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.video_call)}</th>
            <td>
              <S2.FormControls {...register("video")} defaultValue={searchCondition?.video} as="select" $select>
                <option value="">{t(IK.select)}</option>
                <option value="Y">{t(IK.video_call_complete)}</option>
                <option value="N">{t(IK.video_call_not_complete)}</option>
              </S2.FormControls>
            </td>
            <th scope="row">{t(IK.doctor_marketing)}</th>
            <td>
              <S2.FormControls {...register("marketing")} defaultValue={searchCondition?.marketing} as="select" $select>
                <option value="">{t(IK.select)}</option>
                <option value="Y">{t(IK.marketing_agree)}</option>
                <option value="N">{t(IK.marketing_disagree)}</option>
              </S2.FormControls>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.doctor_type)}</th>
            <td>
              <S2.FormControls {...register("doctorType")} defaultValue={searchCondition?.doctorType} as="select" $select>
                <option value="">{t(IK.select)}</option>
                <option value="TNS">{t(IK.doctor_tns)}</option>
                <option value="DENTIS">{t(IK.doctor_dentis)}</option>
              </S2.FormControls>
            </td>
            <th scope="row">{t(IK.doctor_type_classification)}</th>
            <td>
              <S2.FormControls {...register("doctorClassification")} defaultValue={searchCondition?.doctorClassification} as="select" $select>
                <option value="">{t(IK.select)}</option>
                <option value="ORTHODONTIST">{t(IK.doctor_orthodontists)}</option>
                <option value="PEDIATRIC">{t(IK.doctor_pediatrics)}</option>
                <option value="UNIVERSITY">{t(IK.doctor_university)}</option>
                <option value="ETC">{t(IK.other)}</option>
                <option value="INTERNATIONAL">{t(IK.doctor_International)}</option>
              </S2.FormControls>
            </td>
          </tr>
        </tbody>
      </S.TableType2>
      <S3.ButtonCtBox>
        <S3.StyledButton as="button" type="button" onClick={() => onReset()}>
          <i className="ri-refresh-line" /> {t(IK.reset)}
        </S3.StyledButton>
        <S3.StyledButton as="button" $large $primary type="submit">
          <i className="ri-search-2-line" /> {t(IK.search)}
        </S3.StyledButton>
      </S3.ButtonCtBox>
    </form>
  );
};

export default DoctorFilter;
