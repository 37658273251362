import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCoupon, useGetCouponList } from "../apis/couponApi";
import BoardTotal from "../components/board/BoardTotal";
import CouponCheck from "../components/coupon/CouponCheck";
import CouponSearchFilter from "../components/coupon/CouponSearchFilter";
import { CouponTableTr } from "../components/coupon/CouponTableTr";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import { FormControlsFlex } from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeParam, urlParamToObject } from "../utils/searchParams";

const CouponList = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data, isLoading } = useGetCouponList(changeParam(searchCondition));
  const coupons = data?.data;

  const [items, setItems] = useState([]);

  /**체크목록 담기 */
  useEffect(() => {
    if (!isLoading && coupons) {
      setItems(coupons.content.map((item) => (item.useYn === "N" ? { ...item, checked: false } : item)));
    }
  }, [isLoading, coupons]);

  /**전체 체크 */
  const handleAllCheck = (e) => {
    //체크상태 있는것만 체크
    setItems((prevItems) => prevItems.map((item) => (item.useYn === "N" ? { ...item, checked: e.target.checked } : item)));
  };

  /**단일 체크 */
  const handleCheck = (id) => {
    setItems((prevItems) => prevItems.map((item) => (item.couponId === id ? { ...item, checked: !item.checked } : item)));
  };

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  const tableCol = ["70px", "90px", "*", "220px", "220px", "180px", "120px", "260px", "120px"];
  const tableHead = [
    <CouponCheck handleCheck={handleAllCheck} />,
    "No.",
    t(IK.coupon_name),
    t(IK.clinic_name),
    t(IK.doctor_name),
    t(IK.pay_discount),
    t(IK.coupon_created_date),
    t(IK.coupon_date_limit),
    t(IK.coupon_usage),
  ];

  const deleteMutation = useDeleteCoupon();

  /**쿠폰 삭제 */
  const handleDelete = () => {
    //삭제할 항목이 없을때
    if (items.filter((item) => item.checked).length === 0) return Notify.failure(t(IK.coupon_error_msg_2));
    Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => {
      deleteMutation.mutate(items.filter((item) => item.checked).map((item) => item.couponId));
    });
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <CouponSearchFilter setSearchCondition={setSearchCondition} searchCondition={searchCondition} />
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableHead={tableHead} tableCol={tableCol} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={coupons?.totalElements} />
              <FormControlsFlex>
                <S2.StyledButton as="button" onClick={handleDelete}>
                  {t(IK.delete)}
                </S2.StyledButton>
                <S2.StyledButton $primary to="/couponList/add">
                  {t(IK.add_coupon)}
                </S2.StyledButton>
              </FormControlsFlex>
            </CardHead>
            <DataTable
              dataLength={coupons?.totalElements}
              tableCol={tableCol}
              tableHead={tableHead}
              tableTr={<CouponTableTr totalElements={coupons?.totalElements} page={searchCondition?.page} coupons={items} handleCheck={handleCheck} />}
            />
            <CardTail>
              <Paging totalCount={coupons?.totalElements} currentPage={searchCondition.page} size={coupons?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default CouponList;
