import dayjs from "dayjs";

/**param을 객체로 처리 */
export function urlParamToObject() {
  return Object.fromEntries(new URLSearchParams(window.location.search));
}

/**빌링 검색어 객체를 param으로 처리 */
export const billChangeParam = (searchCondition) => {
  const { page, endMonth, startMonth, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (startMonth && endMonth) {
    q.push(`billingMonth:${dayjs(startMonth).format("YYYY-MM-DD")}_${dayjs(endMonth).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
  };
};

/**보드게시판 객체를 param으로 변경 */
export const changeBoradParam = (searchCondition) => {
  const { page, boardSort, boardSelect, boardText, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (!!boardText) {
    q.push(`${boardSelect}:${boardText}`);
  }

  return {
    q: q.join(","),
    boardSort: boardSort,
    page: page - 1,
    size: 10,
  };
};

/**이벤트 게시판 검색조건 param
 * size는 10으로 고정
 */
export const changeEventParam = (searchCondition) => {
  const { page, startDateTo, startDateFrom, searchSelect, searchText, ...rest } = searchCondition;

  const q = filterQ(rest);

  //검색어 select가 있고 검색어가 있을 경우 추가
  if (!!searchText) {
    q.push(`${searchSelect}:${searchText}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
    ...(startDateFrom && startDateTo && { startDateFrom, startDateTo }),
  };
};

/**q 관련 검색어 필터링(일반 검색)
 * @param {Object} rest - 검색어들
 * @returns {Array} - 검색어 배열.
 */
export const filterQ = (rest) => {
  return Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);
};

/**
 * 날짜 변경 처리
 * @param {Date} date - 선택된 날짜.
 * @param {function} setValue - form set 함수.
 * @param {string} fieldName - 필드 이름.
 * @returns {void}
 */
export const onDateChange = (date, setValue, fieldName) => {
  if (date) {
    setValue(fieldName, dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
  } else {
    setValue(fieldName, "");
  }
};

/**디폴트 게시판 검색 조건(처방전,) */
export const changeParam = (searchCondition) => {
  const { page, startDate, startDateTo, startDateFrom, sort, endDate, startYear, endYear, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (startDate && endDate) {
    q.push(`createdDate:${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}`);
  }

  if (startYear && endYear) {
    q.push(`birthday:${dayjs(startYear).format("YYYY")}_${dayjs(endYear).format("YYYY")}`);
  }

  const param = {
    q: q.join(","),
    page: page - 1,
    size: 10,
    ...(startDateFrom && startDateTo && { startDateFrom, startDateTo }),
  };

  if (sort) {
    // sort가 있을 경우 sort를 추가
    param.sort = sort;
  }

  return param;
};

/*created 리스트 게시판 검색 조건
 * 환자, 의사
 */
export const createdChangeParam = (searchCondition) => {
  const { page, createdDateTo, createdDateFrom, startYear, endYear, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (startYear && endYear) {
    q.push(`birthday:${dayjs(startYear).format("YYYY")}_${dayjs(endYear).format("YYYY")}`);
  }

  const param = {
    q: q.join(","),
    page: page - 1,
    size: 10,
    ...(createdDateFrom && createdDateTo && { createdDateFrom, createdDateTo }),
  };

  return param;
};
