import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetUpDesignFile } from "../../../apis/studyApi";
import * as S from "../../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../../elements/Form/style/Form.style";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { CardTail } from "../../layout/CardLayout/CardLayout";

export const StudyDesignFileUpload = ({ studyId, designFile, setDesignFile }) => {
  const { t } = useTranslation(["translation"]);

  const navigate = useNavigate();
  const location = useLocation();

  const setUpDesignMutation = useSetUpDesignFile();
  /**셋업 파일 등록 */
  const handleDesignFile = () => {
    if (designFile) {
      const formData = new FormData();
      formData.append("setupDesign ", designFile);
      setUpDesignMutation.mutate(
        {
          studyId,
          formData,
        },
        {
          onSuccess: () => {
            setDesignFile(null);
          },
        }
      );
    }
  };

  return (
    <>
      <S3.ListGroup>
        <dt>{t(IK.design_file)}</dt>
        <dd>
          <S2.FormControlsFlex $colCenter>
            <S2.FormControls disabled defaultValue={designFile?.name || ""} />
            <S2.FormControls type="file" accept=".zip, application/zip" id="setupDesign_file" hidden onChange={(event) => setDesignFile(event.target.files[0])} />
            <S2.FormControlsBtn as="label" htmlFor="setupDesign_file">
              {t(IK.file_btn)}
            </S2.FormControlsBtn>
          </S2.FormControlsFlex>
        </dd>
      </S3.ListGroup>

      <CardTail line>
        <S.ButtonLtBox>
          <S.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
            {t(IK.list)}
          </S.StyledButton>

          <S.StyledButton as="button" $primary onClick={handleDesignFile}>
            {t(IK.upload)}
          </S.StyledButton>
        </S.ButtonLtBox>
      </CardTail>
    </>
  );
};
