import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCenterList } from "../apis/centerApi";
import BoardTotal from "../components/board/BoardTotal";
import CenterSearchFilter from "../components/center/CenterSearchFilter";
import { CenterTableTr } from "../components/center/CenterTableTr";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeParam, urlParamToObject } from "../utils/searchParams";

const CenterList = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data: centerData, isLoading } = useGetCenterList(changeParam(searchCondition));
  const centers = centerData?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  const tableHaed = [t(IK.id), t(IK.name), t(IK.center_permissions), t(IK.country)];
  const tableCol = ["20%", "20%", "20%", "20%"];

  return (
    <S.CardRow>
      <S.CardCol>
        <CenterSearchFilter setSearchCondition={setSearchCondition} searchCondition={searchCondition} />
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHaed} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={centers?.totalElements} />
              <S2.StyledButton $primary to="/centerList/add">
                {t(IK.add_center)}
              </S2.StyledButton>
            </CardHead>
            <DataTable tableCol={tableCol} tableHead={tableHaed} dataLength={centers?.totalElements} tableTr={<CenterTableTr centers={centers?.content} />} />
            <CardTail>
              <Paging totalCount={centers?.totalElements} currentPage={searchCondition.page} size={centers?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default CenterList;
