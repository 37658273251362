import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import { useRecoilState } from "recoil";
import { converterTimeZone } from "../../../apis/accountApi";
import { userTimezone } from "../../../atom/userAtom";
import { formatTimezone } from "../../../utils/formatDate";

const callBackApiList = {
  "/studyList": ["StudyList", "TabCount"],
  "/studyList/:studyId": ["StudyInfo", "StudySwiper"],
  "/patientList": ["PatientList"],
  "/patientList/:patientId": ["PatientInfo"],
  "/doctorList": ["DoctorList"],
  //쿠폰은 한국만 사용
  "/couponList": ["CouponList"],
  //결제도 한국만 사용
  "/billList/:billingId": ["BillDetail"],
  "/education/do-better": ["BoardList"],
  "/education/eBook": ["BoardList"],
  "/education/resources": ["BoardList"],
  "/education/clinical-gallery": ["GalleryList"],
  "/education/clinical-gallery/post/:galleryId": ["Galleryinfo"],
  "/support/event": ["EventList"],
  "/support/event/post/:eventBoardId": ["EventInfo"],
  "/support/notice": ["BoardList"],
  "/support/popup": ["PopupList"],
  "/support/popup/post/:popupId": ["Popup"],
};

/** URL 패턴 일치 여부 확인 함수 */
const isUrlMatch = (urlPattern, urlToCheck) => {
  const regexPattern = new RegExp("^" + urlPattern.replace(/:\w+/g, "\\d+") + "$");
  return regexPattern.test(urlToCheck);
};

const TimeZone = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [selectedTimezone, setSelectedTimezone] = useRecoilState(userTimezone);

  /** 현재 pathname에 해당하는 콜백 API 찾기 */
  const matchedComponent = useMemo(() => {
    for (const [path, queryKey] of Object.entries(callBackApiList)) {
      if (isUrlMatch(path, pathname)) return queryKey;
    }
    return [];
  }, [pathname]);

  /** 타임존 변경 핸들러 */
  const handleTimezone = useCallback(
    (timezone) => {
      const formattedTimezone = formatTimezone(timezone); // 타임존 포맷
      converterTimeZone(formattedTimezone)
        .then(() => {
          setSelectedTimezone(timezone.value);
          if (matchedComponent?.length > 0) {
            // 쿼리 캐시 무효화
            queryClient.invalidateQueries({
              predicate: (query) => [...(matchedComponent || []), "AlarmList"].includes(query.queryKey[0]),
            });
          }
        })
        .catch(console.error);
    },
    [matchedComponent, queryClient, setSelectedTimezone]
  );

  return <TimezoneSelect value={selectedTimezone} onChange={handleTimezone} menuPlacement="top" menuPosition="auto" />;
};

export default TimeZone;
