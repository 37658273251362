import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { DatePicker, YearPicker } from "../../elements/DatePicker";
import * as S2 from "../../elements/Form/style/Form.style";
import * as S from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";

const PatientFilter = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const searchs = watch();

  /**기간 설정 */
  const handleDateChange = (event) => {
    const gap = event.target.value;
    if (gap) {
      const createdDateFrom = new Date(dayjs().subtract(gap, "month").format("YYYY-MM-DD"));
      const createdDateTo = new Date();
      setValue("createdDateFrom", createdDateFrom.toISOString().split("T")[0]);
      setValue("createdDateTo", createdDateTo.toISOString().split("T")[0]);
    } else {
      setValue("createdDateFrom", "");
      setValue("createdDateTo", "");
      setSearchCondition((prev) => ({ ...prev, createdDateFrom: "", createdDateTo: "" }));
    }
  };

  /**생년월일 앞*/
  const handleStartYearChange = (birth) => {
    if (birth) {
      setValue("startYear", dayjs(birth).format("YYYY"));
    } else {
      setValue("startYear", "");
    }
  };

  /**생년월일 뒤*/
  const handleEndYearChange = (birth) => {
    if (birth) {
      setValue("endYear", dayjs(birth).format("YYYY"));
    } else {
      setValue("endYear", "");
    }
  };

  /**치료 시작 날짜 설정 */
  const handlecreatedDateFrom = (date) => {
    if (date) {
      setValue("createdDateFrom", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("createdDateFrom", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handlecreatedDateTo = (date) => {
    if (date) {
      setValue("createdDateTo", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("createdDateTo", "");
    }
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    if ((data.createdDateFrom || data.createdDateTo) && !(data.createdDateFrom && data.createdDateTo)) {
      // data.createdDateFrom와 data.createdDateTo 중 하나는 값이 있고 다른 하나는 없는 경우
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }
    if ((data.startYear || data.endYear) && !(data.startYear && data.endYear)) {
      // data.startYear data.endYear 중 하나는 값이 있고 다른 하나는 없는 경우
      return Notiflix.Report.info("", t(IK.birthday_required), t(IK.confirm), () => {});
    }
    if (searchCondition.doctorId) {
      setSearchCondition((prev) => ({ doctorId: prev.doctorId, ...data, page: 1 }));
    } else {
      setSearchCondition({ ...data, page: 1 });
    }
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      clinicName: "",
      doctorName: "",
      patientNumber: "",
      patientName: "",
      startYear: "",
      endYear: "",
      createdDateFrom: "",
      createdDateTo: "",
      packages: "",
      gender: "",
    });

    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;

    if (searchCondition.doctorId) {
      setSearchCondition((prev) => ({ doctorId: prev.doctorId, page: 1 }));
    } else {
      setSearchCondition({ page: 1 });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            {/* 치과명 */}
            <th scope="row">{t(IK.search_clinic)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.clinicName} {...register("clinicName")} />
            </td>
            {/* 환자명 */}
            <th scope="row">{t(IK.search_patient)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_patient_input)} defaultValue={searchCondition?.patientName} {...register("patientName")} />
            </td>
          </tr>
          <tr>
            {/* 의사명 */}
            <th scope="row">{t(IK.search_doctor)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_doctor_input)} defaultValue={searchCondition?.doctorName} {...register("doctorName")} />
            </td>
            {/* 환자 번호 */}
            <th scope="row">{t(IK.patient_number)}</th>
            <td>
              <S2.FormControls placeholder={t(IK.search_patient_number)} defaultValue={searchCondition?.patientNumber} {...register("patientNumber")} />
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.birthday)}</th>
            <td>
              <S2.FormControlsFlex>
                <YearPicker
                  handleDataChange={handleStartYearChange}
                  selected={(searchs.startYear && new Date(searchs.startYear)) || (searchCondition.startYear && new Date(searchCondition.startYear))}
                  formControls
                />
                <YearPicker
                  handleDataChange={handleEndYearChange}
                  selected={(searchs.endYear && new Date(searchs.endYear)) || (searchCondition.endYear && new Date(searchCondition.endYear))}
                  formControls
                />
              </S2.FormControlsFlex>
            </td>
            <th scope="row">{t(IK.search_patient_register)}</th>
            <td>
              <S2.FormControlsFlex $FormControlsDate>
                <S2.FormControls onChange={handleDateChange} as="select" $select id="dateControl">
                  <option value="">{t(IK.entire_date)}</option>
                  <option value={1}>{t(IK.month1)}</option>
                  <option value={3}>{t(IK.month3)}</option>
                  <option value={6}>{t(IK.month6)}</option>
                  <option value={12}>{t(IK.year1)}</option>
                </S2.FormControls>
                <DatePicker
                  handleDataChange={handlecreatedDateFrom}
                  selected={(searchs.createdDateFrom && new Date(searchs.createdDateFrom)) || (searchCondition.createdDateFrom && new Date(searchCondition.createdDateFrom))}
                  formControls
                />
                <DatePicker
                  handleDataChange={handlecreatedDateTo}
                  selected={(searchs.createdDateTo && new Date(searchs.createdDateTo)) || (searchCondition.createdDateTo && new Date(searchCondition.createdDateTo))}
                  formControls
                />
              </S2.FormControlsFlex>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.treatment_option)}</th>
            <td>
              <S2.FormControls {...register("packages")} defaultValue={searchCondition?.packages} as="select" $select>
                <option value="">{t(IK.select_treatment_options)}</option>
                <option value="R">R-active</option>
                <option value="RP">R-passive</option>
                <option value="S10">10</option>
                <option value="S20">20</option>
                <option value="SR">Regular</option>
                <option value="SAP">Regular AP</option>
                <option value="Si">i</option>
                <option value="SiAP">iAP</option>
              </S2.FormControls>
            </td>
            <th scope="row">{t(IK.gender)}</th>
            <td>
              <S2.FormControls {...register("gender")} defaultValue={searchCondition?.gender} as="select" $select>
                <option value="">{t(IK.select_gender)}</option>
                <option value="male">{t(IK.male)}</option>
                <option value="female">{t(IK.female)}</option>
              </S2.FormControls>
            </td>
          </tr>
        </tbody>
      </S.TableType2>
      <S3.ButtonCtBox>
        <S3.StyledButton type="button" onClick={() => onReset()} as="button">
          <i className="ri-refresh-line" /> {t(IK.reset)}
        </S3.StyledButton>
        <S3.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line" /> {t(IK.search)}
        </S3.StyledButton>
      </S3.ButtonCtBox>
    </form>
  );
};

export default PatientFilter;
