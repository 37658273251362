/**
 * 페이지네이션된 리스트에서 아이템의 순서를 계산합니다.
 * @param total 리스트의 총 아이템 수.
 * @param page 현재 페이지 번호.
 * @param index 아이템의 인덱스.
 * @param size 페이지 당 아이템 수.
 * @returns 페이지 아이템의 순서 번환
 */
export const pageListNumber = (total, page, index, size = 10) => {
  return total - (Number(page) - 1) * size - index;
};

/**
 * 페이지네이션된 리스트에서 아이템의 순서를 계산합니다 (정순).
 * @param page 현재 페이지 번호.
 * @param index 아이템의 인덱스.
 * @param size 페이지 당 아이템 수.
 * @returns 페이지 아이템의 순서 번호
 */
export const pageOrderNumber = (page, index, size = 10) => {
  return (Number(page) - 1) * size + index + 1;
};
