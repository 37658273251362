import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/BoardList.style";

const BoardTotal = ({ total }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <S.BoardTotal>
      <span>
        {t(IK.total)} <strong>{total}</strong> {t(IK.cases)}
      </span>
    </S.BoardTotal>
  );
};

export default BoardTotal;
