import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetClinicInfo, useGetPatientInfo } from "../apis/patientApi";
import { CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import PatientFileInfo from "../components/patients/PatientFileInfo";
import PatientInfo from "../components/patients/PatientInfo";
import PatientModal from "../components/patients/PatientModal";
import { PatientFileSkeleton, PatientInfoSkeleton } from "../components/patients/PatientSkeleton";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import { IK } from "../utils/i18n/keyStore";

const PatientView = () => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**환자 정보 조회 */
  const { data: patientInfo, isLoading: isInfoLoading, isError: isInfoError } = useGetPatientInfo(patientId);
  /**환자 구외,구내,방사선, 동의서 조회 */
  const { data: fileInfo, isLoading: isFileLoading, isError: isFileError } = useGetClinicInfo(patientId);
  const agreementFile = fileInfo?.data?.patientAgreement;

  const isLoadingAll = isInfoLoading || isFileLoading || isInfoError || isFileError;

  const [openModal, setOpenModal] = useState(false);

  return (
    <S.CardFlex>
      {isLoadingAll ? (
        <>
          <PatientInfoSkeleton />
          <S.CardColWd $full>
            <PatientFileSkeleton t={t} />
          </S.CardColWd>
        </>
      ) : (
        <>
          <PatientInfo patient={patientInfo?.data} agreementFile={agreementFile} setOpenModal={setOpenModal} patientId={patientId} />
          <S.CardColWd $full>
            <S.CardInner>
              <PatientFileInfo file={fileInfo?.data} />
              <CardTail line>
                <S2.ButtonLtBox>
                  <S2.StyledButton as="button" onClick={() => navigate(`/patientList${location?.state?.herf || "?page=1"}`)}>
                    {t(IK.list)}
                  </S2.StyledButton>
                </S2.ButtonLtBox>
              </CardTail>
            </S.CardInner>
          </S.CardColWd>
          <PatientModal openModal={openModal} setOpenModal={setOpenModal} />
        </>
      )}
    </S.CardFlex>
  );
};

export default PatientView;
