import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import SetupInterceptors from "../apis/SetupInterceptors";
import { checkToken, deleteRefreshToken } from "../apis/accountApi";

/**토큰확인용 로그인 유저는 로그인화면 이동 불가 */
const useTokenCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    SetupInterceptors(navigate, t);
  }, [navigate, t]);

  useEffect(() => {
    if (token && location.pathname === "/") {
      checkToken()
        .then((res) => navigate("/studyList"))
        .catch(() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("recoil-persist");
          deleteRefreshToken();
          navigate("/");
        });
    }
  }, [token, location.pathname, navigate]);

  return !!token;
};

export default useTokenCheck;
